export enum WrcCallTypes {
    Audio = 1,
    Video = 2,
    Screenshare = 3,
    OneWayVideo = 4
}

export enum WrcCodes {
    Success = 1,
    Rejected = -1,
    MediaFailed = -2,
    RequestTimeout = -3,
    ResponseTimeout = -4,
    ScrensharePermissionDenied = -5
}

export enum WrsConnectionModes {
    /**
      * The call will be made normally and will be recorded if a MediaServer is present in 
      * between and configured accordingly
      */
    Normal = 0,

    /**
     * The call will be made such that all video streams will flow as described in Normal mode 
     * (via MS) and all audio streams will bypass any intermediate MediaServer even if such is 
     * present in between
     */
    DirectAudio = 1,

    /**
     * The call will be made such that all audio streams will flow as described in Normal mode
     * (via MS) and all video streams will bypass any intermediate MediaServer even if such is
     * present in between 
     */
    DirectVideo = 2,

    /**
     * The call will bypass any intermediate MediaServer setup all-together and will connect P2P
     * straight to the browser at other end
     */
    Direct = 3
}

export enum WrsDtmfTones {
    NUM_0 = 0,
    NUM_1 = 1,
    NUM_2 = 2,
    NUM_3 = 3,
    NUM_4 = 4,
    NUM_5 = 5,
    NUM_6 = 6,
    NUM_7 = 7,
    NUM_8 = 8,
    NUM_9 = 9,
    Star = 100,     // Dialpad key '*'
    Pound = 101     // Dialpad key '#'
}

export enum WrsCallSignal {
    REQUEST = 'requestav',
    JOIN = 'join-requestav',
    REQUEST_ACK = 'ack-requestav',
    JOIN_ACK = 'ack-join-requestav',
    STATUS = 'avtstatus',
    ACK = 'ackav',
    END = 'endav',
    DROP = 'dropav',
    MUTE = 'mute',
    UNMUTE = 'unmute',
    CALLSTATE = 'call-state',
    CALLID = 'requestav-callid',
    EVENT = 'eventav'
}

export enum WrsScreenshareSignal {
    ADD = 'addscreenshare',
    STATUS = 'screensharestatus',
    END = 'endscreenshare',
    STARTNOTIFICATION = 'screensharestart-notification',
    MUTE = 'screensharemute',
    UNMUTE = 'screenshareunmute',
}