import {
    AgentForcedLogoffEvent,
    AgentStatusChangeEvent,
    AgentUIUpdateEvent,
    CommandResultEvent,
    ForwadableEvent,
    GenericCallEvent,
    GenericCtiEvent,
    GenericEvent,
    GenericInteractionEvent,
    GenericRepeatableEvent,
    GenericTMACEvent,
    IEventAcknowledgement,
    IncomingCallEvent,
    IncomingEmailEvent,
    InteractionClosedEvent,
    InteractionTransferNotificationEvent,
    InteractionTransferResponseEvent,
    IRespondTextChatTransferNotification,
    IRespondTransferNotification,
    IResponse,
    IUIEvent,
    OutgoingCallEvent,
    OutgoingEmailEvent,
    SMSIncomingEvent,
    SMSOutgoingEvent,
    TextChatAgentDisconnectedEvent,
    TextChatIncomingEvent,
    TextChatRemoteUserConnectedEvent,
    TextChatTransferNotificationEvent,
    TextChatTransferNotificationResponseEvent,
    TMACEventTypes,
    TmacServerConnectionAborted,
    TmacServerConnectionExist,
    TmacServerConnectionSuccess,
    WallboardRefreshEvent
} from '../interfaces';
import { CommandResultEventModel, IUIEventModel } from '../models';
import { Logger, TUtility } from '../utils';
import { SDKInternal } from './sdk-internal';

export class EventProcessor {

    // interaction recovery data
    interactionRecoveryData: any[];

    constructor(private sdkInternal: SDKInternal) {
        this.interactionRecoveryData = [];
    }

    public process(event: IUIEvent): void {
        // return if the event is null
        if (!event) {
            return;
        }

        // check the created time format and correct
        if (event.CreatedTime?.toString().includes('/Date')) {
            event.CreatedTime = new Date(parseInt(event.CreatedTime.toString().substr(6)));
        }

        // if this event to be handled and emitted
        if (typeof this[event.EventName] === 'function') {
            this[event.EventName](event);
            return;
        }

        // emit the event
        this.emitEvent(event);
    }

    private emitEvent(event: any) {
        // log the event 
        this.logEvent(event);

        // emit event by event
        this.sdkInternal.sdkInstance.events.emit(event.EventName, event);

        // emit all the events in single source
        this.sdkInternal.sdkInstance.events.emit('OnTMACEvent', event);

        // if ACK required send the ACK, if not RecoveryEvent and IsReCreate
        if (!event.RecoveryEvent && !event.IsReCreate && event.ACK && event.ACK.IsRequired) {
            Logger.debug(`EventAcknowledgement: required for ${event.EventName} - ${event.ACK.Id}`);
            // process and send ACK if required
            this.sendACK({ channel: event.ACK.Channel, itemId: event.ACK.Id, source: event.ACK.Source }, new Object());
        }
    }

    private logEvent(event: IUIEvent) {
        // check the events to ignore
        if (!this.sdkInternal.config.logging.sdkEvents || event.EventName === 'WallboardRefreshEvent' ||
            event.EventName === 'TeamWallboardRefreshEvent' ||
            event.EventName === 'GenericCTIEvent' ||
            event.EventName === 'OnPOMPendingCallbacksList') {
            return;
        }
        Logger.info(`${event.EventName} - ${JSON.stringify(event)}`);
    }

    private sendACK(data: IEventAcknowledgement, userObject: any) {
        // assign data for obj for retry
        userObject.data = data;
        // add a retry count property if not there
        if (!userObject.retryCount) {
            userObject.retryCount = 0;
        }
        // try send ACK
        this.sdkInternal.eventAcknowledgement(data, userObject).then((result: IResponse) => {
            // check for response data
            if (result.response) {
                Logger.info(`EventAcknowledgement: response received - ${result.response.ResultCode}`);
                // retry for failed case only
                if (result.response.ResultCode === 0) {
                    // failed try again after 2s for max 3 retry 
                    if (result.userObject.retryCount < 3) {
                        setTimeout((x) => {
                            Logger.warn(`EventAcknowledgement: sending ACK [${result.userObject.retryCount}]: Trying after 2s.`);
                            // increment the retry if failed
                            x.retryCount++;
                            // send the ack again
                            this.sendACK(x.data, x);
                        }, 2000, result.userObject);
                    }
                    else {
                        result.userObject.retryCount = 0;
                    }
                }
            }
        });
    }

    private saveRecoveryData(event: any) {
        if (this.interactionRecoveryData.filter((r) => { return r.InteractionId === event.InteractionID; }).length === 0) {
            // add to interaction reference
            this.sdkInternal.addInteraction(event);
            // add to the recovery referece
            this.interactionRecoveryData.push(event.RecoveryData);
        }
    }

    private handleGenericEvents(event: GenericEvent) {
        // init the event
        let eventToEmit: GenericEvent = { ...event };

        try {
            // check if there is SubEventName else emit here only
            if (!event.SubEventName) {
                // emit the event
                this.emitEvent(event);
                return;
            }

            // prepare the event to emit
            eventToEmit = {
                ...event, // copy the event
                EventName: (event.SubEventName.endsWith('Event') ? // check event name ends with 'Event'
                    event.SubEventName : // if so, emit the SubEventName only
                    event.SubEventName + 'Event') as TMACEventTypes, // if not, add 'Event' to SubEventName
            };
            // delete the sub event name property
            delete eventToEmit.SubEventName;
        } catch (error) {
            Logger.error('Exception in EventProcessor.handleGenericEvents', error);
        }

        // emit the event
        this.emitEvent(eventToEmit);
    }

    GenericEvent(event: GenericEvent): void {
        this.handleGenericEvents(event);
    }

    GenericRepeatableEvent(event: GenericRepeatableEvent): void {
        this.handleGenericEvents(event);
    }

    GenericCtiEvent(event: GenericCtiEvent): void {
        this.handleGenericEvents(event);
    }

    GenericCallEvent(event: GenericCallEvent): void {
        this.handleGenericEvents(event);
    }

    GenericTMACEvent(event: GenericTMACEvent): void {
        this.handleGenericEvents(event);
    }

    WallboardRefreshEvent(event: WallboardRefreshEvent): void {
        // update the agent data
        this.sdkInternal.updateAgentData('agentSkills', event.Skills);
        // emit the event
        this.emitEvent(event);
    }

    AgentStatusChangeEvent(event: AgentStatusChangeEvent): void {
        // if the status is 'not logged in' set the logged in to false
        if (event.Status.toLocaleLowerCase() === 'not logged in') {
            this.sdkInternal.updateAgentData('isLoggedIn', false);
            // create a new event
            const customEvent = new IUIEventModel();
            // send force logoff event
            this.AgentForcedLogoffEvent({
                ...customEvent,
                EventName: 'AgentForcedLogoffEvent',
                Reason: `${event.Status} - Agent session not available`,
                Type: 'SessionNotFound'
            });
        }
        // update the agent data
        this.sdkInternal.updateAgentData('agentStatus', event.Status);
        // emit the event
        this.emitEvent(event);
    }

    AgentForcedLogoffEvent(event: AgentForcedLogoffEvent): void {
        // update the agent data
        this.sdkInternal.reInitAgentData();
        // de-register from event socket
        this.sdkInternal.deRegisterEvent();
        // emit the event
        this.emitEvent(event);
    }

    TmacServerConnectionSuccess(event: TmacServerConnectionSuccess): void {
        try {
            // reset the session key
            if (event.ResultCode === 200) {
                // update the agent data sessionKey
                if (event.Data) {
                    this.sdkInternal.updateAgentData('sessionKey', event.Data);
                }

                // update the agent data tmacServer
                if (event.ResultMessage) {
                    this.sdkInternal.updateAgentData('tmacServer', event.ResultMessage);
                    this.sdkInternal.updateConnectionData('tmacServer', event.ResultMessage);
                }

                // recover interactions if stored
                if (this.interactionRecoveryData.length > 0) {
                    Logger.debug('TmacServerConnectionSuccess: recover all the interactions, count=' + this.interactionRecoveryData.length);
                    this.sdkInternal.recoverInteractions({ jsonData: JSON.stringify(this.interactionRecoveryData) });
                }

                // check if signalr connectivity is tmac is configured
                if (this.sdkInternal.getConnectionData().signalRUrl !== '') {
                    // check if the url is present
                    if (this.sdkInternal.signalRConnector !== null && event.TmacSignalRUrl) {
                        Logger.info(`TmacServerConnectionSuccess: try connecting to the new server URL, url=${event.TmacSignalRUrl}`);
                        this.sdkInternal.changeSignalRConnection(event);
                    }
                    else {
                        Logger.info('TmacServerConnectionSuccess: no SignalR URL found, event mode changed to polling');
                    }
                }
            }
        } catch (error) {
            Logger.error('Exception in TmacServerConnectionSuccess', error);
        }
        // emit the event
        this.emitEvent(event);
    }

    TmacServerConnectionAborted(event: TmacServerConnectionAborted): void {
        Logger.debug(`TmacServerConnectionAborted [${event.ResultMessage}]: TMAC Server connection aborted, logging you out!`);

        // emit the event
        this.emitEvent(event);

        // try closing the app
        window.close();

        // TODO:: try route to the initial page
        // location.href = location.origin + window.location.pathname.substr(0, window.location.pathname.lastIndexOf('/'));
    }

    TmacServerConnectionExist(event: TmacServerConnectionExist): void {
        // SignalR connection to the server is lost, fall back to event polling
        Logger.debug('TmacServerConnectionExist: SignalR connection to the server is lost, falling back to event polling!');
        // check the event mode
        if (this.sdkInternal.getConnectionData().eventMode !== 'eventPolling') {
            this.sdkInternal.changeToEventPolling();
        }
        // emit the event
        this.emitEvent(event);
    }

    ForwadableEvent(event: ForwadableEvent): void {
        // add this event to event session
        this.sdkInternal.addEventToAgentSession({
            agentId: event.ForwardAgent,
            eventString: JSON.stringify(event.Event),
            isPriority: true,
            toTmacServer: event.ForwardServer
        }, null);
        // emit the event
        this.emitEvent(event);
    }

    IncomingCallEvent(event: IncomingCallEvent): void {
        // save the event data for recovery
        this.saveRecoveryData(event);
        // emit the event
        this.emitEvent(event);
    }

    OutgoingCallEvent(event: OutgoingCallEvent): void {
        // save the event data for recovery
        this.saveRecoveryData(event);
        // emit the event
        this.emitEvent(event);
    }

    TextChatIncomingEvent(event: TextChatIncomingEvent): void {
        // check if the event is recreated then do not send it
        if (event.IsReCreate) {
            return;
        }
        // save the event data for recovery
        this.saveRecoveryData(event);
        // emit the event
        this.emitEvent(event);
    }

    TextChatRemoteUserConnectedEvent(event: TextChatRemoteUserConnectedEvent): void {
        // check if the event is recreated then do not send it
        if (event.IsReCreate) {
            return;
        }

        // get the agent data
        const agentData = this.sdkInternal.getAgentData();

        // send the greeting text
        if (!event.RecoveryEvent &&
            !event.TextChatIncomingEvent.IsAgentTransferedChat &&
            !event.TextChatIncomingEvent.IsAgentConferenceChat &&
            agentData.chatGreetingText &&
            event.ConferenceType !== 'silent' &&
            event.ConferenceType !== 'whisper') {
            this.sdkInternal.sendTextChat({
                interactionId: event.InteractionID.toString(),
                message: agentData.chatGreetingText,
                messageId: TUtility.uuid(),
                templateId: '',
                type: ''
            }, null)
                .then(() => {
                    // emit the event
                    this.emitEvent({
                        EventName: 'TextChatMessageTemplateSentEvent',
                        DateTime: new Date(),
                        CreatedTime: new Date(),
                        EventId: TUtility.uuid(),
                        RecoveryEvent: false,
                        InteractionID: event.InteractionID,
                        Message: agentData.chatGreetingText,
                        IsAppMessage: false,
                        Result: true,
                        MessageId: TUtility.uuid(),
                        UIEvent: true
                    });
                });
        }

        // emit the event
        this.emitEvent(event);
    }

    TextChatAgentDisconnectedEvent(event: TextChatAgentDisconnectedEvent): void {
        // check if the conference type is silent monitoring, then end the chat
        if (event.ConferenceType === 'silent') {
            this.sdkInternal.endTextChat({
                interactionId: event.InteractionID.toString(),
                reason: 'AgentChatDisconnected'
            }, null);
        }
        // emit the event
        this.emitEvent(event);
    }

    IncomingEmailEvent(event: IncomingEmailEvent): void {
        // save the event data for recovery
        this.saveRecoveryData(event);
        // emit the event
        this.emitEvent(event);
    }

    OutgoingEmailEvent(event: OutgoingEmailEvent): void {
        // save the event data for recovery
        this.saveRecoveryData(event);
        // emit the event
        this.emitEvent(event);
    }

    GenericInteractionEvent(event: GenericInteractionEvent): void {
        // save the event data for recovery
        this.saveRecoveryData(event);
        // emit the event
        this.emitEvent(event);
    }

    SMSIncomingEvent(event: SMSIncomingEvent): void {
        // save the event data for recovery
        this.saveRecoveryData(event);
        // emit the event
        this.emitEvent(event);
    }

    SMSOutgoingEvent(event: SMSOutgoingEvent): void {
        // save the event data for recovery
        this.saveRecoveryData(event);
        // emit the event
        this.emitEvent(event);
    }

    InteractionClosedEvent(event: InteractionClosedEvent): void {
        // remove from the interaction reference
        this.sdkInternal.removeInteraction(event.InteractionID);
        // remove the interaction from interactionRecoveryData for the closed interaction
        this.interactionRecoveryData = this.interactionRecoveryData.filter((r) => {
            return r.InteractionId !== event.InteractionID;
        });
        // emit the event
        this.emitEvent(event);
    }

    AgentUIUpdateEvent(event: AgentUIUpdateEvent): void {
        // check if there is SubEventName else emit here only
        if (!event.SubEventName) {
            // emit the event
            this.emitEvent(event);
            return;
        }

        // get the data
        const data = event.JsonData;

        // event to emit
        let emittingEvent: any = { ...event };

        // switch the SubEventName
        switch (event.SubEventName) {
            case "AUXCodeUpdateEvent":
                // filter the AUX codes
                // eslint-disable-next-line no-case-declarations
                const filtered = this.sdkInternal.filterAUXCodes(JSON.parse(data));
                // update the agent data
                this.sdkInternal.updateAgentData('auxCodes', filtered);
                Logger.info(`AUXCodeUpdateEvent: AUX codes reloaded successfully`);
                // create a new AUXCodeUpdateEvent
                emittingEvent = {
                    ...event,
                    EventName: 'AUXCodeUpdateEvent',
                    AUXCodes: filtered
                };
                break;
            case "MSStatusEvent":
                // create a new MSStatusInteractionEvent
                emittingEvent = {
                    ...event,
                    EventName: 'MSStatusInteractionEvent',
                    Status: data
                };
                break;
            case "UIConfigsUpdateEvent":
                Logger.info(`UIConfigsUpdateEvent: TMAC Settings are modified. Re-login to load the changes`);
                // create a new UIConfigsUpdateEvent
                emittingEvent = {
                    ...event,
                    EventName: 'UIConfigsUpdateEvent'
                };
                break;
        }

        // emit the event
        this.emitEvent(emittingEvent);
    }

    InteractionTransferNotificationEvent(event: InteractionTransferNotificationEvent): void {
        // append the response object
        event.Response = async (accept: boolean, comment?: string, userObject?: any) => {
            // create a response data
            const data: IRespondTransferNotification = {
                channel: event.Channel,
                comment: comment ? comment.trim() : '',
                otherData: event.OtherData,
                response: accept ? 'Accept' : 'Reject',
                toAgentId: event.FromAgentID,
                toInteractionId: event.FromInteractionID,
                toTmacServer: event.FromTmacServer
            }

            // invoke and return the response
            return await this.sdkInternal.respondTransferNotification(data, userObject);
        }
        // emit the event
        this.emitEvent(event);
    }

    InteractionTransferResponseEvent(event: InteractionTransferResponseEvent): void {
        //check the response message, if accept then transfer the interaction
        if (event.Response === "Accept") {
            this.sdkInternal.transferInteraction({
                channel: event.Channel,
                interactionId: event.InteractionID.toString(),
                otherData: event.OtherData,
                toAgentId: event.FromAgentID,
                toTmacServer: event.FromAgentTmacServer
            }, null)
                .then((dt: IResponse) => {
                    const response: CommandResultEvent = dt.response;
                    // emit a custom response event
                    const customEvent = new CommandResultEventModel();
                    customEvent.InteractionID = event.InteractionID;
                    customEvent.ResultCode = response.ResultCode;
                    customEvent.ResultMessage = response.ResultMessage;
                    customEvent.ErrorDetails = response.ErrorDetails;
                    // success
                    if (response.ResultCode === 0) {
                        customEvent.EventName = 'InteractionTransferSuccessEvent';
                    }
                    // failed
                    else {
                        customEvent.EventName = 'InteractionTransferFailedEvent';
                    }
                    Logger.info('TransferInteraction: ' + customEvent.ResultMessage);
                    // emit the response as event
                    this.emitEvent(customEvent);
                })
                .catch((error) => {
                    // emit a custom response event
                    const customEvent = new CommandResultEventModel();
                    customEvent.EventName = 'InteractionTransferFailedEvent';
                    customEvent.InteractionID = event.InteractionID;
                    customEvent.ResultCode = -1;
                    customEvent.ResultMessage = 'Interaction transfer failed';
                    customEvent.ErrorDetails = error;
                    Logger.error('TransferInteraction: ' + customEvent.ResultMessage, error);
                    // emit the event
                    this.emitEvent(customEvent);
                });
        }
        else {
            // emit the event
            this.emitEvent(event);
        }
    }

    TextChatTransferNotificationEvent(event: TextChatTransferNotificationEvent): void {
        // append the response object
        event.Response = async (accept: boolean, comment?: string, userObject?: any) => {
            // create a response data
            const data: IRespondTextChatTransferNotification = {
                comment: comment ? comment.trim() : '',
                otherData: event.Data,
                response: accept ? 'Accept' : 'Reject',
                toAgentId: event.FromAgentID,
                toInteractionId: event.FromInteractionID,
                toTmacServer: event.FromTmacServer
            };
            // invoke and return the response
            return await this.sdkInternal.respondTextChatTransferNotification(data, userObject);
        };
        // emit the event
        this.emitEvent(event);
    }

    TextChatTransferNotificationResponseEvent(event: TextChatTransferNotificationResponseEvent): void {
        // get session ID from recovery data
        let sessionId = '';
        const interactionData = this.interactionRecoveryData.filter((i) => i.InteractionId === event.InteractionID);
        // get the session Id
        if (interactionData.length > 0) {
            sessionId = interactionData[0].eventData.UCID;
        }
        // extract the other data
        const otherData = JSON.parse(event.Data);
        // check the response message, if accept then transfer the chat
        if (event.Response === "Accept") {
            this.sdkInternal.transferTextChat({
                chatMode: otherData.mode,
                comment: event.Comment,
                conferenceType: otherData.type,
                interactionId: event.InteractionID.toString(),
                lineId: event.LineID,
                sessionId,
                toAgentId: event.FromAgentID,
                toTmacServer: event.FromAgentTmacServer,
            }, null)
                .then((dt: IResponse) => {
                    const response: CommandResultEvent = dt.response;
                    // emit a custom response event
                    const customEvent = new CommandResultEventModel();
                    customEvent.InteractionID = event.InteractionID;
                    customEvent.ResultCode = response.ResultCode;
                    customEvent.ErrorDetails = response.ErrorDetails;
                    // success
                    if (response.ResultCode === 0) {
                        customEvent.EventName = 'TextChatTransferSuccessEvent';
                        customEvent.ResultMessage = `Chat ${otherData.type === 'transfer' ? 'transfer' : 'conference'} success`;
                    }
                    // failed
                    else {
                        customEvent.EventName = 'TextChatTransferFailedEvent';
                        customEvent.ResultMessage = `Chat ${otherData.type === 'transfer' ? 'transfer' : 'conference'} failed: ${customEvent.ResultMessage}`;
                    }
                    Logger.info('TransferTextChat: ' + customEvent.ResultMessage);
                    // emit the response as event
                    this.emitEvent(customEvent);
                })
                .catch((error) => {
                    // emit a custom response event
                    const customEvent = new CommandResultEventModel();
                    customEvent.EventName = 'TextChatTransferFailedEvent';
                    customEvent.InteractionID = event.InteractionID;
                    customEvent.ResultCode = -1;
                    customEvent.ResultMessage = 'Error in TransferTextChat';
                    customEvent.ErrorDetails = error;
                    Logger.error('TransferTextChat: ' + customEvent.ResultMessage, error);
                    // emit the event
                    this.emitEvent(customEvent);
                });
        }
        else {
            // change the event name to TextChatTransferRejectEvent
            event.EventName = 'TextChatTransferRejectEvent';
            // emit the event
            this.emitEvent(event);
        }
    }

    TCM_DirectAgentNotifyEvent(event: IUIEvent): void {
        // change the event name to remove underscore
        event.EventName = 'TCMDirectAgentNotifyEvent';
        // emit the event
        this.emitEvent(event);
    }

    TCM_DirectAgentNotifyTimeoutEvent(event: IUIEvent): void {
        // change the event name to remove underscore
        event.EventName = 'TCMDirectAgentNotifyTimeoutEvent';
        // emit the event
        this.emitEvent(event);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    AgentSettingsUpdatedEvent(event: any): void {
        // get updated agent profile data
        const agentData = JSON.parse(event.JsonData);
        // if data found
        if (agentData) {
            // add the json data to agent profile
            event.AgentProfile = { ...agentData };
            // update the internal data ref
            this.sdkInternal.updateAgentProfile({ ...agentData });
        }
        // delete the sub event name and json data properties
        delete event.SubEventName;
        delete event.JsonData;
        // emit the event
        this.emitEvent(event);
    }
}