import {
    TUtility,
    Logger,
    HttpClient,
    SignalRWrapper,
    WebSocketWrapper
} from ".";

// create a local interface for reference
interface IUtils {
    Logger: typeof Logger;
    Generic: typeof TUtility,
    HttpClient: typeof HttpClient,
    SignalRWrapper: typeof SignalRWrapper,
    WebSocketWrapper: typeof WebSocketWrapper
}

// create an object for Utils to export
// eslint-disable-next-line no-var
export declare var TUtils: IUtils;
TUtils = {
    Logger: Logger,
    Generic: TUtility,
    HttpClient: HttpClient,
    SignalRWrapper: SignalRWrapper,
    WebSocketWrapper: WebSocketWrapper
};