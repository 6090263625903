import { EventEmitterClass } from '../dispatcher/event-emitter';
import { Logger } from './logger';
export class WebSocketWrapper {
  /**
   * private variables
   */

  // socket reference
  #webSocket = null;
  // is closed flag
  #isClosed = false;
  // timer for reconnection
  #timer = null;

  // event emitter for signalR
  public events: EventEmitterClass = new EventEmitterClass();

  constructor() {
    Logger.info('WebSocketWrapper: instance created!', false);
  }

  /**
   * Method to connect to WebSocket
   * @param url url to connect
   * @param retryInterval retry interval time, default is 5000ms
   */
  public connect(url: string, retryInterval = 5000): void {
    Logger.debug(`WebSocketWrapper: connect, url=${url}, retryInterval=${retryInterval}`, false);
    try {
      // check if the url is provided
      if (!url) {
        Logger.warn('WebSocketWrapper: Connection url is empty!', false);
        return;
      }
      // create a websocket connection
      this.#webSocket = new WebSocket(url);

      // connection on open
      this.#webSocket.onopen = (e: Event) => {
        Logger.debug('WebSocketWrapper: connection is open', false);
        this.#isClosed = false;
        clearInterval(this.#timer);
        this.events.emit('WebSocketOnOpenEvent', e);
      };

      // connection on closed
      this.#webSocket.onclose = (e: Event) => {
        Logger.debug('WebSocketWrapper: connection is closed!', false);
        this.events.emit('WebSocketCloseEvent', e);
        // set the websocket to null
        this.#webSocket = null;
        // check if the connection closed by user
        if (this.#isClosed) {
          Logger.info('WebSocketWrapper: connection closed by user, ignore retry!', false);
          return;
        }
        // retry connecting..
        this.#timer = setTimeout(() => {
          Logger.info('WebSocketWrapper: trying to reconnect', false);
          this.connect(url, retryInterval);
        }, retryInterval);
      };

      // connection on error
      this.#webSocket.onerror = (e: ErrorEvent) => {
        Logger.error('Error in WebSocketWrapper', 'There has been an error in connection', false);
        this.events.emit('WebSocketErrorEvent', e);
      };

      // connection on message
      this.#webSocket.onmessage = (e: MessageEvent) => {
        this.events.emit('WebSocketMessageEvent', e);
      };
    } catch (error) {
      Logger.error('Error in WebSocketWrapper.connect', error, false);
    }
  }

  /**
   * Method to close WebSocket connection
   */
  close(): void {
    try {
      Logger.debug(`WebSocketWrapper: stop the connection!`, false);
      // close the connection
      this.#webSocket.close();
      // set the flag to true for user close
      this.#isClosed = true;
    } catch (error) {
      Logger.error('Error in WebSocketWrapper.close', error, false);
    }
  }

  /**
   * Method to get the state of WebSocket connection
   */
  getState(): number {
    // check if the socket is available and return the state
    if (this.#webSocket !== null) {
      return this.#webSocket.readyState;
    }
    return -1;
  }

  /**
   * Method to send the data through WebSocket connection
   * @param data data to send
   */
  send(data: string): number {
    // check if socket is ready to send data, else return error callback
    if (this.#webSocket !== null && this.#webSocket.readyState === 1) {
      this.#webSocket.send(data);
      return 1;
    } else {
      return 0;
    }
  }
}
