
import { useContext, useEffect, useState } from 'react'
import { Scrollbars } from "react-custom-scrollbars-2"
import { subscribeToEvents, fireEvent } from '../../util/eventEmitter'
import { fetchInteractions, getOldMessagesInteractions } from '../../services/conversationApi'
import Loading from '../shared/loading'
import EmptyChatList from '../shared/emptyChatList'
import SearchBtn from '../shared/searchBtn'
import CloseBtn from '../shared/closeBtn'
import LoadingDots from '../shared/loadingDots'
import ChatMessage from './chatMessage'
import ChatSearchPanel from './chatSearchPanel'
import { UserContext } from '../userContext'

let scrollChat = null

function ChatPreview() {

    const [customer, setCustomer] = useState(null)
    const [searchTab, toggleSearch] = useState(false)
    const [chatDisplay, setChatDisplay] = useState([])
    const [loadMore, setLoadMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userInteractionIds, setUserInteractionIds] = useState([])
    const [index, setIndex] = useState(0)
    const [focusMessage, setFocusMessage] = useState(null)
    const [activateFocus, setActivateFocus] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)

    const context = useContext(UserContext)


    useEffect(() => {
        subscribeToEvents('chat', async ({ customer }) => {
            if (!customer) { return }
            setLoading(true)
            setCustomer(customer)
            setChatDisplay([])
            setUserInteractionIds([])
            const timestamp = Date.now() + 100000000
            const interactionIds = await getInteractionIds(customer.userId, timestamp, 50)
            if (interactionIds && interactionIds.length > 0) {
                console.log(`CHAT LOAD EVENT RECEIVED : Length : ${interactionIds.length}, ${customer.customerName}`)
                await fetchMessages(timestamp, 10, interactionIds, customer)
                setInitialLoad(true)
            }
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (scrollChat && scrollChat.scrollToBottom && initialLoad) {
            setTimeout(() => {
                scrollChat?.scrollToBottom()
                setInitialLoad(false)
            }, 200)
        }
    }, [scrollChat, initialLoad])

    useEffect(() => {
        if (activateFocus && focusMessage) {
            let element = document.getElementById(focusMessage)

            if (element) {
                element.scrollIntoView()
                setActivateFocus(false)
            }

        }
    }, [activateFocus, focusMessage])

    async function getInteractionIds(userId, timestamp, limit) {
        const interactionIds = (await fetchInteractions(userId, timestamp, limit)).map(obj => obj.sessionId)
        const ids = [...userInteractionIds, ...interactionIds]
        if (ids.length > 0) {
            setUserInteractionIds(ids)
        }
        return ids
    }

    async function fetchMessages(timestamp, limit, interactionIds = userInteractionIds, user = customer) {
        let messages = [...chatDisplay]
        let i = index
        let interactionsAvailable = true
        while (messages.length < (chatDisplay.length + limit) && interactionsAvailable) {
            let nextIds = []
            if (i >= interactionIds.length) {
                timestamp = messages[0]?.created_at || timestamp
                nextIds = await getInteractionIds(user.userId, timestamp, 5)
                if (nextIds.length === interactionIds.length) {
                    interactionsAvailable = false
                    return messages
                }
                interactionIds = nextIds
            }
            const latestInteraction = interactionIds[i]
            const msgs = await getOldMessagesInteractions(latestInteraction, timestamp, limit)
            if (msgs.length < limit) {
                ++i
            }
            messages = [...msgs, ...messages]
            setChatDisplay(messages)
            setActivateFocus(true)
            setIndex(i)
        }
        return messages
    }

    async function loadMoreMessages() {
        if (chatDisplay.length > 0) {
            fireEvent('load_chat', true);
            setLoadMore(true)
            setFocusMessage(chatDisplay.length > 0 ? chatDisplay[0].mid : null)
            const timestamp = chatDisplay[0]?.created_at
            const messages = await fetchMessages(timestamp, 10)
            setActivateFocus(true)
            setLoadMore(false)
            fireEvent('load_chat', false);
        }

    }

    const displayChat = () => {
        if (loading) {
            return <Loading />
        } else {
            if (chatDisplay.length > 0) {
                const cus = { ...customer, customerName: context.userList[customer.userId] ? `${context.userList[customer.userId]?.firstName} ${context.userList[customer.userId]?.lastName}` : customer.customerName }
                return chatDisplay.map(messsage => <ChatMessage
                    key={messsage.mid}
                    mid={messsage.mid}
                    messageInfo={messsage}
                    customer={cus}
                />)
            }
            else {
                return <EmptyChatList />
            }
        }
    }

    return (
        <>
            {/* {customer && <div className="chat-header">
                <div className="chat-userdetials">
                    {customer.customerName || "Unknown User"}
                </div>
                <div className="right-search-icon">
                    {searchTab ?
                        <CloseBtn closeEvent={() => toggleSearch(false)} />
                        : <SearchBtn onClickSearch={() => toggleSearch(true)} />
                    }
                </div>
            </div>} */}
            <div className="chat chat-bg">
                <div className={searchTab ? "open-chat-p" : "chat-body"} tabIndex="1">
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                        ref={c => {
                            scrollChat = c
                        }}
                        thumbMinSize={0}
                        // noScrollX
                        // momentum
                        onScroll={() => {
                            if (scrollChat.getValues().top === 0 && !loadMore) {
                                loadMoreMessages()
                            }
                        }}

                    >
                        <div className="messages">
                            {displayChat()}
                        </div>
                    </Scrollbars>
                </div>
                {searchTab && <ChatSearchPanel
                    toggleSearch={toggleSearch}
                    userInteractionIds={userInteractionIds}
                    focusMessageEvent={(msgTime) => console.log(msgTime)}
                />}
            </div>
        </>
    )
}

export default ChatPreview