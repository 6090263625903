
const userServiceUrl = (process.env || window.env).REACT_APP_USER_SERVICE_URL;
const userServiceToken = (process.env || window.env).REACT_APP_USER_SERVICE_TOKEN;

export const getUserDetailsBatch = (userIdList) => {

    return new Promise((resolve, reject) => {
        let url = `${userServiceUrl}/user-auth-service/api/v1/user/accounts`;

        let userIdListStr = JSON.stringify(userIdList);

        fetch(url, {
            headers: {
                "Authorization": userServiceToken,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: userIdListStr
        }).then(res => {
            return res.json();
        }).then(data => {
            let userList = data.data.users.reduce((acc, item) => {
                acc[item.userId] = item;

                return acc;
            }, {});
            resolve(userList);
        }).catch(ex => {
            reject(ex);

        });

    })

}