import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faFile, faFilePdf, faFileWord, faFileExcel } from '@fortawesome/free-solid-svg-icons';

function DocMessage({ content }) {
    if (content) {
        return <div>
            {getIcon(content.mimeType)}
            {content.content_title}
            <div
                onClick={() => {
                    /* context.SDKClient.routeInteractionFromOtherRouter(); */
                    console.log("TODO: Download pdf");
                }}
                role="button"
                className="download-icon"
            >
                <FontAwesomeIcon icon={faArrowCircleDown} className="fa-lg" />
            </div>
        </div>

    }
    return ""

}
export default DocMessage

function getIcon(mimeType) {
    if (["application/pdf"].includes(mimeType)) {
        return <FontAwesomeIcon icon={faFilePdf} className="fa-lg" />
    }
    if (["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(mimeType)) {
        return <FontAwesomeIcon icon={faFileWord} className="fa-lg" />
    }
    if (["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(mimeType)) {
        return <FontAwesomeIcon icon={faFileExcel} className="fa-lg" />
    }
    return <FontAwesomeIcon icon={faFile} className="fa-lg" />
}