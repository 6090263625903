import InteractionList from "./interactions/interactionList";
import ChatPreview from "./chat/chatPreview";
import { UserContext } from "./userContext";
import { useEffect, useState } from "react";
import {
  SDKClient,
  TEnums,
  // TUtils
} from "@tmac/sdk";
import ChatLoader from "./chat/chatLoader";
import { startConversation } from "../services/interactionApi";
const proxyUrl = (process.env || window.env).REACT_APP_TMAC_PROXY_URL;
const defaultUser = (process.env || window.env).REACT_APP_DEFAULT_USER;

function Layout() {
  const [loginData, setLoginData] = useState(null);
  const [userList, setUserList] = useState({});

  const initiate = async () => {
    try {
      const conf = {
        proxy: {
          urls: [proxyUrl],
          type: TEnums.ProxyType.SOAP,
          timeout: 60,
        },
        logging: {
          enabled: true,
          level: {
            debug: true,
            info: true,
            warn: true,
            error: true,
          },
          remote: {
            enabled: true,
            timeout: 30,
            count: 30,
          },
          sdkMethods: true,
          sdkEvents: true,
        },
        customScripts: [],
      };

      console.log(`Setting config for SDK : ${JSON.stringify(conf)}`);
      SDKClient.setConfig(conf);

      const ld = await SDKClient.getLoginData();

      console.log(`[GetLoginData] - Returned : ${JSON.stringify(ld)}`);

      console.log(`Location params : ${window.location.search}`);
      let params = new URLSearchParams(window.location.search);

      let agentId = ld && ld.agentData ? ld.agentData.agentId : params.get("agentId") ? params.get("agentId") : defaultUser;
      const tmacServer = params.get("tmacServer");
      console.log(`Agent id selected as ${agentId}`);

      let tempLD = {
        agentData: {
          agentId: agentId,
          tmacServer
        },
      };

      setLoginData(tempLD);
    } catch (error) {
      console.error("Layout", "intiate", error);
    }
  };

  useEffect(() => {
    initiate();
  }, []);

  return (
    <UserContext.Provider value={{ loginData, SDKClient, userList }}>
      <div className="layout">
        <div className="content p-2 flex-wrap">
          <div className="sidebar-group m-2 height-93vh">
            <InteractionList setUserList={setUserList} />
          </div>
          <ChatLoader />
          <ChatPreview />
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default Layout;
