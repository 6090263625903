import { IProxy, ISignalRProxy, ILogging } from '../interfaces';
import { ProxyType } from '../enums';

export class ConfigModel {
  proxy: IProxy;
  signalRProxy?: ISignalRProxy;
  logging?: ILogging;
  customScripts?: string[];

  constructor() {
    this.proxy = {
      urls: [],
      type: ProxyType.SOAP
    };
    this.signalRProxy = {
      enabled: true,
      fallback: true,
      logging: false,
      protocol: 'webSockets',
      timeout: 10,
      ping: 30
    };
    this.logging = {
      enabled: false,
      level: {
        info: false,
        debug: false,
        warn: false,
        error: false
      },
      remote: {
        enabled: false,
        count: 10,
        timeout: 30
      },
      sdkMethods: false,
      sdkEvents: false
    };
    this.customScripts = [];
  }
}
