import moment from 'moment';
import { useContext } from 'react';
import { UserContext } from '../userContext';
import MultiMediaMessage from './multiMediaMessage';
import defaultAvatar from '../../assests/images/default_avatar.png'

function ChatMessage({ mid, messageInfo, onClick, customer }) {
    let context = useContext(UserContext);

    let agentId = context && context.loginData && context.loginData.agentData ? context.loginData.agentData.agentId : "";

    // const direction = messageInfo && messageInfo.sender_id === agentId ? 'out' : 'in';
    const direction = messageInfo.sender_id === customer.userId ? "in" : "out"
    let customerName = "Customer"
    let avatar = defaultAvatar
    if (messageInfo.sender_id === customer.userId) {
        customerName = customer.customerName
        avatar = customer.avatar || avatar
    }
    else if (messageInfo.sender_id === agentId) {
        customerName = "Me"
    }
    else if (direction === "out") {
        customerName = `Agent: ${messageInfo.sender_id}`
    }

    return (
        <>
            <div className={`message-item ${direction === 'out' ? "outgoing-message" : ""}`} key={mid} id={messageInfo.mid} onClick={() => {
                if (onClick)
                    onClick()
            }}>

                {/* <div className={`card-title ${direction === 'out' ? "rc-header" : "lc-header"}`}>{customerName}</div> */}
                {/* {messageInfo.media && <MultiMediaMessage message={messageInfo} />} */}
                <div className="message-avatar">
                    <figure className="avatar">
                        <img src={avatar} className="rounded-circle" alt="image" />
                    </figure>
                    <div className="message-content">
                        <p className="text-primary color-purpl title">
                            {customerName}
                        </p>
                        {messageInfo.media && <MultiMediaMessage message={messageInfo} />}
                        {messageInfo && messageInfo.message_content || messageInfo.message}
                    </div>
                </div>
                <div className="chat-time">
                    <div className="time">{moment(messageInfo.created_at).format('YY-MMM-DD hh:MM a')}</div>
                </div>
            </div>
        </>
    );
}

export default ChatMessage;