import { useState, useEffect, useContext } from 'react';
import InteractionCard from './interactionCard';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getInteractions, getAllUnreadCountsV2Users } from '../../services/conversationApi';
import { getUserDetailsBatch } from '../../services/userAccountsApi';
import { UserContext } from '../userContext';
import Loading from '../shared/loading';
import EmptyInteractionList from '../shared/emptyInteractionList';
import Search from '../shared/search';

let scrollbarUserInteractions = null;

function InteractionList(props) {

    const context = useContext(UserContext);

    // console.log(`Context Value : ${JSON.stringify(context)}`);

    const [users, setUsers] = useState([]);
    const [lastInteraction, setLastInteraction] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [chats, setChats] = useState({});
    const [selectedUser, setSelectedUser] = useState("");
    const [loading, setLoading] = useState(true);

    const initialize = async () => {
        try {

            let agentId = context.loginData && context.loginData.agentData ? context.loginData.agentData.agentId : null;

            console.log(`Agent selected as : ${agentId}`);
            if (agentId) {

                setLoading(true);
                const timstampNow = new Date().getTime() + 100000000
                let { interactions, uniqueUsers, lastInt, newUserIds } = await getInteractions(agentId, timstampNow, 100);

                /* let tempInterations = interactions; */

                setUsers(uniqueUsers);
                setLastInteraction(lastInt);
                //setTempUsers(uniqueUsers);

                setLoading(false);

                /* if (tempInterations) {

                    let keyArray = Object.keys(tempInterations).sort(function (a, b) {

                        let timestamp_a = tempInterations[a] && tempInterations[a].startDateTime ? tempInterations[a].startDateTime : 0;
                        let timestamp_b = tempInterations[b] && tempInterations[b].startDateTime ? tempInterations[b].startDateTime : 0;

                        if (timestamp_a < timestamp_b) {
                            return 1;
                        }
                        if (timestamp_a > timestamp_b) {
                            return -1;
                        }

                        return 0;
                    });

                    tempInterations = keyArray.reduce((acc, key) => {
                        acc[key] = tempInterations[key];
                        return acc;
                    }, {});



                } */


                if (newUserIds && newUserIds.length > 0) {

                    let userList = await getUserDetailsBatch(newUserIds);

                    props.setUserList(userList);

                }

                let summary = await getAllUnreadCountsV2Users(agentId, interactions.map(i => i.sessionId));

                setChats(summary);

            } else {
                console.error("InteractionList", "unable to find agent data");
            }


        } catch (ex) {
            console.error(ex);
        }

    }

    const loadMore = async () => {

        if (users && users.length > 0 && lastInteraction) {

            let agentId = context.loginData && context.loginData.agentData ? context.loginData.agentData.agentId : null;

            let { interactions, uniqueUsers, lastInt, newUserIds } = await getInteractions(agentId, lastInteraction.startDateTime, 100, users);

            setUsers(uniqueUsers);
            setLastInteraction(lastInt);

            if (newUserIds && newUserIds.length > 0) {

                let userList = await getUserDetailsBatch(newUserIds);

                props.setUserList(prevState => { return { ...prevState, ...userList } });

            }

            let summary = await getAllUnreadCountsV2Users(agentId, interactions.map(i => i.sessionId));

            setChats(prevState => {
                return { ...prevState, ...summary }
            });

        }



    }

    useEffect(() => {
        initialize();
    }, [context.loginData]);

    let searchedUsers = users;

    if (searchText) {
        const userList = context?.userList || {}
        searchedUsers = users.filter(usr => userList[usr.userId]?.userId.indexOf(searchText) >= 0
            || userList[usr.userId]?.userName.indexOf(searchText) >= 0
            || userList[usr.userId]?.firstName.indexOf(searchText) >= 0
            || userList[usr.userId]?.lastName.indexOf(searchText) >= 0
            || userList[usr.userId]?.mobileNumber.indexOf(searchText) >= 0
            || usr.userId.indexOf(searchText) >= 0
        )
        /* searchedUsers = searchedKeys.reduce((acc, key) => {
            acc[key] = users[key];
            return acc;
        }, {}) */
    }

    let sortedUsers = searchedUsers.sort(function (a, b) {

        let timestamp_a = a && a.lastInteractionTime ? a.lastInteractionTime : 0;
        let timestamp_b = b && b.lastInteractionTime ? b.lastInteractionTime : 0;

        if (timestamp_a < timestamp_b) {
            return 1;
        }
        if (timestamp_a > timestamp_b) {
            return -1;
        }

        return 0;
    });

    /* let sortedUsers = keyArray.reduce((acc, key) => {
        acc[key] = searchedUsers[key];
        return acc;
    }, {}); */


    return (
        <div className="sidebar active">
            <Search onChange={SearchUser} onEnter={SearchUser} placeholder="Search chats" />
            {loading && (<Loading />)}
            {!loading && (
                <div className="sidebar-body" tabIndex="2" style={{ outline: "none", touchAction: "/" }}>
                    <Scrollbars
                        thumbMinSize={0}
                        ref={c => {
                            scrollbarUserInteractions = c;
                        }}
                        onScrollStop={() => {

                            let values = scrollbarUserInteractions ? scrollbarUserInteractions.getValues() : null;
                            if (values.top > 0.999) {
                                //get more
                                loadMore();
                            }
                        }}>
                        <div className="list-group list-group-flush">
                            {sortedUsers && sortedUsers.length > 0 ? sortedUsers.map(usr =>
                                <InteractionCard
                                    key={usr.userId}
                                    user={usr}
                                    chats={chats && Object.keys(chats).length > 0 ? usr.interactions.reduce((acc, i) => {
                                        acc.push(chats[i]);
                                        return acc;
                                    }, []) : []}
                                    onSelect={setSelectedUser}
                                    isSelected={selectedUser === usr.userId}
                                />) : <EmptyInteractionList />}
                        </div>
                    </Scrollbars>

                </div>

            )}
        </div>
    );

    function SearchUser(result) {

        setSearchText(result);

    }
}

export default InteractionList;