import { v4 as uuidv4 } from 'uuid';
import { Logger } from './logger';

declare const WrsUtils: any;

export class TUtility {
  /**
   * To encrypt string
   * @param ostr encrypt string
   */
  public static toEncodedString(ostr: string): string {
    ostr = ostr.replace(/\s+/g, '');
    let x: number,
      nstr = '';
    const len = ostr.length;
    for (x = 0; x < len; ++x) {
      nstr += (255 - ostr.charCodeAt(x)).toString(36).toUpperCase();
    }
    return nstr;
  }

  /**
   * To decrypt string
   * @param ostr decrypt string
   */
  public static fromEncodedString(ostr: string): string {
    let x: number,
      nstr = '';
    const len = ostr.length;
    for (x = 0; x < len; x += 2) {
      nstr += String.fromCharCode(255 - parseInt(ostr.substr(x, 2), 36));
    }
    return nstr;
  }

  /**
   * to get the current date time
   */
  public static getDT(): string {
    const date = new Date(),
      localeDate = date.toLocaleDateString(),
      localeTime = date.toLocaleTimeString(),
      localeTimeSplit = localeTime.split(' ');
    return localeDate + ' ' + localeTimeSplit[0] + ',' + date.getMilliseconds() + (localeTimeSplit.length > 1 ? ' ' + localeTimeSplit[1] : '');
  }

  /**
   * To load custom scripts
   * @param scriptUrls script URLs
   */
  public static loadScript(scriptUrls: string[]): void {
    try {
      if (scriptUrls.length === 0) {
        return;
      }

      // chcek if the script is already loaded
      const scripts = document.getElementsByTagName('script');
      for (let i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
          Logger.info(`TUtility.loadScript: [${scriptUrls[i]}] is already loaded!`, false);
          scriptUrls = scriptUrls.filter((s) => {
            return s !== scriptUrls[i];
          });
        }
      }

      // check again if the scripts list is empty or not
      if (scriptUrls.length === 0) {
        return;
      }

      // load all the scripts
      scriptUrls.forEach((item) => {
        const node = document.createElement('script');
        node.src = item;
        node.type = 'text/javascript';
        node.async = false;
        node.onload = () => {
          Logger.info(`TUtility.loadScript: [${item}] loaded dynamically`, false);
        };
        // load the script
        document.getElementsByTagName('body')[0].appendChild(node);
      });
    } catch (error) {
      Logger.error('Error in TUtility.loadScript', error, false);
    }
  }

  /**
   * To set the TTL in session storage
   * @param key TTL storage key
   * @param value value to be set
   * @param ttl time to live
   */
  public static setTTLData(key: string, value: string, ttl: number): void {
    const now = new Date();
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * To get the TTL data
   * @param key key set for TTL
   * @param value to return value only
   */
  public static getTTLData(key: string, value = true): { item: string; expiry: number } | string {
    const itemStr = sessionStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      sessionStorage.removeItem(key);
      return null;
    }
    // check if the value to be returned
    if (value) {
      return item.value;
    }
    // return the item
    return item;
  }

  /**
   * To get unique ids
   */
  public static uuid(): string {
    return uuidv4();
  }

  /**
   * To get WrsUtils
   */
  public static wrsUtils<T>(): T {
    try {
      const wrsUtils = WrsUtils;
      return wrsUtils;
    } catch (error) {
      Logger.error('Error in TUtility.wrsUtils', error, false);
    }
  }
}
