
import { createRef } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import EmptyChatList from '../shared/emptyChatList';
import ChatMessage from './chatMessage';

function ChatSearchResults({ results, gotoMessage, loadMore, onScroll }) {
    let scrollChat = createRef()
    if (results && results.length === 0) {
        return <div className="chat-search-result"><EmptyChatList /></div>
    }
    return <div className="chat-search-result">
        <Scrollbars
            thumbMinSize={0} ref={c => {
                scrollChat = c;
            }}
            onScroll={() => {
                if (scrollChat.getValues().top === 0 && !loadMore) {
                    onScroll()
                }
            }}
        >
            {results && results.length > 0 && results.map(res => <ChatMessage key={res.conversation_id} id={1} messageInfo={res} onClick={() => gotoMessage(res)} />)}
        </Scrollbars>
    </div>
}
export default ChatSearchResults