import { SDK } from './sdk';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pj = require('../../package.json');

// eslint-disable-next-line no-var
export declare var SDKClient: SDK;
SDKClient = SDK.getInstance();

console.log('\n\n');
console.log('%c🚫WARNING!', 'color:red; font-size:48px');
console.log('%cThis is a browser feature intended for developers. Do not enter or paste code which you don\'t understand. It may allow attackers to steal your information or impersonate you. See https://en.wikipedia.org/wiki/Self-XSS for more details.', 'font-size:20px');
console.log('\n\n');
console.log(`TMAC-SDK v=${pj.version} attached ⚡, Use 'TMAC.SDKClient' for singleton instance or create an instance using 'TMAC.SDK.getInstance()'✨.`);