const min_value =  (process.env || window.env).REACT_APP_ANI_MIN;
const max_value =  (process.env || window.env).REACT_APP_ANI_MAX;
class AniHandler {

    constructor(){        
        this.range = Array.from({length: (max_value - min_value)}, (v, k) => parseInt(k) + parseInt(min_value));
        console.debug("Min: %i , Max : %i, Range : %s",min_value,max_value,this.range.toString());
    }

    GetAni(){
        if(this.range.length === 0){
            this.range = Array.from({length: (max_value - min_value)}, (v, k) => parseInt(k) + parseInt(min_value));
        }
        return this.range.shift();
    }
}

const aniHandler = new AniHandler();
Object.freeze(aniHandler);

export default aniHandler;