
// declare global
declare global {
    interface Window {
        $: any
        jQuery: any
    }
}

// init jquery
let $ = undefined;

try {
    // assign the window $ object
    $ = window.$;

    // import jquery
    $ = require('jquery');

    // assign to window $
    window.$ = $;

    // assign to window jQuery
    window.jQuery = $;
} catch (error) {
    //
}

try {
    // import signalr
    require('signalr');
} catch (error) {
    //
}

// create an interface for jQuerySignalR
interface jQuerySignalR extends JQuery, SignalR {
}

// create an object for Utils to export
// eslint-disable-next-line no-var
export declare var jQ: jQuerySignalR;

// assign value
jQ = $;

