/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ProxyType } from '../enums/sdk';
import {
  AgentProfile,
  CommandResultEvent,
  IAddEvent,
  IAgentAVMessage,
  IAgentCommand,
  IAgentData,
  IAgentNotification,
  IAgentSnap,
  IAUXCodes,
  IAVControlMessage,
  ICallWorkCode,
  ICancelTransferNotification,
  IChangeEmailStatus,
  IChangeStatus,
  IChangeTextChatConferenceType,
  ICloneEmail,
  IComposeSMS,
  IConferenceTransferCall,
  IConfig,
  IConnection,
  ICreateAgentReminder,
  ICreateAgentReminderTask,
  ICreateGroup,
  IDeflectToDigitalArgs,
  IDeleteEmail,
  IDownloadFaxFile,
  IEndTextChat,
  IEventAcknowledgement,
  IExternalConnectGenericCommand,
  IGenericCTICommand,
  IGetAgentActivity,
  IGetAgentListStaffedArgs,
  IGetAgentReminder,
  IGetAgentSessionsList,
  IGetAgentStatus,
  IGetChatInteractionHistory,
  IGetDataServerSignalRNatUrlsArgs,
  IGetEmailTemplates,
  IGetFaxTemplate,
  IGetInteractionData,
  IGetInteractionHistory,
  IGetTMACServer,
  IGetVIPData,
  IInstantMessage,
  IInteractionComment,
  IInvokeAsyncChatDataApiArgs,
  ILogin,
  ILogout,
  IMakeCall,
  IMakeCallWithUUI,
  IMarkEmailAsSpam,
  IMaskInboxEmail,
  IMiniDashboard,
  INotifyTyping,
  IOpenIntent,
  IPullEmailFromMakerDraft,
  IPullEmailFromMakerQueue,
  IPullEmailFromSentItems,
  IRecoverInteractions,
  IRegisterCallback,
  IRejectEmail,
  IReplyBulkEmailsInQueue,
  IRespondTextChatTransferNotification,
  IRespondToWqDacRequest,
  IRespondTransferNotification,
  IResponse,
  IRestRequest,
  IRouteInteractionFromOtherRouterArgs,
  ISaveEmail,
  ISaveEmailAsEml,
  ISendActionMessage,
  ISendAgentActivity,
  ISendAppMessage,
  ISendBroadcastArgs,
  ISendDTMF,
  ISendEmail,
  ISendFax,
  ISendInstantSMS,
  IsendMessageToAgent,
  ISendNotificationArgs,
  ISendSMS,
  ISendTextChat,
  ISendTextChatTransferNotification,
  ISendTransferNotification,
  ISendWhatsApp,
  ISetAgentData,
  IStackToAgentSession,
  ITCISViolation,
  ITextChatConferenceToBot,
  ITransferEmailToAgent,
  ITransferEmailToSkill,
  ITransferInteraction,
  ITransferIVR,
  ITransferTextChat,
  ITransferTextChatToQueue,
  IUIEvent,
  IUpdateAgentReminder,
  IUpdateAsyncChatDataArgs,
  IUpdateFaxStatus,
  IUpdateGroup,
  IUpdateIntent,
  IUpdateMosDetails,
  IUploadFiles,
  IVideoSnap,
  IVoiceSendMessage
} from '../interfaces';
import { ConfigModel } from '../models';
import { HttpClient, Logger, SignalRWrapper, TUtility } from '../utils';
import { EventProcessor } from './event-processor';
import { SDK } from './sdk';

export class SDKInternal extends HttpClient {
  // SDK connection object
  private connections: IConnection;
  // agent data object
  private agentData: IAgentData;
  // get event registered falg
  private getEventRegistered: boolean;
  // get event polling interval
  private eventPollInterval: number;
  // event processor instance
  private eventProcessor: EventProcessor;
  // method names to ignore appending TMAC Server in requestArgs
  private ignoreServerIdList: string[];
  // methods to ignore while logging request response even if enabled
  private ignoreMethodLoggingList: string[];
  // TMAC proxy type
  private proxyType: string;
  // open interactions reference
  private interactionList: any[];

  // SDK config object
  public config: IConfig;
  // signalR connection referece
  public signalRConnector: SignalRWrapper;

  constructor(public sdkInstance: SDK) {
    // call parent class constructor
    super();

    // assign the class property values
    this.config = new ConfigModel();
    this.connections = {
      connectedProxyUrl: '',
      eventMode: '',
      signalRUrl: '',
      tmacServer: ''
    };
    this.initAgentData();
    this.getEventRegistered = false;
    this.eventPollInterval = 600;
    this.eventProcessor = new EventProcessor(this);
    this.signalRConnector = null;
    this.ignoreServerIdList = [
      'GetProxyVersion',
      'GetLanID',
      'GetUserDomainList',
      'GetAgentListStaffed',
      'GetAgentSessionsList',
      'GetAgentStatus',
      'UploadFiles',
      'RemoveFile',
      'RestRequest',
      'GetWidgetPreviewJson',
      'GetTmacLoginJson'
    ];
    this.ignoreMethodLoggingList = ['GetEvents', 'NotifyTextChatTyping', 'FreezeTextChatAutoResponse'];
    this.proxyType = ProxyType.SOAP;
    this.interactionList = [];
  }

  private initAgentData() {
    this.agentData = {
      accessRole: '',
      agentId: '',
      agentName: '',
      agentProfile: '',
      agentStatus: '',
      agentSkills: [],
      channelList: [],
      deviceId: '',
      featuresList: [],
      isLoggedIn: false,
      sessionKey: '',
      isMSAgent: false,
      lanId: '',
      levelHierarchy: '',
      levelHierarchyFilterType: '',
      levelHierarchyId: '',
      msServerIDs: null,
      password: '',
      profilePicture: '',
      supervisorId: '',
      supervisorName: '',
      teamId: '',
      teamName: '',
      tmacServer: '',
      loginJsonData: '',
      auxCodes: [],
      remoteConfig: '',
      chatGreetingText: '',
      catagory: ''
    };
  }

  public reInitAgentData(): void {
    // remove the data from localstorage
    localStorage.removeItem(`_loginData-${this.agentData.agentId}`);
    // re-init agent data
    this.initAgentData();
  }

  public setConfig(config: IConfig): void {
    // store the configs locally
    this.config = { ...this.config, ...config };
    // check if the proxy type is provided
    if (config.proxy.type) {
      this.proxyType = ProxyType[config.proxy.type] ? ProxyType[config.proxy.type] : ProxyType.SOAP;
      Logger.info(`Proxy type=[${config.proxy.type}]`, false);
    }
    // check if the proxy urls are provided then set it
    if (config.proxy.urls && config.proxy.urls.length > 0) {
      this.setUrls(config.proxy.urls);
      Logger.info(`Proxy Urls=[${config.proxy.urls}]`, false);
    }
    // check if the proxy request timeout is provided then set it
    if (config.proxy.timeout) {
      this.setTimeout(config.proxy.timeout * 1000);
      Logger.info(`Proxy request timeout is set to [${config.proxy.timeout}]`, false);
    }
    // check if custom scripts are to be loaded
    if (config.customScripts && config.customScripts.length > 0) {
      Logger.info('Loading custom scripts, count=' + config.customScripts.length, false);
      TUtility.loadScript(config.customScripts);
    }
    // set the logger config also
    Logger.setConfig(config);
  }

  public getConfig(): IConfig {
    // return copy of config
    return { ...this.config };
  }

  public setAppConfig(type: string, config: any): void {
    localStorage.setItem(`_appconfig_${type ?? 'tmac'}`, config);
  }

  public getAppConfig(type: string): any {
    localStorage.getItem(`_appConfig_${type}`) ?? null;
  }

  public getConnectionData(): IConnection {
    // update the connected proxy URL
    this.connections.connectedProxyUrl = this.getConnectedUrl();
    // update the login data
    this.updateLoginData();
    // return copy of connections
    return { ...this.connections };
  }

  public updateConnectionData(key: string, value: any): void {
    // update the property value
    this.connections[key] = value;
    // update login data
    this.updateLoginData();
  }

  public setAgentData(requestArgs: ISetAgentData) {
    try {
      this.agentData.agentId = requestArgs.agentId;
      this.agentData.deviceId = requestArgs.deviceId;
      this.agentData.tmacServer = requestArgs.tmacServer;
      // update login data
      this.updateLoginData();
      Logger.info('setAgentData: Agent data set successfully', false);
      return true;
    } catch (error) {
      Logger.error('Error in setAgentData', error, false);
    }
    return false;
  }

  public getAgentData() {
    // return copy of agentData
    return { ...this.agentData };
  }

  public updateAgentData(key: string, value: any) {
    // update the property value
    this.agentData[key] = value;
    // update login data
    this.updateLoginData();
  }

  public addInteraction(interaction: any) {
    this.interactionList.push(interaction);
  }

  public removeInteraction(interactionId: number) {
    this.interactionList = this.interactionList.filter((i: any) => i.InteractionID !== interactionId);
  }

  public getInteractions() {
    return [...this.interactionList];
  }

  public getInteractionById(interactionId: number) {
    // get the interaction by ID
    const interaction = this.interactionList.filter((i: any) => i.InteractionID === interactionId);
    // check if the interaction is there
    if (interaction.length > 0) {
      // return the interaction
      return interaction[0];
    }
    // return null if no interaction found
    return null;
  }

  public deRegisterEvent(): void {
    // set getEventRegistered to false
    this.getEventRegistered = false;
    Logger.info('GetEvents de-registered!');
    // check the type of event registration
    // if signalR then close it
    if (this.connections.eventMode && this.connections.eventMode !== 'eventPolling' && this.signalRConnector.isConnected) {
      this.signalRConnector.close(true);
    }
  }

  /**
   * To update SignalRConnector reference
   *
   * @param data
   */
  public changeSignalRConnection(event: any): void {
    // update the connection data signalRUrl
    this.updateConnectionData('signalRUrl', event.TmacSignalRUrl);

    // // set the urls to signalr connector
    // this.signalRConnector.setUrl([event.TmacSignalRUrl]);
    // // change the query string to new session key
    // this.signalRConnector.setQueryString({
    //   deviceId: this.agentData.deviceId,
    //   agentSessionKey: this.agentData.sessionKey
    // });
    // // check if connected to existing url then disconnect and connect to new url
    // if (this.signalRConnector.isConnected()) {
    //     Logger.info(`TmacServerConnectionSuccess: disconnecting and connecting to new url=${event.TmacSignalRUrl}`);
    //     // set this flag to true so that it will not ask proxy for new URL on disconnect
    //     this.signalRConnector.tryReconnecting = true;
    //     // close the connection and mark forceClose false so that it will try connecting to new URL on disconnect
    //     this.signalRConnector.close(false);
    // }
    // else {
    //     Logger.warn(`TmacServerConnectionSuccess: cannot find existing connection with SignalR!`);
    //     this.changeToEventPolling();
    // }

    // close the existing connection with the old server and create new connection
    this.signalRConnector.close(true);

    // clear old the connection
    this.signalRConnector = null;

    // if signalR connection ia not success then fallback to polling
    if (!this.checkForSignalRServer([event.TmacSignalRUrl])) {
      this.changeToEventPolling();
    }
  }

  public changeToEventPolling() {
    try {
      this.connectivityStatus(2, 'Unable to connect to the server for events, falling back to event polling!');
      // close the signalR connection
      this.signalRConnector.close(true);
      // assign the instance to sdkInstance signalR prop
      this.sdkInstance.signalR = this.signalRConnector;

      // [MS: Dec 14, '21] do not reset the signalrUrl, it will be useful for refresh scenario
      // clear the connection data signalrUrl
      // this.updateConnectionData('signalRUrl', '');

      setTimeout(() => {
        // set the connectivity status
        this.connectivityStatus(1, '');
      }, 1000);

      // poll for event once
      this.getEvents(true);
    } catch (error) {
      Logger.error('Exception in SDKInternal.changeToEventPolling', error);
    }
  }

  public async getProxyVersion(userObject: any) {
    try {
      // send request
      return await this.call('GetProxyVersion', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTMACVersion(tmacServer: string, userObject: any) {
    try {
      // prepare request args
      const data = { tmacServer };
      // send request
      return await this.call('GetTMACVersion', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getLanID(userObject: any) {
    try {
      // send request
      return await this.call('GetLanID', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getUserDomainList(userObject: any) {
    try {
      // send request
      return await this.call('GetUserDomainList', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async login(requestArgs: ILogin, userObject: any) {
    try {
      // send request
      const result: IResponse = await this.call('Login', requestArgs, userObject);
      // check if the result is not null and assign the data
      if (result.response !== null) {
        // get the response data
        const response: CommandResultEvent = result.response;
        // check the response result code
        if (response.ResultCode > 0) {
          // set the agent data
          this.agentData.isLoggedIn = true;
          this.agentData.lanId = requestArgs.lanId;
          this.agentData.password = TUtility.toEncodedString(requestArgs.password);
          this.agentData.sessionKey = response.AgentSessionKey;
          this.agentData.loginJsonData = requestArgs.jsonData;
          this.agentData.tmacServer = response.TmacServerName || '';
          this.connections.tmacServer = response.TmacServerName || '';
          // check if response data is available
          if (response.Data) {
            this.updateAgentProfile(response.Data);
          }
          this.agentData.remoteConfig = response.OtherData || '';
          // assign the signalR url to connection object
          this.connections.signalRUrl = response.TmacSignalRUrl || '';
          // check if agent status is available
          if (!this.agentData.agentStatus) {
            // get agent status
            const agentStatus = await this.getMyStatus(null);
            // check the response
            if (agentStatus.response && agentStatus.response.ResultCode === 1) {
              // assign the agent status
              this.agentData.agentStatus = agentStatus.response.ResultMessage;
            }
          }
          // update local storage login data
          this.updateLoginData();
          // set the logger user info
          Logger.setUserInfo(`${this.agentData.agentId} - ${this.agentData.deviceId}`);
        }
        // log the login response
        Logger.info(`Login: response - ${response.ResultCode}, ${response.ResultMessage}`);
        // return the response
        return result;
      } else {
        Logger.info('Login: response data is null');
        // create and return the response
        return {
          response: {
            ResultCode: -1234,
            ResultMessage: 'Unexpected response from the server!'
          },
          userObject
        };
      }
    } catch (error) {
      Logger.error('Exception in login', error);
      return Promise.reject(error);
    }
  }

  public async checkAgentSession(userObject: any) {
    try {
      // if agentId is not provided take from the sdk
      if (!this.agentData.agentId) {
        return Promise.reject('Agent Id not found!');
      }

      // send request
      const result = await this.call('CheckAgentSession', { agentId: this.agentData.agentId }, userObject);
      if (!result.response) {
        // check the login data
        const loginData = await this.getLoginData(this.agentData.agentId);
        // if login data found in storage after session not found, then remove it
        // first check and remove agentId based _loginData
        if (loginData && localStorage.getItem(`_loginData-${this.agentData.agentId}`)) {
          localStorage.removeItem(`_loginData-${this.agentData.agentId}`);
        }
        // second check and remove _latestLoginData
        else {
          localStorage.removeItem('_latestLoginData');
        }
      }
      // return the response
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async stackAgentSession(requestArgs: IStackToAgentSession, userObject: any) {
    // send request
    const result: IResponse = await this.call('StackAgentSession', requestArgs, userObject);
    // check if the result is not null and assign the data
    if (result.response !== null) {
      // get the response data
      const response: CommandResultEvent = result.response;
      // check the response result code
      if (response.ResultCode > 0) {
        // set the agent data
        this.agentData.isLoggedIn = true;
        this.agentData.sessionKey = response.AgentSessionKey;
        this.agentData.tmacServer = response.TmacServerName || '';
        this.connections.tmacServer = response.TmacServerName || '';
        // check if response data is available
        if (response.Data) {
          this.updateAgentProfile(response.Data);
        }
        this.agentData.remoteConfig = response.OtherData ? response.OtherData : '';
        // assign the signalR url to connection object
        this.connections.signalRUrl = response.TmacSignalRUrl || '';
        // add the catagory
        this.agentData.catagory = response.Catagory || '';
        // check if agent status is available
        if (!this.agentData.agentStatus) {
          // get agent status
          const agentStatus = await this.getMyStatus(null);
          // check the response
          if (agentStatus.response && agentStatus.response.ResultCode === 1) {
            // assign the agent status
            this.agentData.agentStatus = agentStatus.response.ResultMessage;
          }
        }
        // update local storage login data
        this.updateLoginData();
        // set the logger user info
        Logger.setUserInfo(`${this.agentData.agentId} - ${this.agentData.deviceId}`);
      }
      // log the login response
      Logger.info(`StackAgentSession: response - ${response.ResultCode}, ${response.ResultMessage}`);
      // return the response
      return result;
    } else {
      Logger.info('StackAgentSession: response data is null');
      // create and return the response
      return {
        response: {
          ResultCode: -1234,
          ResultMessage: 'Unexpected response from the server!'
        },
        userObject
      };
    }
  }

  public updateAgentProfile(data: AgentProfile): void {
    try {
      this.agentData.accessRole = data.AccessRole;
      this.agentData.agentStatus = data.CurrentAgentStatus || '';
      this.agentData.agentId = data.AgentID || '';
      this.agentData.agentName = data.AgentName || '';
      this.agentData.agentProfile = data.UserProfileForAgent || '';
      this.agentData.deviceId = data.StationID || '';
      this.agentData.profilePicture = data.AgtAgentProfileModel ? data.AgtAgentProfileModel.ProfilePicture : '';
      this.agentData.teamId = data.TeamID || '';
      this.agentData.teamName = data.TeamName || '';
      this.agentData.supervisorId = data.SupervisorID || '';
      this.agentData.supervisorName = data.SupervisorName || '';
      this.agentData.levelHierarchyId = data.LevelHierarchyID || '';
      this.agentData.levelHierarchy = data.LevelHierarchy || '';
      this.agentData.levelHierarchyFilterType = data.LevelHierarchyFilterType || '';
      this.agentData.channelList = data.AgentTabCounts || [];
      this.agentData.featuresList = data.AgentFeatures || [];
      this.agentData.isMSAgent = data.IsMSAgent !== null ? data.IsMSAgent : false;
      this.agentData.msServerIDs = data.MSServerIDs || null;
      this.agentData.chatGreetingText = data.TextChatGreetingText || '';
      // update the login data
      this.updateLoginData();
    } catch (error) {
      Logger.error('Exception in updateAgentProfile', error);
    }
  }

  private updateLoginData() {
    // update the local storage login data
    const data = JSON.stringify({
      // config: this.config,
      agentData: this.agentData,
      connectionData: this.connections
    });
    // set the data
    localStorage.setItem(`_loginData-${this.agentData.agentId}`, data);
    localStorage.setItem(`_latestLoginData`, data);
  }

  public async getLoginData(agentId?: string) {
    try {
      // get the agentId
      const user = agentId || this.agentData.agentId;
      // get data from local storage
      const loginData = user ? localStorage.getItem(`_loginData-${user}`) : localStorage.getItem('_latestLoginData');
      // check if login data is present
      if (loginData) {
        const jsonData = JSON.parse(loginData);
        // check if the json is valid and return the data
        if (jsonData && Object.keys(jsonData).length !== 0) {
          // check if the existing agent data and config has value
          if (this.config === null || this.agentData.agentId === '') {
            // [ReviewCodeLine, Feb 25, '21] commented, check whether to update config?
            // this.config = jsonData.config;
            this.agentData = jsonData.agentData;
            this.connections = jsonData.connectionData;
          }
          // check if agent status is available
          if (!this.agentData.agentStatus) {
            // get agent status
            const agentStatus = await this.getMyStatus(null);
            // check the response
            if (agentStatus.response && agentStatus.response.ResultCode === 1) {
              // assign the agent status
              this.agentData.agentStatus = jsonData.agentData.agentStatus = agentStatus.response.ResultMessage;
            }
          }
          return jsonData;
        }
      }
    } catch (error) {
      Logger.error('Exception in getLoginData', error);
      return Promise.reject(error);
    }
    return null;
  }

  public async logout(requestArgs: ILogout, userObject: any) {
    try {
      // check if the user logout
      const userLogout = !requestArgs.deviceId;
      // check if agentId and deviceId is provided
      requestArgs.deviceId = requestArgs.deviceId || this.agentData.deviceId;
      // send request
      const result: IResponse = await this.call('Logout', requestArgs, userObject);
      if (result.response !== null) {
        Logger.info(`Logout: response - ${result.response.ResultCode}, ${result.response.ResultMessage}`);
        // if the logout deviceId is sent in request, it could be supervisor logging out another agent, so ignore
        // and check if the logout success
        if (userLogout && result.response.ResultCode === 0) {
          // re-init agent data
          this.reInitAgentData();
          // de-register from event socket
          this.deRegisterEvent();
        }
      } else {
        Logger.info(`Logout: response is null`);
      }
      // return the response
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTMACServerID(requestArgs: IGetTMACServer, userObject: any) {
    try {
      // send request
      return this.call('GetTMACServerID', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public getEvents(force = false) {
    try {
      // check if get event is already registered if not force get
      if (!force && this.getEventRegistered) {
        Logger.warn('GetEvents is already registered!');
        return;
      }
      // invoke get event internal
      this.getEventsInternal(force);
      // set the get event registered flag to true
      this.getEventRegistered = true;
      Logger.info('GetEvents registered!');
    } catch (error) {
      Logger.error('Exception in getEvents', error);
    }
  }

  private getEventsInternal(force: boolean) {
    try {
      // check if the agent is logged in
      if (!this.agentData.isLoggedIn) {
        Logger.warn('Event polling failed, Agent is not logged in');
        // set the get event registered flag to true
        this.getEventRegistered = false;
        this.emitSDKEvent({
          EventName: 'AgentForcedLogoffEvent',
          CreatedTime: new Date(),
          InteractionID: 0,
          Reason: 'Agent session not available',
          Type: 'SessionNotFound'
        });
        // return if not logged in
        return;
      }

      // [MS: Dec 14, '21] added check for 'force' flag
      // check if the signalR is provided, then try connecting to socket
      if (!force && this.config.signalRProxy.enabled && this.connections.signalRUrl) {
        // if signalR connection success then return
        if (this.checkForSignalRServer([this.connections.signalRUrl])) {
          return;
        } else {
          // set the signalR url to empty
          this.connections.signalRUrl = '';
        }
      }

      // change the event mode to event polling
      this.connections.eventMode = 'eventPolling';

      // prepare get event request args
      const data = {
        agentId: this.agentData.agentId,
        deviceId: this.agentData.deviceId,
        lanId: this.agentData.lanId,
        password: TUtility.fromEncodedString(this.agentData.password),
        sessionKey: this.agentData.sessionKey,
        jsonData: this.agentData.loginJsonData
      };

      // send request and process the response
      this.call('GetEvents', data, null)
        .then((result: IResponse) => {
          // check for null
          if (result.response !== null) {
            // process the event list
            result.response.forEach((event: IUIEvent) => {
              // process the event
              this.eventProcessor.process(event);
            });
          }

          // poll for events
          setTimeout(() => {
            this.getEventsInternal(true);
          }, this.eventPollInterval);
        })
        .catch(() => {
          // set the connectivity status
          this.connectivityStatus(2, 'Error in reaching server for events');

          // poll for events again
          setTimeout(() => {
            this.getEventsInternal(true);
          }, this.eventPollInterval);
        });
    } catch (error) {
      Logger.error('Exception in getEventsInternal', error);
    }
  }

  private checkForSignalRServer(urls: string[]) {
    try {
      Logger.debug('Register to signalR TMAC server for events, urls=[' + urls + ']');

      // [MS: Sep 29, '21] commented below query string to solve signalr security issue
      // define the query string
      // const qs = {
      //   deviceId: this.agentData.deviceId,
      //   agentSessionKey: this.agentData.sessionKey,
      //   catagory: this.agentData.catagory
      // };

      // define the query string
      const qs = {
        version: '2'
      };

      // create a signalR connection to server
      this.signalRConnector = new SignalRWrapper(
        urls,
        this.config.signalRProxy.protocol,
        'TmacServer',
        qs,
        'TMACServerHub',
        this.config.signalRProxy.logging,
        this.config.signalRProxy.timeout
      );

      // check if the connection is created successfully
      if (this.signalRConnector !== null) {
        // on registered event from server
        this.signalRConnector.hub.on('onRegistered', () => {
          //
        });

        // TMAC events from server
        this.signalRConnector.hub.on('onTMACEvents', (event: IUIEvent) => {
          // set the connectivity status
          this.connectivityStatus(1, '');
          // process the event
          this.eventProcessor.process(event);
        });

        // pong received from server, so ping after 30s to eliminate inactive disconnect issue
        this.signalRConnector.hub.on('pong', () => {
          setTimeout(() => {
            if (this.signalRConnector.isConnected()) this.signalRConnector.hub.invoke('Ping', this.signalRConnector.connectionId);
          }, (this.config.signalRProxy.ping ?? 30) * 1000);
        });

        // connection connected event
        this.signalRConnector.events.on('SignalRConnectedEvent', (evt) => {
          // set the event mode
          this.connections.eventMode = this.signalRConnector.getTransport();
          // [MS: Sep 29, '21] invoke TMAC Server "Connect" method to establish agent session connection
          this.signalRConnector.hub.invoke(
            'Connect',
            this.signalRConnector.connectionData.deviceId,
            this.signalRConnector.connectionData.sessionKey,
            this.signalRConnector.getTransport(),
            this.signalRConnector.connectionData.catagory,
            evt.fallback
          );
          // set the connectivity status
          this.connectivityStatus(1, 'Connected to server for events');

          // send custom SDK event
          this.emitSDKEvent({
            EventName: 'SignalRConnectedEvent',
            CreatedTime: new Date(),
            InteractionID: 0
          });

          // send ping to server after 30s to eliminate inactive disconnect issue
          setTimeout(() => {
            if (this.signalRConnector.isConnected()) this.signalRConnector.hub.invoke('Ping', this.signalRConnector.connectionId);
          }, (this.config.signalRProxy.ping ?? 30) * 1000);
        });
        // connection disconnected event
        this.signalRConnector.events.on('SignalRDisconnectedEvent', () => {
          this.signalrErrorHandler(false);

          // send custom SDK event
          this.emitSDKEvent({
            EventName: 'SignalRDisconnectedEvent',
            CreatedTime: new Date(),
            InteractionID: 0
          });
        });
        // connection slow event
        this.signalRConnector.events.on('SignalRConnectionSlowEvent', () => {
          // set the connectivity status
          this.connectivityStatus(0, 'Connection to the server is slow, there may be delay in getting events');
          setTimeout(() => {
            this.connectivityStatus(1, '');
          }, 1000);

          // send custom SDK event
          this.emitSDKEvent({
            EventName: 'SignalRConnectionSlowEvent',
            CreatedTime: new Date(),
            InteractionID: 0
          });
        });
        // connection reconnecting event
        this.signalRConnector.events.on('SignalRReconnectingEvent', () => {
          // set the connectivity status
          this.connectivityStatus(0, 'Reconnecting to the server for events...');

          // send custom SDK event
          this.emitSDKEvent({
            EventName: 'SignalRReconnectingEvent',
            CreatedTime: new Date(),
            InteractionID: 0
          });
        });
        // connection reconnected event
        this.signalRConnector.events.on('SignalRReconnectedEvent', () => {
          // set the connectivity status
          this.connectivityStatus(1, 'Re-connected to server for events');
          // [MS: Sep 29, '21] invoke TMAC Server "Reconnect" method to establish agent session connection
          this.signalRConnector.hub.invoke(
            'Reconnect',
            this.signalRConnector.connectionData.deviceId,
            this.signalRConnector.connectionData.sessionKey,
            this.signalRConnector.getTransport(),
            this.signalRConnector.connectionData.catagory
          );

          // send custom SDK event
          this.emitSDKEvent({
            EventName: 'SignalRReconnectedEvent',
            CreatedTime: new Date(),
            InteractionID: 0
          });

          // send ping to server after 30s to eliminate inactive disconnect issue
          setTimeout(() => {
            this.signalRConnector.hub.invoke('Ping', this.signalRConnector.connectionId);
          }, (this.config.signalRProxy.ping ?? 30) * 1000);
        });
        // connection data received event
        this.signalRConnector.events.on('SignalRReceivedEvent', () => {
          //
        });
        // connection error event
        this.signalRConnector.events.on('SignalRErrorEvent', () => {
          this.signalrErrorHandler(true);

          // send custom SDK event
          this.emitSDKEvent({
            EventName: 'SignalRErrorEvent',
            CreatedTime: new Date(),
            InteractionID: 0
          });
        });

        // store the connection data
        this.signalRConnector.connectionData = {
          deviceId: this.agentData.deviceId,
          sessionKey: this.agentData.sessionKey,
          catagory: this.agentData.catagory
        };

        // connect to the server
        this.signalRConnector.connect();

        // assign the instance to sdkInstance signalR prop
        this.sdkInstance.signalR = this.signalRConnector;
      }
      // return true to process
      return true;
    } catch (error) {
      Logger.error('Exception in checkForSignalRServer', error);
    }
    // return false to fallback to get event
    return false;
  }

  private signalrErrorHandler(fallback: boolean): void {
    // set the connectivity status
    this.connectivityStatus(2, 'Disconnected from server for events, checking for session in server...');
    // check if get event fallback is configured, by default true
    if (!fallback && !this.config.signalRProxy.fallback) {
      Logger.debug(`SignalRWrapper: [TmacServer]: GetEvent fallback is disabled in config!`);
      return;
    }
    // check that flag to get the tmac server
    if (!this.signalRConnector.tryReconnecting && this.agentData.isLoggedIn) {
      // set the flag to true so that it will not call on every disconnect/error
      this.signalRConnector.tryReconnecting = true;
      // connect to proxy and get the new tmac url
      this.getTMACServerOnSocketFail().then((event: IResponse) => {
        // check if the response is null or have any data
        if (event.response !== null && event.response.length > 0) {
          // process the event
          this.eventProcessor.process(event.response[0]);
        }
      });
    }
  }

  public async eventAcknowledgement(requestArgs: IEventAcknowledgement, userObject: any) {
    // prepare request args
    const data = {
      ...requestArgs,
      agentId: this.agentData.agentId
    };
    // send request
    return await this.call('EventAcknowledgement', data, userObject);
  }

  private async getTMACServerOnSocketFail() {
    // prepare request args
    const data = {
      deviceId: this.agentData.deviceId,
      agentId: this.agentData.agentId,
      lanId: this.agentData.lanId,
      password: TUtility.fromEncodedString(this.agentData.password),
      jsonData: this.agentData.loginJsonData
    };
    // send request
    return await this.call('GetTMACServerOnSocketFail', data, null);
  }

  public async recoverInteractions(requestArgs: IRecoverInteractions) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('RecoverInteractions', data, null);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getRepeatQueueEvents() {
    try {
      // prepare request args
      const data = {
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('GetRepeatQueueEvents', data, null);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public emitSDKEvent(event: any) {
    this.eventProcessor.process(event);
  }

  ////////// GENRIC METHODS //////////

  public async loadAUXCodes(byTeam: boolean, userObject: any) {
    try {
      // prepare request args
      const data = {
        // check if to get for the logged in agent team
        agentId: byTeam ? this.agentData.agentId : ''
      };
      // send request
      const result: IResponse = await this.call('LoadAUXCodes', data, userObject);
      // check if the result is null
      if (result.response !== null && result.response.length > 0) {
        // assign the filtered AUX codes back to result also assign the AUX codes to the agent data
        result.response = this.agentData.auxCodes = this.filterAUXCodes(result.response);
      }
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public filterAUXCodes(codes: IAUXCodes[]): IAUXCodes[] {
    // check for null/empty
    if (codes === null || codes.length === 0) {
      return [];
    }
    // create a copy
    let temp: IAUXCodes[] = [...codes];

    // remove the item ACW and Logout from server - commented
    // temp = temp.filter((aux: IAUXCodes) => aux.Value !== 110 && aux.Value !== 111);

    // we will make ACW and Logout from server disabled, it can be enabled if needed from the application
    temp = temp.map((aux: IAUXCodes) => {
      if (aux.Value === 110 || aux.Value === 111) {
        aux.Display = 0;
      }
      return aux;
    });

    // sort the items
    // sort the array on values
    temp.sort((a, b) => {
      return a.Value - b.Value;
    });
    // check if the available on top
    if (temp.filter((t) => t.Value === 112).length > 0 && temp[0].Value !== 112) {
      // put available on top after sorting which has value 112
      temp.unshift(temp.pop());
    }
    // return the data
    return temp;
  }

  public async changeStatus(requestArgs: IChangeStatus, userObject: any) {
    try {
      // check if deviceId is provided
      requestArgs.deviceId = requestArgs.deviceId ?? this.agentData.deviceId;
      // check if tmacServer is provided
      requestArgs.tmacServer = requestArgs.tmacServer ?? this.agentData.tmacServer;

      // send request
      return this.call('ChangeStatus', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadIntents(userObject: any) {
    try {
      // send request
      return this.call('LoadIntents', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateIntent(requestArgs: IUpdateIntent, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('UpdateIntent', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async openIntent(requestArgs: IOpenIntent, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('OpenIntent', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async selectInteraction(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SelectInteraction', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async closeInteraction(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('CloseInteraction', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async reloadInteraction(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('ReloadInteraction', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getLoggedInAgentList(tmacServer: string, userObject: any) {
    try {
      // prepare request args
      const data = tmacServer ? { tmacServer } : new Object();
      // send request
      return this.call('GetLoggedInAgentList', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAgentListStaffed(requestArgs: IGetAgentListStaffedArgs, userObject: any) {
    try {
      const { agentId, teamId } = this.agentData;
      const requestParams = {
        agentId: requestArgs.agentId ? agentId : '',
        teamId: requestArgs.byTeam ? teamId : '',
        type: requestArgs.type
      };
      // send request
      return this.call('GetAgentListStaffed', requestParams, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAgentSessionsList(requestArgs: IGetAgentSessionsList, userObject: any) {
    try {
      // send request
      return this.call('GetAgentSessionsList', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFavouriteSkills(userObject: any) {
    try {
      // send request
      return this.call('GetFavouriteSkills', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSpeedDialNumbers(byTeam: boolean, userObject: any) {
    try {
      // prepare request args
      const data = {
        // check if to get for the logged in agent team
        agentId: byTeam ? this.agentData.agentId : ''
      };
      // send request
      return this.call('GetSpeedDialNumbers', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAgentStatus(requestArgs: IGetAgentStatus, userObject: any) {
    try {
      // send request
      return this.call('GetAgentStatus', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getMyStatus(userObject: any) {
    try {
      // get the agent status
      return await this.getAgentStatus(
        {
          agentId: this.agentData.agentId,
          deviceId: this.agentData.deviceId,
          tmacServer: this.agentData.tmacServer
        },
        userObject
      );
    } catch (error) {
      Logger.error('Exception in getMyStatus', error);
    }
  }

  public async getQueueStatus(skillId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        skillId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('GetQueueStatus', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTmacWallboardSkills(userObject: any) {
    try {
      // send request
      return this.call('GetTmacWallboardSkills', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTmacWallboardForSkill(skillId: string, userObject: any) {
    try {
      // prepare request args
      const data = { skillId };
      // send request
      return this.call('GetTmacWallboardForSkill', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAgentReminders(requestArgs: IGetAgentReminder, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('GetAgentReminders', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createAgentReminder(requestArgs: ICreateAgentReminder, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('CreateAgentReminder', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createAgentReminderTask(requestArgs: ICreateAgentReminderTask, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('CreateAgentReminderTask', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateAgentReminder(requestArgs: IUpdateAgentReminder, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('UpdateAgentReminder', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getInteractionHistory(requestArgs: IGetInteractionHistory, userObject: any) {
    try {
      // send request
      return this.call('GetInteractionHistory', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async externalConnectGenericCommand(requestArgs: IExternalConnectGenericCommand, userObject: any) {
    try {
      // send request
      return this.call('ExternalConnectGenericCommand', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadCallWorkCodes(byTeam: boolean, userObject: any) {
    try {
      // prepare request args
      const data = {
        // check if to get for the logged in agent team
        teamId: byTeam ? this.agentData.teamId : '',
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('LoadCallWorkCodes', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async setCallWorkCode(requestArgs: ICallWorkCode, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SetCallWorkCode', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async removeCallWorkCode(requestArgs: ICallWorkCode, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('RemoveCallWorkCode', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendAgentNotification(requestArgs: IAgentNotification, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendAgentNotification', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendCommandToAgent(requestArgs: IAgentCommand, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('SendCommandToAgent', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendIM(requestArgs: IInstantMessage, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId,
        agentName: this.agentData.agentName
      };
      // send request
      return this.call('SendIM', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async addEventToAgentSession(requestArgs: IAddEvent, userObject: any) {
    try {
      // send request
      return this.call('AddEventToAgentSession', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendGenericCTICommand(requestArgs: IGenericCTICommand, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendGenericCTICommand', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getMiniDashboard(requestArgs: IMiniDashboard, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('GetMiniDashboard', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getVIPData(requestArgs: IGetVIPData, userObject: any) {
    try {
      // send request
      return this.call('GetVIPData', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async removeFile(filePath: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        filePath,
        deviceId: this.agentData.deviceId,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('RemoveFile', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getDashboardColorCodes(userObject: any) {
    try {
      // send request
      return this.call('GetDashboardColorCodes', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getQueueTimeColorCodes(userObject: any) {
    try {
      // send request
      return this.call('GetQueueTimeColorCodes', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateMosDetails(requestArgs: IUpdateMosDetails, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('UpdateMosDetails', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async restRequest(requestArgs: IRestRequest, userObject: any) {
    try {
      // send request
      return this.call('RestRequest', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTextTemplateDepartments(userObject: any) {
    try {
      // send request
      return this.call('GetTextTemplateDepartments', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTextTemplateGroups(departmentId: string, userObject: any) {
    try {
      // prepare request args
      const data = { departmentId };
      // send request
      return this.call('GetTextTemplateGroups', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTextTemplates(groupId: string, userObject: any) {
    try {
      // prepare request args
      const data = { groupId };
      // send request
      return this.call('GetTextTemplates', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAllTextTemplates(userObject: any) {
    try {
      // send request
      return this.call('GetAllTextTemplates', new Object(), userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async insertTCISViolation(requestArgs: ITCISViolation, userObject: any) {
    try {
      // prepare request args
      const data = {
        EventName: requestArgs.eventName,
        AgentId: this.agentData.agentId,
        CamPicURL: requestArgs.camPicURL,
        ScreenShotURL: requestArgs.screenShotURL,
        ScreenVideo: requestArgs.screenVideo,
        AgentStatus: this.agentData.agentStatus,
        QRVideoURL: '',
        ActionSummary: requestArgs.actionSummary,
        Threshold: 0
      };
      // send request
      return this.call('InsertTCISViolation', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async saveVideoSnap(requestArgs: IVideoSnap, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('SaveVideoSnap', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async saveAgentSnap(requestArgs: IAgentSnap, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('SaveAgentSnap', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async uploadFiles(requestArgs: IUploadFiles, userObject: any) {
    // prepare request args
    const data = {
      ...requestArgs,
      agentId: this.agentData.agentId
    };
    // send request
    return this.call('UploadFiles', data, userObject);
  }

  public async storeWebRTCCommStats(requestArgs: any[]) {
    try {
      // prepare request args
      const data = {
        stats: requestArgs
      };
      // send request
      return this.call('StoreWebRTCCommStats', data, null);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendAgentAVMessage(requestArgs: IAgentAVMessage, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId,
        agentName: this.agentData.agentName
      };
      // send request
      return this.call('SendAgentAVMessage', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendTransferNotification(requestArgs: ISendTransferNotification, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId,
        agentName: this.agentData.agentName
      };
      // send request
      return this.call('SendTransferNotification', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async cancelTransferNotification(requestArgs: ICancelTransferNotification, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('CancelTransferNotification', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async respondTransferNotification(requestArgs: IRespondTransferNotification, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId,
        agentName: this.agentData.agentName
      };
      // send request
      return this.call('RespondTransferNotification', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferInteraction(requestArgs: ITransferInteraction, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('TransferInteraction', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async saveInteractionComment(requestArgs: IInteractionComment, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SaveInteractionComment', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendAVControlMessage(requestArgs: IAVControlMessage, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendAVControlMessage', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async SendWhatsApp(requestArgs: ISendWhatsApp, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendWhatsApp', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTmacLoginJson(id: string, userObject: any) {
    try {
      // send request
      return this.call('GetTmacLoginJson', { id }, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getWidgetPreviewJson(id: string, userObject: any) {
    try {
      // send request
      return this.call('GetWidgetPreviewJson', { id }, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAgentActivity(requestArgs: IGetAgentActivity, userObject: any) {
    try {
      // send request
      return this.call('GetAgentActivity', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendAgentActivity(requestArgs: ISendAgentActivity, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('SendAgentActivity', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getInteractionTemplates(channel: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        agentId: this.agentData.agentId,
        channel
      };
      // send request
      return this.call('GetInteractionTemplates', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getInteractionActions(sessionId: string, userObject: any) {
    try {
      // send request
      return await this.call('GetInteractionActions', { sessionId }, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async respondToWqDacRequest(requestArgs: IRespondToWqDacRequest, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('RespondToWqDacRequest', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getInteractionData(requestArgs: IGetInteractionData, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs
      };
      // send request
      return this.call('GetInteractionData', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getConversationList(userObject: any) {
    try {
      // send request
      return this.call('GetConversationList', { agentId: this.agentData.agentId }, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendMessageToAgent(requestArgs: IsendMessageToAgent, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('SendMessageToAgent', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGroupInfo(groupId: string, userObject: any) {
    try {
      // send request
      return this.call('GetGroupInfo', { groupId }, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createGroup(requestArgs: ICreateGroup, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('CreateGroup', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateGroup(requestArgs: IUpdateGroup, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('UpdateGroup', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async setBroadcastMessageForTeam(requestArgs: ISendBroadcastArgs, userObject: any) {
    try {
      // send request
      return this.call('SetBroadcastMessageForTeam', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendNotification(requestArgs: ISendNotificationArgs, userObject: any) {
    try {
      // send request
      await this.call(
        'SendNotification',
        {
          ...requestArgs,
          fromId: this.agentData.agentId,
          fromName: this.agentData.agentName
        },
        userObject
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deflectToDigital(requestArgs: IDeflectToDigitalArgs, userObject: any) {
    try {
      // send request
      return this.call(
        'DeflectToDigital',
        {
          ...requestArgs,
          agentId: this.agentData.agentId
        },
        userObject
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async routeInteractionFromOtherRouter(requestArgs: IRouteInteractionFromOtherRouterArgs, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return await this.call('RouteInteractionFromOtherRouter', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getDataServerSignalRNatUrls(requestArgs: IGetDataServerSignalRNatUrlsArgs, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return await this.call('GetDataServerSignalRNatUrls', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  ////////// VOICE //////////

  public async makeCall(requestArgs: IMakeCall, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('MakeCall', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async makeCallWithUUI(requestArgs: IMakeCallWithUUI, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('MakeCallWithUUI', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async answerCall(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('AnswerCall', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async holdCall(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('HoldCall', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async unHoldCall(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('UnHoldCall', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async disconnectCall(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('DisconnectCall', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async disconnectCallByHandle(connectionHandle: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        connectionHandle,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('DisconnectCallByHandle', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async disconnectStation(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('DisconnectStation', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async conferenceCall(requestArgs: IConferenceTransferCall, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('ConferenceCall', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async conferenceCancel(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('ConferenceCancel', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async conferenceComplete(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('ConferenceComplete', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async conferenceBlind(requestArgs: IConferenceTransferCall, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('ConferenceBlind', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferCall(requestArgs: IConferenceTransferCall, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('TransferCall', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferCancel(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('TransferCancel', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferComplete(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('TransferComplete', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferBlind(requestArgs: IConferenceTransferCall, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('TransferBlind', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferToIVR(requestArgs: ITransferIVR, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('TransferToIVR', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendDTMF(requestArgs: ISendDTMF, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendDTMF', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async voiceSendMessage(requestArgs: IVoiceSendMessage, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('VoiceSendMessage', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  ////////// SMS //////////

  public async composeSMS(requestArgs: IComposeSMS, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('ComposeSMS', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendSMS(requestArgs: ISendSMS, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendSMS', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendInstantSMS(requestArgs: ISendInstantSMS, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return await this.call('SendInstantSMS', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  ////////// TEXT CHAT //////////

  public async notifyTextChatTyping(requestArgs: INotifyTyping, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('NotifyTextChatTyping', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendTextChat(requestArgs: ISendTextChat, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendTextChat', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendAppMessage(requestArgs: ISendAppMessage, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendAppMessage', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async registerTextChatCallback(requestArgs: IRegisterCallback, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('RegisterTextChatCallback', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async endTextChat(requestArgs: IEndTextChat, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('EndTextChat', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendTextChatTransferNotification(requestArgs: ISendTextChatTransferNotification, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId,
        agentName: this.agentData.agentName
      };
      // send request
      return this.call('SendTextChatTransferNotification', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async respondTextChatTransferNotification(requestArgs: IRespondTextChatTransferNotification, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId,
        agentName: this.agentData.agentName
      };
      // send request
      return this.call('RespondTextChatTransferNotification', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferTextChat(requestArgs: ITransferTextChat, userObject: any) {
    try {
      // send the endav message if the chatmode is not text and this is a transfer
      // do not send for conference as this method is used for conference also.
      if (requestArgs.chatMode && requestArgs.chatMode.toLowerCase() !== 'text' && requestArgs.conferenceType === 'transfer') {
        // send av control message
        this.sendAVControlMessage(
          {
            interactionId: requestArgs.interactionId,
            message: JSON.stringify({ type: 'endav', reason: 'Transfer to agent ' + requestArgs.toAgentId, param: requestArgs.chatMode }),
            type: 'endav'
          },
          null
        );
      }

      // TODO::
      // on an AV conference with another agent,
      // if either of the agent send a transfer to another agent this endav will be called, we need to ingore it.

      // check if agentId / deviceId / tmacServer is provided
      requestArgs.deviceId = requestArgs.deviceId || this.agentData.deviceId;
      requestArgs.agentId = requestArgs.agentId || this.agentData.agentId;
      requestArgs.tmacServer = requestArgs.tmacServer || this.agentData.tmacServer;

      // send request
      return this.call('TransferTextChat', requestArgs, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferTextChatToQueue(requestArgs: ITransferTextChatToQueue, userObject: any) {
    try {
      // send the endav message if the chatmode is not text
      if (requestArgs.chatMode && requestArgs.chatMode.toLowerCase() !== 'text') {
        // send av control message
        this.sendAVControlMessage(
          {
            interactionId: requestArgs.interactionId,
            message: JSON.stringify({ type: 'endav', reason: 'Transfer to queue', param: requestArgs.chatMode }),
            type: 'endav'
          },
          null
        );
      }

      // TODO::
      // on an AV conference with another agent,
      // if either of the agent send a transfer to another agent this endav will be called, we need to ingore it.

      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('TransferTextChatToQueue', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async freezeTextChatAutoResponse(interactionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        interactionId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('FreezeTextChatAutoResponse', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async changeTextChatConferenceType(requestArgs: IChangeTextChatConferenceType, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return await this.call('ChangeTextChatConferenceType', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async textChatConferenceToBot(requestArgs: ITextChatConferenceToBot, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return await this.call('TextChatConferenceToBot', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendActionMessage(requestArgs: ISendActionMessage, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return await this.call('SendActionMessage', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getChatInteractionHistory(requestArgs: IGetChatInteractionHistory, userObject: any) {
    try {
      // check if cif is provided then change groupId to all
      if (requestArgs.cif) {
        requestArgs.groupId = 'all';
      }
      // send request
      return await this.call('GetChatInteractionHistory', { ...requestArgs }, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateAsyncChatData(requestArgs: IUpdateAsyncChatDataArgs, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return await this.call('UpdateAsyncChatData', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async invokeAsyncChatDataApi(requestArgs: IInvokeAsyncChatDataApiArgs, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return await this.call('InvokeAsyncChatDataApi', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  ////////// FAX //////////

  public async getFaxAddressBook(faxLine: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        faxLine,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('GetFaxAddressBook', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFaxRecipients(adddressBookId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        adddressBookId,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('GetFaxRecipients', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFaxLineNumbers(userObject: any) {
    try {
      // prepare request args
      const data = {
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('GetFaxLineNumbers', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFaxTemplateNames(userObject: any) {
    try {
      // prepare request args
      const data = {
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('GetFaxTemplateNames', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFaxTemplate(requestArgs: IGetFaxTemplate, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('GetFaxTemplate', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendFax(requestArgs: ISendFax, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('SendFax', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async downloadFaxFile(requestArgs: IDownloadFaxFile, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('DownloadFaxFile', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateFaxStatus(requestArgs: IUpdateFaxStatus, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        deviceId: this.agentData.deviceId
      };
      // send request
      return this.call('UpdateFaxStatus', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  ////////// EMAIL //////////

  public async getMailboxes(type: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        agentId: type === 'agent' ? this.agentData.agentId : 'mailbox'
      };
      // send request
      return this.call('GetMailboxes', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFrequentEmailAddressList(userObject: any) {
    try {
      // prepare request args
      const data = {
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('GetFrequentEmailAddressList', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async composeNewEmail(mailBox: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        mailBox,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('ComposeNewEmail', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async sendEmail(requestArgs: ISendEmail, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('SendEmail', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async saveEmailDraft(requestArgs: ISaveEmail, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('SaveEmailDraft', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteEmailDraft(requestArgs: IDeleteEmail, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('DeleteEmailDraft', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async changeEmailStatus(requestArgs: IChangeEmailStatus, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('ChangeEmailStatus', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async pullEmailFromMakerQueue(requestArgs: IPullEmailFromMakerQueue, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('PullEmailFromMakerQueue', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async pullEmailFromMakerDraft(requestArgs: IPullEmailFromMakerDraft, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('PullEmailFromMakerDraft', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async pullEmailFromSentItems(requestArgs: IPullEmailFromSentItems, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('PullEmailFromSentItems', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async rejectEmail(requestArgs: IRejectEmail, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('RejectEmail', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferEmailToAgent(requestArgs: ITransferEmailToAgent, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('TransferEmailToAgent', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async transferEmailToSkill(requestArgs: ITransferEmailToSkill, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('TransferEmailToSkill', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async saveEmailAsEml(requestArgs: ISaveEmailAsEml, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('SaveEmailAsEml', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getInboxEmail(sessionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        sessionId,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('GetInboxEmail', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOutboxEmail(sessionId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        sessionId,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('GetOutboxEmail', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async maskInboxEmail(requestArgs: IMaskInboxEmail, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('MaskInboxEmail', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async closeBulkEmailsInQueue(routeIdList: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        routeIdList,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('CloseBulkEmailsInQueue', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteBulkEmailsInDraft(sessionIdList: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        sessionIdList,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('DeleteBulkEmailsInDraft', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async cloneEmail(requestArgs: ICloneEmail, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs,
        agentId: this.agentData.agentId
      };
      // send request
      return this.call('CloneEmail', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getEmailTemplateDepartments(byTeam: boolean, userObject: any) {
    try {
      // prepare request args
      let data = {};
      let method = 'GetEmailTemplateDepartments';

      // Check if to load by team
      // In TMAC SDK Proxy since we added new method to have backward compatibility
      // We need to call that method only if it's needed
      // so, that new TMAC-SDK can be compatible with old TMAC SDK Proxy without the team filter on
      if (byTeam) {
        method = 'GetEmailTemplateDepartmentsByTeam';
        data = {
          teamId: this.agentData.teamId
        };
      }

      // send request
      return this.call(method, data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getEmailTemplateGroups(departmentId: string, userObject: any) {
    try {
      // prepare request args
      const data = {
        departmentId
      };
      // send request
      return this.call('GetEmailTemplateGroups', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getEmailTemplates(requestArgs: IGetEmailTemplates, userObject: any) {
    try {
      // prepare request args
      const data = {
        ...requestArgs
      };
      // send request
      return this.call('GetEmailTemplates', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async markEmailAsSpam(requestArgs: IMarkEmailAsSpam, userObject: any) {
    try {
      // prepare request args
      const data = {
        agentId: this.agentData.agentId,
        ...requestArgs
      };
      // send request
      return this.call('MarkEmailAsSpam', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async replyBulkEmailsInQueue(requestArgs: IReplyBulkEmailsInQueue, userObject: any) {
    try {
      // prepare request args
      const data = {
        agentId: this.agentData.agentId,
        ...requestArgs
      };
      // send request
      return this.call('ReplyBulkEmailsInQueue', data, userObject);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  ////////// INTERNAL CODE //////////

  private async call(method: string, requestArgs: any, userObject: any, type?: string) {
    try {
      // check if config is set
      if (this.config === null) {
        return Promise.reject('Config is not set!');
      }

      // append the TMAC server name with the request args based on condition
      if (requestArgs.tmacServer === undefined && !this.ignoreServerIdList.includes(method)) {
        requestArgs.tmacServer = this.agentData?.tmacServer || '';
      }

      // ingore log for GetEvents
      if (this.config.logging.sdkMethods && !this.ignoreMethodLoggingList.includes(method)) {
        // log request
        Logger.info(`CallServer: [${method}] request`);
      }

      // request time
      const requestAt = Date.now();

      // return http request
      const result: IResponse = await this.httpRequest(method, requestArgs, userObject, 3, type, false);

      // ingore log for GetEvents
      if (this.config.logging.sdkMethods && !this.ignoreMethodLoggingList.includes(method)) {
        // log response
        Logger.info(`CallServer: [${method}] response received, timeTaken=${Date.now() - requestAt}`);
      }

      // check the proxy type
      switch (this.proxyType) {
        // for SOAP proxy type
        case ProxyType.SOAP:
          return {
            response: result.response.d,
            userObject: result.userObject
          };
        // for WebAPI proxy type
        case ProxyType.WEBAPI:
          return {
            response: result.response.Data,
            userObject: result.userObject
          };
        default:
          return result;
      }
    } catch (error) {
      // for GetEvent set connectivity status to 2
      if (method === 'GetEvent') {
        // set the connectivity status to 2
        this.connectivityStatus(2, this.getConnectedUrl());
      }
      return Promise.reject(error);
    }
  }

  public connectivityStatus(status: number, message: string, connectedUrl?: string) {
    // check if connected url is provided then change it
    if (connectedUrl) {
      this.connections.connectedProxyUrl = connectedUrl;
    }
    // emit the connectivity status event
    this.sdkInstance.events.emit('SDKConnectivityStatusEvent', {
      Status: status,
      EventMode: this.connections.eventMode,
      Message: message
    });
  }
}
