import { WrcCallTypes } from '../enums';
import { AVApiConfig, IAnswerOptions, IBlobEmitterOptions, IMediaConstrains, IOfferOptions, IRecordingOptions, IWebRTCConfig } from '../interfaces';
import { AVUtils } from './av-utils';

declare const WrsConfigurationInterface: any;
export class AVConfiguration {
  constructor(private callType: number, private apiConfig: AVApiConfig) {
    // check if the apiConfig is null
    if (!apiConfig) {
      apiConfig = AVUtils.DefautAVConfig();
    }

    const _interface = {};

    // get all the WrsConfigurationInterface methods
    WrsConfigurationInterface(_interface);

    // return interface function and override with this class
    return { ..._interface, ...this };
  }

  getWebRTCConfigurations = (): IWebRTCConfig => {
    return this.apiConfig.webRTCConfig;
  };

  getMediaConstraints = (): IMediaConstrains => {
    if (this.apiConfig.mediaConstraints && this.apiConfig.mediaConstraints.type === 'custom') {
      return { custom: true };
    } else {
      return {
        audio: true,
        video:
          this.callType !== WrcCallTypes.Audio && this.apiConfig.mediaConstraints.type?.toLocaleLowerCase() !== 'onewayvideo'
            ? this.apiConfig.mediaConstraints.video ?? false
            : false
      };
    }
  };

  getOfferOptions = (): IOfferOptions => {
    const offerOptions = this.apiConfig.offerOptions;
    // for audio calls if 'offerToReceiveVideo' is true, make it false
    if (this.callType === WrcCallTypes.Audio && offerOptions.offerToReceiveVideo) {
      offerOptions.offerToReceiveVideo = false;
    }
    return offerOptions;
  };

  getAnswerOptions = (): IAnswerOptions => {
    return this.apiConfig.answerOptions;
  };

  getConnectionBandwidthUpperLimit = (): number => {
    return this.apiConfig.connectionBandwidthUpperLimit;
  };

  getAudioTrackBandwidthUpperLimit = (): number => {
    return this.apiConfig.audioTrackBandwidthUpperLimit;
  };

  getVideoTrackBandwidthUpperLimit = (): number => {
    return this.apiConfig.videoTrackBandwidthUpperLimit;
  };

  enableCdcAutoSelection = (): boolean => {
    return this.apiConfig.enableCdcAutoSelection;
  };

  getAudioVideoConnectionMode = (): number => {
    return this.apiConfig.audioVideoConnectionMode;
  };

  tryToMergeSingleTrackStreamsFromDifferentSources = (): boolean => {
    return this.apiConfig.tryToMergeSingleTrackStreamsFromDifferentSources;
  };

  getRecordingOptions = (): IRecordingOptions => {
    return this.apiConfig.recordingOptions;
  };

  isAudioLevelCheckEnabled = (): boolean => {
    return this.apiConfig.audioLevelCheck.enabled;
  };

  getAudioLevelThreshold = (): number => {
    return this.apiConfig.audioLevelCheck.threshold;
  };

  isAudioMixerEnabled = (): boolean => {
    return this.apiConfig.audioMixerEnabled;
  };

  getBlobEmitterOptions = (): IBlobEmitterOptions => {
    return this.apiConfig.blobEmitterOptions;
  };
}
