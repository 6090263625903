import { EventEmitterClass } from '../dispatcher/event-emitter';
import { jQ } from '../libs';
import { Logger } from './logger';
export class SignalRWrapper {
  // to check connect is called
  #tryConnect: boolean;
  // connection protocol
  #protocol: string;
  // type of the connection where this signalr is used for
  #type: string;
  // force close flag
  #forceClose: boolean;
  // connection flag
  #connected: boolean;
  // connection url list
  #urls: string[];
  // store the postion of url in list connected to
  #connectUrlCount: number;
  // connection retry count
  #retryCount: number;
  // connection timeout
  #timeout: number;
  // signalr connection
  #con: any;

  // signalr hub proxy
  public hub: any;
  // try reconnecting flag
  public tryReconnecting: boolean;
  // event emitter for signalR
  public events: EventEmitterClass = new EventEmitterClass();
  // to save the connection data
  public connectionData: any;
  // connectionId
  public connectionId: string;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(urls: string[], protocol: string, type: string, queryString: any, hub: string, isLogging = false, timeout = 30) {
    try {
      // check if jquery is provided as well as signalr is initialised
      if (typeof jQ === 'undefined') {
        throw new Error('SignalRWrapper - jQuery was not found. Please ensure jQuery is referenced before the SignalR client JavaScript file');
      } else if (typeof jQ.hubConnection === 'undefined') {
        throw new Error('SignalRWrapper - signalR was not found. Please ensure SignalR client JavaScript file is referenced');
      }
      this.#tryConnect = false;
      this.#protocol = protocol ? protocol : 'webSockets';
      this.#type = type;
      this.#forceClose = false;
      this.#connected = false;
      this.#urls = urls;
      this.#connectUrlCount = 0;
      this.#retryCount = 2;
      this.#timeout = timeout * 1000;
      // create the signalr connection
      this.#con = jQ.hubConnection(urls[this.#connectUrlCount]);
      // signalr logging is required
      this.#con.logging = isLogging;
      // query string for the connection to pass to server during the connection
      this.#con.qs = queryString ? queryString : {};
      // create the signalr hub proxy
      this.hub = this.#con.createHubProxy(hub);
      // set the try reconnecting flag
      this.tryReconnecting = true;

      this.#con.starting(() => {
        Logger.info(`SignalRWrapper: [${this.#type}]: starting the connection, url=${this.#con.url}`, false);
        this.events.emit('SignalRStartingEvent', null);
      });

      this.#con.received((data: any) => {
        this.events.emit('SignalRReceivedEvent', data);
      });

      this.#con.connectionSlow(() => {
        Logger.info(`SignalRWrapper: [${this.#type}]: connection is slow, url=${this.#con.url}`, false);
        this.events.emit('SignalRConnectionSlowEvent', null);
      });

      this.#con.error((error: string) => {
        Logger.error(`Error in SignalRWrapper: [${this.#type}]`, error, false);
        this.events.emit('SignalRErrorEvent', error);
      });

      this.#con.reconnecting(() => {
        Logger.info(`SignalRWrapper: [${this.#type}]: reconnecting to the server, url=${this.#con.url}`, false);
        this.#connected = false;
        this.events.emit('SignalRReconnectingEvent', null);
      });

      this.#con.reconnected(() => {
        Logger.info(`SignalRWrapper: [${this.#type}]: reconnected to the server, url=${this.#con.url}`, false);
        this.#connected = true;
        this.events.emit('SignalRReconnectedEvent', null);
      });

      this.#con.disconnected((data: any) => {
        Logger.info(`SignalRWrapper: [${this.#type}]: disconnected, url=${this.#con.url}`, false);
        this.#connected = false;
        this.#tryConnect = false;
        // if closed forcefully then don't try to reconnect on disconnected
        if (!this.#forceClose) {
          // check if the connect url count is greater or equal to the length of provided urls
          // if so the reset the connect url count else increase the connect url count
          if (this.#connectUrlCount >= this.#urls.length - 1) {
            this.#connectUrlCount = 0;
          } else {
            this.#connectUrlCount++;
          }
          // change the signalr connection url
          this.#con.url = this.#urls[this.#connectUrlCount] + '/signalr';
          Logger.info(`SignalRWrapper: [${this.#type}]: try manual connecting after ${this.#retryCount} seconds to ${this.#con.url}`, false);
          // try to connect on an interval of 2,3,4,5... seconds
          setTimeout(() => {
            // if not foce closed then try connecting
            if (!this.#forceClose) {
              this.connect(true);
            } else {
              Logger.info(`SignalRWrapper: [${this.#type}]: could not connect to the server [${this.#con.url}], connection is force closed!`, false);
            }
          }, this.#retryCount * 1000); // re-start connection
          // if not connected in a cylce increase the retry count
          this.#retryCount++;
        } else {
          Logger.info(`SignalRWrapper: [${this.#type}]: manual retry is disabled!`, false);
        }
        // emit the event
        this.events.emit('SignalRDisconnectedEvent', data);
      });
      Logger.info('SignalRWrapper: instance created!', false);
    } catch (error) {
      Logger.error(`Error in SignalRWrapper.constructor`, error, false);
    }
  }

  /**
   * Method to connect to signalR server
   *
   * @param {Boolean} fallback [Optional] flag to notify reconnect or connect
   */
  public connect(fallback?: boolean): void {
    try {
      const connectUrl = this.#con.url;
      fallback = fallback ?? false;
      Logger.info(`SignalRWrapper: connect [${fallback}], url=${connectUrl}`, false);
      //if no connect try, then try to connect or if forceClose is true do not connect
      if (!this.#tryConnect && !this.#forceClose) {
        this.#tryConnect = true;

        // [MS: Sep 29, '21] commented fallback logic of adding to query string to avoid signalr security issue
        // if this fallback connection we need to set a query string
        // so that server will take action accordingly
        // if (fallback) {
        //   this.#con.qs = {
        //     ...this.#con.qs,
        //     fallback: true
        //   };
        // }

        this.#con.start({ transport: this.#protocol }).done(() => {
          Logger.debug(
            `SignalRWrapper: [${this.#type}]: connected, url=${connectUrl}, transport=${this.#con.transport.name}, connectionId=${this.#con.id}`,
            false
          );
          // set the connectionId
          this.connectionId = this.#con.id;
          //to specify the disconnect time for the signalr to try auto connect to the same url
          this.#con.disconnectTimeout = this.#timeout;
          // set the connected flag to true
          this.#connected = true;
          this.events.emit('SignalRConnectedEvent', { fallback: fallback });
          // set the try reconnecting flag to false
          this.tryReconnecting = false;
          // set retry count to 0
          this.#retryCount = 1;
        });
      } else {
        Logger.info(
          `SignalRWrapper: [${this.#type}]: could not connect to the server [${connectUrl}], #tryConnect=${this.#tryConnect}, forceClose=${
            this.#forceClose
          }`,
          false
        );
      }
    } catch (error) {
      Logger.error(`Error in SignalRWrapper.connect`, error, false);
    }
  }

  /**
   * To change the existing signalR server URL list
   * @param urls URLs to set
   */
  public setUrl(urls: string[]): void {
    // check if urls are provided
    if (!urls && urls.length === 0) {
      Logger.warn(`SignalRWrapper: [${this.#type}]: setUrl, urls are not provided in proper format`, false);
      return;
    }
    // add the urls to list
    this.#urls = urls;
    // change the signalr connection to new url
    this.#con = $.hubConnection(urls[0]);
  }

  /**
   * To set the query string for connection
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public setQueryString(queryString: any): string {
    if (!queryString) {
      Logger.warn(`SignalRWrapper: [${this.#type}]: setQueryString, queryString is not provided in proper format`, false);
      return;
    }
    // query string for the connection to pass to server during the connection
    this.#con.qs = queryString;
  }

  /**
   * To check if the connection is connected
   */
  public isConnected(): boolean {
    return this.#con.state === 1;
  }

  /**
   * To get the connection details
   */
  public getDetails(): {
    connectionUrl: string;
    protocol: string;
    isConnected: boolean;
  } {
    return {
      connectionUrl: this.#con.url,
      protocol: this.#protocol,
      isConnected: this.#connected
    };
  }

  /**
   * To get the connection transport
   */
  public getTransport(): string {
    // check if the connection is defined
    if (this.#con !== undefined) {
      return this.#con.transport.name;
    }
    return null;
  }

  /**
   * To close exisitng signalR connection with server
   * @param force force close is needed flag
   */
  public close(force = false): void {
    try {
      Logger.info(`SignalRWrapper: [${this.#type}]: stop the connection!`, false);
      this.#forceClose = force;
      this.#tryConnect = false;
      this.#con?.stop();
    } catch (error) {
      Logger.error(`Error in SignalRWrapper.close`, error, false);
    }
  }
}
