import { IClientLog, IConfig, ILogger, TLogs } from '../interfaces';
import { ConfigModel } from '../models';
import { HttpClient } from './http-client';
import { TUtility } from './utility';
export class Logger {
  private static config: IConfig;
  private static userInfo: string;
  private static httpClient: HttpClient;
  private static remoteLogRef: IClientLog[] = [];
  private static remoteRequest: boolean;

  /**
   * To set the Logger configs
   *
   * @param {IConfig} config Logging configs
   */
  public static setConfig(config: IConfig): void {
    // set the config
    this.config = { ...new ConfigModel(), ...config };
    // create an instance of http client
    this.httpClient = new HttpClient();
    // set the proxy urls
    this.httpClient.setUrls(this.config.proxy.urls);
  }

  /**
   * To set the user info for logging purpose
   *
   * @param {String} userInfo User infor mation for logging
   */
  public static setUserInfo(userInfo: string): void {
    // set the agent data
    this.userInfo = userInfo;
  }

  /**
   * To check config and validate message for logging
   *
   * @param {String} message Message to validate
   * @param {TLogs} type Type of log
   * @returns
   */
  private static allowLog(message: string, type: TLogs): boolean {
    try {
      // check if logging is enabled
      if (!message || !this.config?.logging?.enabled || !this.config?.logging?.level[type]) {
        return false;
      }
    } catch (error) {
      console.error('Error in Logger.checkConfig', error);
    }
    return true;
  }

  /**
   *To register to logger with prefix
   *
   * @param {String} prefix
   * @returns {ILogger}
   */
  public static register(prefix: string): ILogger {
    return {
      info: (message: string, remote = true) => {
        this.log(prefix, message, 'info', remote);
      },
      trace: (message: string) => {
        this.log(prefix, message, 'trace', false);
      },
      debug: (message: string, remote = true) => {
        this.log(prefix, message, 'debug', remote);
      },
      warn: (message: string, remote = true) => {
        this.log(prefix, message, 'warn', remote);
      },
      error: (message: string, error: any | Error, remote = true) => {
        this.log(prefix, `${message}: ${error}`, 'error', remote);
      }
    };
  }

  /**
   * To console and remote log the info message
   *
   * @param {string} message Log message
   * @param {Boolean} remote Remote logging is needed flag, default=true
   */
  public static info(message: string, remote = true): void {
    this.log('TMACSDK', message, 'info', remote);
  }

  /**
   * To console and remote log the trace message
   *
   * @param {string} message Log message
   */
  public static trace(message: string): void {
    this.log('TMACSDK', message, 'trace', false);
  }

  /**
   * To console and remote log the debug message
   *
   * @param {string} message Log message
   * @param {Boolean} remote Remote logging is needed flag, default=true
   *
   */
  public static debug(message: string, remote = true): void {
    this.log('TMACSDK', message, 'debug', remote);
  }

  /**
   * To console and remote log the warn message
   *
   * @param {String} message Log message
   * @param {Boolean} remote Remote logging is needed flag, default=true
   */
  public static warn(message: string, remote = true): void {
    this.log('TMACSDK', message, 'warn', remote);
  }

  /**
   * To console and remote log the error message
   *
   * @param {String} message Log message
   * @param {Error | Any } error Error message
   * @param {Boolean} remote Remote logging is needed flag, default=true
   */
  public static error(message: string, error: Error | any, remote = true): void {
    this.log('TMACSDK', `${message}: ${error}`, 'error', remote);
  }

  private static log(prefix: string, message: string, type: TLogs, remote: boolean) {
    // check if logging is enabled
    if (type !== 'trace' && !this.allowLog(message, type)) {
      return;
    }
    // append the time to message
    const logMessage = `[${TUtility.getDT()}]${this.userInfo ? ' [' + this.userInfo + '] ' : ' '}${prefix}: ${message}`;
    console[type](`[${type.toUpperCase()}] ${logMessage}`);
    if (remote) {
      this.remoteLogger(type, logMessage);
    }
  }

  /**
   * Remote logging all the client logs
   * @param {TLogs} type Type of log
   * @param {String} message Message to log
   */
  private static remoteLogger(type: TLogs | 'timeout', message: string): void {
    // check if remoteLogging is enabled
    if (!this.config || !this.config.logging.remote.enabled) {
      return;
    }

    // don't add timeout or empty logs
    if (type && type !== 'timeout') {
      // add logs to reference
      this.remoteLogRef.push({
        JsonData: JSON.stringify(message),
        LogType: type
      });
    }

    // buffer configured count logs and dump to server or in configure timeout if there is no rquest sent
    if (this.remoteRequest || (this.remoteLogRef.length < this.config.logging.remote.count && TUtility.getTTLData('logger') === 'logger')) {
      return;
    }

    // set the TTL
    TUtility.setTTLData('logger', 'logger', this.config.logging.remote.timeout * 1000);

    // to make sure timer log is logged on time
    setTimeout(() => {
      this.remoteLogger('timeout', '');
    }, this.config.logging.remote.timeout * 1000);

    // check if logs are there
    if (this.remoteLogRef.length > 0) {
      // assign the data to new variable
      const requestArgs = {
        logs: [...this.remoteLogRef]
      };

      const logRef = this.remoteLogRef;
      this.remoteLogRef = [];

      // set remote request flag
      this.remoteRequest = true;
      // send the request
      this.httpClient
        .httpRequest('LogClientLogs', requestArgs, null)
        .catch(() => {
          // if error add the log back to the ref
          this.remoteLogRef = [...logRef, ...this.remoteLogRef];
        })
        .finally(() => {
          // set remote request to false to allow next request
          this.remoteRequest = false;
        });
    }
  }
}
