

function VideoMessage({ content }) {
    if (content) {
        return <video className="message-file" controls >
            <source src={content.content_url} type={content.mimeType}></source>
        </video>
    }
    return ""

}
export default VideoMessage