const interactionUrl = (process.env || window.env).REACT_APP_INTERACTION_API_URL;
const proxyUrl = (process.env || window.env).REACT_APP_TMAC_PROXY_URL;

export const createInteraction = (interactionObj) => {

    return new Promise((resolve, reject) => {
        try {
            console.debug(`invoking Interaction Create method, ${JSON.stringify(interactionObj)}`);

            let url = `${interactionUrl}/api/v1/interaction/create`;
            console.log(`Calling Interaction API - url : ${url}`);        
            let bodyJSON = JSON.stringify(interactionObj);
    
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: bodyJSON
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
    
                    if (data) {
                        console.debug(`Interaction Created : ${data} - Data : ${JSON.stringify(interactionObj)}`);
                        if (data.StatusCode === 0) {
    
                            resolve(data.InteractionId);
                        } else {
                            resolve(data);
                        }
                    } else {
                        console.error(`Interaction Created : ${data} - Data : ${JSON.stringify(interactionObj)}`);
                        //break operation and return empty list
                        reject(new Error("Error occurred"));
                    }
                })
                .catch(ex => {
                    reject(ex);
                });
           
        } catch (error) {
            console.error('Interaction Creation fail',error);
        }  
    });
};

export const startConversation= async(item, channel, agentId,interactionObj,tmacServer)=>{

    try {


        let url = `${interactionUrl}/api/v1/interaction/create`;
        console.log(`Calling Interaction API - url : ${url}`);        
        let bodyJSON = JSON.stringify(interactionObj);

        let response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: bodyJSON
        });

        if(response.status !== 200 ){
            console.error('fail to create interaction');
            return false;
        }
        const response_json = await response.json();        
        const interactionId = response_json.InteractionId;
       

        console.debug(`Interaction Created Data : ${JSON.stringify(response_json)}`);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        //myHeaders.append("Accept-Encoding", "gzip, deflate, br");

        item.ucid = interactionId;
        const data = JSON.stringify(item);
        var urlencoded = new URLSearchParams();
        urlencoded.append("routerType", "aaad");
        urlencoded.append("agentId",agentId);
        urlencoded.append("channel", channel);
        urlencoded.append("subChannel", channel);
        urlencoded.append("item", data);
        urlencoded.append("tmacServer", tmacServer);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        const response_tmac = await fetch(`${proxyUrl}/RouteInteractionFromOtherRouter`, requestOptions);

        console.debug(`RouteInteractionFromOtherRouter done, invoking Interaction Create method, ${JSON.stringify(response)}`);
        if(response && response.status === 200){   
            const respons_xml = await response_tmac.text();
            console.log('response is',respons_xml );  
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(respons_xml,"text/xml");            
            console.log('response is',xmlDoc );               
            return parseInt(xmlDoc.firstChild.textContent) === 0;
        }        
        return false;                
                
    } catch (error) {
        console.error('startConversation fail',error);
        return false;
    }
}

