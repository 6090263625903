export class InteractionUser {
    constructor(interaction) {

        this.interactions = [interaction.sessionId];
        this.lastInteractionTime = interaction.startDateTime;
        this.userId = interaction.customerId;
        this.lastInteraction = interaction.sessionId;
    }

    addInteraction = function (interaction) {
        this.interactions.push(interaction.sessionId);
        /* this.lastInteraction = interaction.sessionId;
        this.lastInteractionTime = interaction.startDateTime; */
    }

}