
import { useContext, useState } from 'react';
import { searchMessage } from '../../services/conversationApi';
import Loading from '../shared/loading';
import ChatSearchResults from './chatSearchResult';
import Search from '../shared/search';
import { UserContext } from '../userContext';

function ChatSearchPanel({ userInteractionIds, focusMessageEvent, toggleSearch }) {
    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [index, setIndex] = useState(0)
    const [loadMore, setLoadMore] = useState(false);

    const context = useContext(UserContext);
    const agentId = context && context.loginData && context.loginData.agentData ? context.loginData.agentData.agentId : "";

    async function SearchInChat(result) {
        setLoading(true)
        let messages = []
        let i = 0
        if (result && userInteractionIds.length > 0) {
            setSearchKey(result)
            const initailLimit = 10
            do {
                const latestInteraction = userInteractionIds[i]
                const msgs = await searchMessage(agentId, latestInteraction, result, initailLimit)
                if (msgs.length < initailLimit) {
                    ++i
                }
                messages = [...msgs, ...messages]
            } while (messages.length < initailLimit && i < userInteractionIds.length)

        }
        setSearchResults(messages);
        setLoading(false)
        setIndex(i)
    }

    async function loadMoreMessages() {
        if (searchResults.length > 0) {
            setLoadMore(true)
            const limit = 10
            let messages = [...searchResults]
            let i = index
            while (messages.length < (searchResults.length + limit) && i < userInteractionIds.length) {
                const latestInteraction = userInteractionIds[i]
                const msgs = await searchMessage(agentId, latestInteraction, searchKey, limit)
                if (msgs.length < limit) {
                    ++i
                }
                messages = [...msgs, ...messages]
            }

            setSearchResults(messages)
            setLoadMore(false)
            setIndex(i)

        }

    }

    return (
        <div className="chatsidepreview  position_relative">
            <Search className="height_10" onEnter={SearchInChat} />
            {loading ? <Loading /> :
                <ChatSearchResults
                    results={searchResults}
                    gotoMessage={focusMessageEvent}
                    closeTab={() => toggleSearch(false)}
                    onScroll={loadMoreMessages}
                    loadMore={loadMore}
                />}
        </div>
    );
}

export default ChatSearchPanel;