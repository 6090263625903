import { HttpRequestInfo, IResponseData } from '../interfaces';
import { Logger } from './logger';

export class HttpClient {
  #urls: string[];
  #urlIndex: number;
  #attempts: number;
  #requestRetry: any[];
  #maxTryRef: string[];
  #connectedUrl: string;
  #xhrTimeout: number;

  constructor() {
    this.#urls = [];
    this.#urlIndex = 0;
    this.#attempts = 0;
    this.#requestRetry = [];
    this.#maxTryRef = [];
    this.#connectedUrl = '';
    this.#xhrTimeout = 30000;
  }

  /**
   * Private method to add request retry reference
   * @param urlPath url path for the request
   */
  #getRequestRetryRef = (urlPath: string): number => {
    // get item from reference
    const getItem = this.#requestRetry.filter((i: any) => i.urlPath === urlPath);
    // if found increment the counter
    if (getItem.length > 0) {
      return ++getItem[0].retry;
    }
    // if not found add new reference
    else {
      this.#requestRetry.push({ urlPath, retry: 1 });
      return 1;
    }
  };

  /**
   * Private method to remove request retry reference
   * @param urlPath url path for the request
   */
  #removeRequestRetryRef = (urlPath: string): void => {
    if (this.#requestRetry.filter((i: any) => i.urlPath === urlPath).length > 0) {
      this.#requestRetry = this.#requestRetry.filter((i: any) => i.urlPath !== urlPath);
    }
  };

  /**
   * Private method to handle async timeout function
   * @param ms milliseconds
   */
  #timeout = (ms: number): Promise<any> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  /**
   * To set the proxy URLs
   * @param urls URLs to set
   */
  public setUrls(urls: string[]): void {
    // set the urls
    this.#urls = urls;
  }

  /**
   * To set proxy request timeout
   * @param timeout timeout to set
   */
  public setTimeout(timeout: number): void {
    // set the timeout
    this.#xhrTimeout = timeout;
  }

  /**
   * Method implements XHR request call to server
   * @param urlPath method to invoke in server
   * @param requestArgs request arguments
   * @param userObject user object from request to response
   * @param retry retry a failed request
   * @param method XHR request type
   * @param log to log the request and response
   */
  public async httpRequest(urlPath: string, requestArgs: any = {}, userObject: any = null, retry = 0, method = 'POST', log = false): Promise<any> {
    try {
      // check if the url is set
      if (this.#urls.length === 0) {
        return Promise.reject('HttpRequest: URLs are not available, please set the URLs using [setUrls] method');
      }
      return new Promise((resolve, reject) => {
        // create an instance of XMLHttpRequest
        const xhr = new XMLHttpRequest();
        // selected url
        const url = this.#urls[this.#urlIndex];
        // prepare the url
        const tryUrl = url.replace(/\/?$/, '/') + urlPath;
        // prepare the request arguments
        const params = requestArgs !== null ? JSON.stringify(requestArgs) : null;
        // open XHR
        xhr.open(method, tryUrl, true);
        // set the credential to false to CORS
        xhr.withCredentials = false;
        // set the headers
        xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
        // time in milliseconds
        xhr.timeout = this.#xhrTimeout;
        // XMLHttpRequest timed out
        xhr.ontimeout = () => {
          // remove any request retry ref if found for the current url path
          this.#removeRequestRetryRef(urlPath);
          Logger.error('Error in httpRequest', `The request for [${tryUrl}] is timed out`, false);
          reject(`The request for [${tryUrl}] is timed out`);
        };
        // listen to on load event
        xhr.onload = async () => {
          // remove any request retry ref if found for the current url path
          this.#removeRequestRetryRef(urlPath);
          // log the response if needed
          if (log) {
            Logger.debug(
              `HttpRequest: [${urlPath}] response received, readyState=${xhr.readyState}, status=${xhr.status}, responseText=${xhr.responseText.length}`,
              false
            );
          }
          // success status
          if (xhr.readyState === 4) {
            // It's done, what happened?
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
              // check the response header
              if (xhr.getResponseHeader('content-type') !== 'text/html') {
                try {
                  // save the connected url
                  this.#connectedUrl = this.#urls[this.#urlIndex];
                  // reset the max retries on success for a urlPath
                  if (this.#maxTryRef.indexOf(urlPath) >= 0) {
                    this.#maxTryRef = this.#maxTryRef.filter((m) => {
                      return m !== urlPath;
                    });
                  }
                  // if retry and success
                  if (this.#attempts > 0) {
                    Logger.info('HttpClient: Connected to ' + this.#urls[this.#urlIndex] + ' successfully', false);
                    // set the attempts to 0
                    this.#attempts = 0;
                  }
                  // set the connectivity state to 1 for response
                  this.connectivityStatus(1, '', this.#connectedUrl);
                  // return the response with user object
                  resolve({
                    response: JSON.parse(xhr.responseText),
                    userObject
                  });
                } catch (error) {
                  Logger.error('Error in httpRequest', error, false);
                  return Promise.reject(`${error}`);
                }
              } else {
                // Something went wrong?
                Logger.error('Error in httpRequest', `Something went wrong during the transaction for [${tryUrl}], status=${xhr.status}`, false);
                reject(`Something went wrong during the transaction for [${tryUrl}], responseText=${xhr.responseText}`);
              }
            } else if (!(xhr.status >= 200 && xhr.status < 400)) {
              // max try variable
              let maxTry = 0;
              // check if retry is 0 then do not decrement
              if (retry > 0) {
                maxTry = retry - 1;
              }
              // check if the method retry exceeded
              if (
                this.#maxTryRef.filter((m) => {
                  return m === urlPath;
                }).length === maxTry
              ) {
                // out of retries
                // Logger.error('Error in httpRequest', `An error has occurred during the transaction for [${tryUrl}], status=${xhr.status}`, false);
                // reject(`An error has occurred during the transaction for [${tryUrl}], status=${xhr.status}`);

                // call error
                xhr.onerror(null);
                return;
              }
              // add the urlPath to the max try reference
              this.#maxTryRef.push(urlPath);
              // recurse if we still have retries
              resolve(this.httpRequest(urlPath, requestArgs, userObject, retry, method, log));
            } else {
              Logger.error('Error in httpRequest', `Unexpected response status - ${xhr.status}`, false);
              reject(`Unexpected response status - ${xhr.status}`);
            }
          }
        };
        // listen to on error event
        xhr.onerror = async () => {
          // [ReviewCodeLine, Feb 25, '21] commented to check if this logic is needed
          // check if retry is needed
          // if (retry === 0) {
          //     Logger.error('Error in httpRequest', `An error has occurred during the transaction for [${tryUrl}], status=${xhr.status}`, false);
          //     reject(`An error has occurred during the transaction for [${tryUrl}], status=${xhr.status}`);
          //     return;
          // }

          // check the connectivity status
          const state = navigator.onLine;
          Logger.debug(`HttpRequest: [${urlPath}] Internet connectivity check, status=${state}`, false);
          // get current url index
          const currentIndex = this.#urls.indexOf(url);
          // check url length to pick the url from the list
          // increase the index and try the next url
          // if the index reach the max number of urls configured then reset to 0 and select the first url again and try
          if (currentIndex >= this.#urls.length - 1) {
            this.#urlIndex = 0;
            Logger.error('Error in httpRequest', `An error has occurred during the transaction for [${tryUrl}], status=${xhr.status}`, false);
            reject(`An error has occurred during the transaction for [${tryUrl}], status=${xhr.status}`);
            return;
          } else {
            this.#urlIndex = currentIndex + 1;
          }
          // increase the attempts flag counter to retry in an interval *1000 ms for the requested urlPath
          const retryInterval = this.#getRequestRetryRef(urlPath);
          Logger.debug(`HttpRequest: [${urlPath}] Retry request in ${retryInterval} seconds, url=[${this.#urls[this.#urlIndex]}]`, false);
          // wait for retry time
          await this.#timeout(retryInterval * 1000);
          // request again
          resolve(this.httpRequest(urlPath, requestArgs, userObject, retry, method, log));
        };
        // set the connectivity state to 0 for reqest
        this.connectivityStatus(0, '', this.#connectedUrl);
        // send the request
        xhr.send(params);
        // log the request if needed
        if (log) {
          Logger.debug(`HttpRequest - [${urlPath}] request`, false);
        }
      });
    } catch (error) {
      Logger.error('Error in httpRequest', error, false);
      return Promise.reject(`${error}`);
    }
  }

  /**
   * To get the connected url
   */
  public getConnectedUrl(): string {
    return this.#connectedUrl;
  }

  /**
   * Method to emit the connectivity status for heartbeat
   * @param status Type of status
   * @param connectedUrl Connected proxy url
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public connectivityStatus(status: number, message: string, connectedUrl?: string): void {
    //
  }

  /**
   * A static function to send a http request
   * @param requestInfo request object of type HttpRequestInfo
   */
  public static async sendRequest<T>(requestInfo: HttpRequestInfo): Promise<IResponseData<T>> {
    try {
      const url = requestInfo.urls[0];
      const method = requestInfo?.method || 'GET';
      const requestArgs = requestInfo?.requestArgs || new Object();
      const userObject = requestInfo?.userObject || null;
      let retry = requestInfo?.retry || 0;
      const timeout = requestInfo?.timeout || 60000;
      const log = requestInfo?.log || false;
      const headers = requestInfo?.header || [];
      const formData = requestInfo?.formData || null;
      const responseType = requestInfo.responseType || 'text';

      // check if the url is set
      if (!url) {
        return Promise.reject('HttpClient: sendRequest - URLs are not available, please set the URLs using [setUrls] method');
      }

      return new Promise((resolve, reject) => {
        // create an instance of XMLHttpRequest
        const xhr = new XMLHttpRequest();

        // prepare the request arguments
        const params = formData ? formData : requestArgs !== null ? JSON.stringify(requestArgs) : null;

        // open XHR
        xhr.open(method, url, true);

        // set the credential to false to CORS
        xhr.withCredentials = false;

        // set the headers if any
        if (Object.keys(headers).length > 0) {
          for (const [key, value] of Object.entries(headers)) {
            xhr.setRequestHeader(key, value as string);
          }
        }

        // set the response type if provided
        xhr.responseType = responseType;

        // time in milliseconds
        xhr.timeout = timeout;

        // XMLHttpRequest timed out
        xhr.ontimeout = () => {
          Logger.error('Error in sendRequest', `The request for [${url}] is timed out`, false);
          reject(`The request for [${url}] is timed out`);
        };

        // listen to on load event
        xhr.onload = async () => {
          // log the response if needed
          if (log) {
            Logger.debug(`HttpClient: [${url}] response received, readyState=${xhr.readyState}, status=${xhr.status}`, false);
          }
          // success status
          if (xhr.readyState === 4) {
            // It's done, what happened?
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
              // check the response header
              if (xhr.getResponseHeader('content-type') !== 'text/html') {
                try {
                  // return the response with user object
                  resolve({
                    response: xhr.response,
                    userObject
                  });
                } catch (error) {
                  Logger.error('Error in sendRequest', error, false);
                  return Promise.reject(`${error}`);
                }
              } else {
                // Something went wrong?
                Logger.error('Error in sendRequest', `Something went wrong during the transaction for [${url}], status=${xhr.status}`, false);

                reject({
                  url,
                  message: `Something went wrong during the transaction`,
                  ...xhr.response
                });
              }
            } else if (!(xhr.status >= 200 && xhr.status < 400)) {
              // check if retry is 0 then do not decrement
              if (retry > 0) {
                retry = --retry;
              }
              // check if the method retry exceeded
              if (retry === 0) {
                // remove the first url from request
                requestInfo.urls.shift();
                // check if any other url's are provided
                if (requestInfo.urls.length) {
                  // call error
                  xhr.onerror(null);
                  return;
                }

                // out of retries
                Logger.error('Error in sendRequest', `An error has occurred during the transaction for [${url}], status=${xhr.status}`, false);

                reject({
                  url,
                  message: `An error has occurred during the transaction`,
                  ...xhr.response
                });
                return;
              }
              // recurse if we still have retries
              resolve(this.sendRequest({ ...requestInfo, retry }));
            } else {
              Logger.error('Error in sendRequest', `Unexpected response status - ${xhr.status}`, false);

              reject({
                url,
                message: `Unexpected response status`,
                ...xhr.response
              });
              return;
            }
          }
        };

        // listen to on error event
        xhr.onerror = async (e) => {
          // check if manual error
          if (e === null) {
            // recurse if we still have retries
            resolve(this.sendRequest(requestInfo));
            return;
          }
          Logger.error('Error in sendRequest', `An error has occurred during the transaction for [${url}], status=${xhr.status}`, false);

          reject({
            url,
            message: `An error has occurred during the transaction`,
            response: xhr.response
          });

          return;
        };

        // send the request
        xhr.send(params);

        // log the request if needed
        if (log) {
          Logger.debug(`HttpClient: [${url}] request`, false);
        }
      });
    } catch (error) {
      Logger.error('Error in sendRequest', error, false);

      return Promise.reject({
        url: '',
        message: error,
        response: null
      });
    }
  }
}
