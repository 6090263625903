import { EventEmitterClass } from '../dispatcher/event-emitter';
import { WrcCallTypes, WrcCodes, WrsCallSignal, WrsScreenshareSignal } from '../enums';
import { AVApiConfig, AVEventTypes, ILocalStreamInfo, IRemoteStreamInfo, IRemoteVideoRef, IWrsUtils } from '../interfaces';
import { SDK } from '../sdk';
import { Logger } from '../utils';
import { AVConfiguration } from './av-configuration';
import { AVUtils } from './av-utils';

declare const WrsUtils: IWrsUtils;
declare const WrsConfigurationInterface: any;
declare const WrsChannelInterface: any;
declare const WrsStatsInterface: any;
declare const WrsPeerConnection: any;
declare const TfiWebRTCStats: any;
declare const navigator: Navigator | any;

export class AVChannel {
  #sdkInstance: SDK;
  #channel: any;
  #statsifs: any;
  #callType: number;
  #intent: string;
  #userId: string;
  #interactionId: string;
  #id: string;
  #sessionId: string;
  #tunnel: string;
  #statsInterval: number;
  #lastVoiceStreamId: string;
  #escalateTimeout: any;
  #screenshareStartTriggered: boolean;
  #screenshareConnectTriggered: boolean;
  #screenshareStream: MediaStream;
  #isScreensharePresenter: boolean;
  #startTime: Date;
  #mediaSummaryStats: any;
  #localStreamDetails: ILocalStreamInfo;
  #remoteStreamDetails: IRemoteStreamInfo[];
  #statsCollector: any;
  #mixer: any;
  #localVideo: string;
  #remoteVideo: IRemoteVideoRef;
  #lastDeviceChangeEventTime: number;
  #pc: any;

  events: EventEmitterClass;
  wrsUtils: IWrsUtils;
  configuration: AVConfiguration;

  constructor(
    sdkInstance: SDK,
    interactionId: string,
    userId: string,
    userName: string,
    sessionId: string,
    tunnel: string,
    private _apiConfig: AVApiConfig
  ) {
    // check if the web client API is referred in project
    if (WrsUtils === undefined || WrsConfigurationInterface === undefined || WrsChannelInterface === undefined || WrsStatsInterface === undefined) {
      throw new Error('AVChannel - Webclient API was not found. Please ensure Webclient is referenced');
    }

    // set the SDK instance
    this.#sdkInstance = sdkInstance;

    /**
     * PRIVATE VARIABLES
     */

    // peer connection object
    this.#pc = null;
    // private object for WrsChannelInterface Interace method implementation
    this.#channel = new Object();
    // private object for WrsStatsInterface Interace method implementation
    this.#statsifs = new Object();
    // call type ref
    this.#callType = undefined;
    // intent of the channel
    this.#intent = '';
    // user id for connection
    this.#userId = userId;
    // to store the interaction Id
    this.#interactionId = interactionId;
    // id of the current connection
    this.#id = `${interactionId}_${userId}_${userName}`;
    // session id of the call
    this.#sessionId = sessionId;
    // base channel media for the webrtc call
    this.#tunnel = tunnel;
    // stats interval
    this.#statsInterval = 1000;
    // last voice activity user
    this.#lastVoiceStreamId = '';
    // escalate timeout
    this.#escalateTimeout = null;
    // screenshare trigger flag
    this.#screenshareStartTriggered = false;
    // screenshare connect trigger flag
    this.#screenshareConnectTriggered = false;
    // screenshare stream ref
    this.#screenshareStream = null;
    // flag to check the screenshare presenter
    this.#isScreensharePresenter = false;
    // call start time 3
    this.#startTime = new Date();
    // media summary stats
    this.#mediaSummaryStats = {
      duration: 0,
      mos: {
        min: 0,
        max: 0
      },
      packetsLost: '',
      ASB: {
        min: 0,
        max: 0
      },
      jitter: {
        min: 0,
        max: 0
      },
      ABS: '',
      ABR: '',
      VBS: '',
      VBR: ''
    };
    // source stream details
    this.#localStreamDetails = null;
    // remote stream details
    this.#remoteStreamDetails = [];
    // stats collector object
    this.#statsCollector = null;
    // create a conference mixer
    this.#mixer = WrsUtils.createConferenceMixer();
    // remote video class object
    this.#remoteVideo = null;
    // local video container
    this.#localVideo = null;
    // set the device change event time
    this.#lastDeviceChangeEventTime = Date.now();

    /**
     * PUBLIC VARIABLES
     */

    // create a instance of event emitter
    this.events = new EventEmitterClass();
    // assign the wrs utils
    this.wrsUtils = WrsUtils;

    // check if the api config is provided
    if (!_apiConfig) {
      _apiConfig = AVUtils.DefautAVConfig();
    }

    // call the internal WrsChannelInterface constructor exposed in the API for channel
    WrsChannelInterface(this.#channel);

    // this method is used by internal implementation to get the id
    this.#channel.getId = () => {
      return this.#id;
    };

    // override the implementation send method
    this.#channel.send = (json: any) => {
      // check ack sending enabled
      if (json.type.includes('ack') && !this._apiConfig.sendACK) {
        return;
      }
      // add the intent to the messages
      json.intent = this.#intent;
      // based on tunnel add the sessionid
      if (tunnel === 'voice') {
        // add the from to the message
        json.from = this.#id;
        // append the session id
        json.session = sessionId;
      } else {
        // append session id
        json.sessionid = sessionId;
      }

      // send stats for call intent
      if (this.#intent === 'call') {
        // create a copy of object json
        const stat = Object.assign({}, json);
        // add stat source for reference
        stat._statSource = 's';
        // send av stats
        this.#sendAVStats('signalling', {
          message: stat,
          type: stat.type
        });
      }

      // to send av messages
      this.sendMessage(json);
    };

    // call the internal WrsStatsInterface constructor exposed in the API for stats
    WrsStatsInterface(this.#statsifs);

    // this method is used by internal implementation to get stats interval
    this.#statsifs.getStatsInterval = () => {
      return this.#statsInterval;
    };

    // override the onStats method implemented internally to send the WebRTC stats
    this.#statsifs.onStats = (stats: any) => {
      // check if statsCollector is null, then return
      if (!this.#statsCollector) {
        this.#onError(1234, 'TfiStatsCollector: statsCollector is not available');
        return;
      }
      // call the collector and push the stats
      this.#statsCollector.pushStats(stats);
    };

    // set the stats collector
    this.#setStatsCollector();

    // device check
    this.#deviceCheck();
  }

  // to create peer connection
  #createPc = (param: number): void => {
    // clear the existing pc, if in error/dispose state
    if (this.#pc !== null && this.#pc._state() < 0) {
      this.#pc = null;
    }

    // check if peer connection is null
    if (this.#pc === null) {
      this.#onTrace('Creating peer connection');
      // set the call type
      this.#setCallType(param);
      // get the av configuration
      this.configuration = new AVConfiguration(this.#callType, this._apiConfig);
      // [private method] get the peer connection
      this.#pc = new WrsPeerConnection(this.#channel, this.configuration, this.#intent, this.#statsifs);
    } else {
      this.#onTrace('Peer connection is already created, state=' + this.#pc._state());
      return;
    }

    // pc on connect
    this.#pc.onConnect = () => {
      // emit the onAVStats event
      this.#emitEvent('onAVStats', 'connected');
      // set the start time
      this.#startTime = new Date();
      // clear escalate timeout
      clearTimeout(this.#escalateTimeout);
      // emit the onConnected event
      this.#emitEvent('onConnected', { callType: this.#callType });
    };

    // pc on disconnect
    this.#pc.onDisconnect = () => {
      // emit the onAVStats event
      this.#emitEvent('onAVStats', 'disconnected');
      // if video available then clear the the src video
      if (this.#localVideo && document.getElementById(this.#localVideo)) {
        const element = document.getElementById(this.#localVideo) as HTMLMediaElement;
        element.srcObject = null;
      }
      // clear all the remote videos
      if (this.#remoteVideo && typeof this.#remoteVideo.clear === 'function') {
        this.#remoteVideo.clear();
      }
      // clear escalate timeout
      clearTimeout(this.#escalateTimeout);
      // send stats
      this.#sendAVStats('mediasummary', null);
      // emit the onDisconnected event
      this.#emitEvent('onDisconnected');
    };

    // pc on connect
    this.#pc.onReconnecting = () => {
      // emit the onAVStats event
      this.#emitEvent('onAVStats', 'reconnecting');
      // emit the onReconnecting event
      this.#emitEvent('onReconnecting');
    };

    // pc on connect
    this.#pc.onReconnected = () => {
      // emit the onAVStats event
      this.#emitEvent('onAVStats', 'connected');
      // emit the onReconnected event
      this.#emitEvent('onReconnected');
    };

    // pc on connect
    this.#pc.onIceStateChanged = (status: string) => {
      // send stats
      this.#sendAVStats('signalling', {
        message: status,
        type: 'icestatus'
      });
    };

    // pc on onHoldUnhold
    this.#pc.onHoldUnhold = (hold: boolean) => {
      // emit the onHoldUnhold event
      this.#emitEvent('onHoldUnhold', hold);
    };

    // pc on acquire video [source video]
    this.#pc.onAcquireVideo = (streams: MediaStream[]) => {
      // add to the source stream object
      this.#localStreamDetails = {
        user: this.#userId,
        streams: streams
      };
      // for video call only
      if (this.#callType === WrcCallTypes.Video && this.#localVideo) {
        // add the source video after 2 seconds to make sure the video tag is created
        setTimeout(
          (x) => {
            if (document.getElementById(this.#localVideo)) {
              const element = document.getElementById(this.#localVideo) as HTMLMediaElement;
              element.srcObject = x[0];
            }
          },
          2000,
          streams
        );
      }
      // emit the onSourceVideoAdded event
      this.#emitEvent('onSourceVideoAdded', streams);
    };

    // pc on remote video added
    this.#pc.onAddVideo = (streams: MediaStream[], streamInfo: any) => {
      // process the streams
      streams.forEach((stream: MediaStream) => {
        // let hasAudio = false;
        let hasVideo = false;
        const hasStreamInfo = Object.keys(streamInfo).length !== 0;
        const userInfo = (hasStreamInfo && streamInfo[stream.id].owner.split('_')) || [];
        const owner = (hasStreamInfo && streamInfo[stream.id].owner) || '';
        let id = '';
        let user = '';
        let info = {
          id: '',
          user: '',
          type: ''
        };

        // check if unknown
        if (owner === '') {
          id = stream.id;
          user = 'unknown';
        }
        // check if customer
        else if (owner === '0' || owner === 'customer') {
          id = '0';
          user = 'customer';
        }
        // check if user name
        else if (userInfo.length > 2) {
          id = userInfo[1];
          user = userInfo[2];
        }
        // check if user id
        else if (userInfo.length === 2) {
          id = userInfo[1];
          user = userInfo[1];
        }
        // check default
        else {
          id = userInfo[0];
          user = userInfo[0];
        }

        // set the user id and user to info
        info = { id: id, user: user, type: '' };

        //This processing needs to be done only for screenshare streams since there are seperate events for screenshare connect and disconnect
        if (hasStreamInfo && streamInfo[stream.id].type !== undefined && streamInfo[stream.id].type === 'screenshare') {
          // screenshareStream_ will already be set if the user is a Presenter,
          // only if the user is on the receiving end of the screenshare, then we save the stream here
          if (!this.#isScreensharePresenter) {
            this.#screenshareStream = stream;
          }

          // set the type
          info.type = 'screenshare';

          // set the remote stream info
          this.#setStreamInfo(stream, id, 'screenshare');

          // if this is not a one-one screenshare and the screenshare stream is being received after the audio/video call is connected
          if (!this.#screenshareConnectTriggered) {
            this.#screenshareConnectTriggered = true;
            // check if presenter
            if (this.#isScreensharePresenter) {
              // emit the onScreenshareStarted event
              this.#emitEvent('onScreenshareStarted');
            } else {
              // trigger add video if remoteVideo assigned
              if (this.#remoteVideo && typeof this.#remoteVideo.addVideo === 'function') {
                this.#remoteVideo.addVideo(stream, info);
              }
              // emit the onScreenshareConnected event
              this.#emitEvent('onScreenshareConnected', { stream, streamInfo: info });
            }
          }

          // check tracks
          stream.getTracks().forEach((track) => {
            // on ended event
            track.onended = () => {
              // check if the stream is there, then remove the stream info ref
              if (!this.#removeStreamInfo(stream.id)) {
                // if the stream is not there in reference then do not process, return
                return;
              }
              // remove the video from window
              if (this.#remoteVideo && typeof this.#remoteVideo.removeVideo === 'function') {
                this.#remoteVideo.removeVideo(stream);
              }
              // emit the onRemoteStreamEnded event
              this.#emitEvent('onRemoteStreamEnded', {
                stream,
                streamInfo: {
                  id: id,
                  user: user,
                  type: 'screenshare'
                }
              });
            };
          });
          return;
        } else {
          // check tracks
          stream.getTracks().forEach((track) => {
            if (track.kind == 'audio') {
              // hasAudio = true;
            } else if (track.kind == 'video') {
              hasVideo = true;
            }
            // on ended event
            track.onended = () => {
              // check if the stream is there, then remove the stream info ref
              if (!this.#removeStreamInfo(stream.id)) {
                // if the stream is not there in reference then do not process, return
                return;
              }
              // remove the video from window
              if (this.#remoteVideo && typeof this.#remoteVideo.removeVideo === 'function') {
                this.#remoteVideo.removeVideo(stream);
              }
              // emit the onRemoteStreamEnded event
              this.#emitEvent('onRemoteStreamEnded', {
                stream,
                streamInfo: {
                  id,
                  user,
                  type
                }
              });
            };
          });

          // get the type and set to info
          const type = (info.type = hasVideo ? 'video' : 'audio');

          // NOTE: to prevent double 'added' events for streams that have audio and video tracks
          if (this.#getStreamRef(stream.id).length > 0) {
            return;
          }

          // set the remote stream info
          this.#setStreamInfo(stream, id, type);

          // trigger add video if remoteVideo assigned
          if (this.#remoteVideo && typeof this.#remoteVideo.addVideo === 'function') {
            this.#remoteVideo.addVideo(stream, info);
          }

          // emit the onRemoteVideoAdded event
          this.#emitEvent('onRemoteVideoAdded', { stream, streamInfo: info });
        }
      });
    };

    // peer connection on call request
    this.#pc.onCallRequest = () => {
      // emit the onCallRequest event
      this.#emitEvent('onCallRequest');
    };

    // peer connection on stream status change
    this.#pc.onStreamStatusChange = (stream: MediaStream, status: string) => {
      // emit the onStreamStatusChanged event
      this.#emitEvent('onStreamStatusChanged', { stream, status });
    };

    // peer connection onModifyStreams
    this.#pc.onModifyStreams = (streams: MediaStream[], arg: (streams: MediaStream[]) => void) => {
      // check if the arg is a function callback
      if (arg != null && typeof arg === 'function') {
        return arg(streams);
      }
      // expose to user to modify the streams and return
      return this.onModifyStreams(streams);
    };

    // peer on acquire user media
    this.#pc.onAcquireUserMedia = (mediaConstraints: MediaStreamConstraints) => {
      // expose to user to add the streams and return
      return this.onAcquireUserMedia(mediaConstraints);
    };

    // peer connection onVoiceActivity
    this.#pc.onVoiceActivity = (streamId: string, level: number) => {
      if ((streamId === '' && this.#lastVoiceStreamId !== '') || level < 40) {
        this.#lastVoiceStreamId = '';
        // emit the onVoiceActivity event
        this.#emitEvent('onVoiceActivity', { streamId: '', level: 0 });
      }
      //expose to the instance to override from the initiator
      else if (this.#lastVoiceStreamId !== streamId) {
        this.#lastVoiceStreamId = streamId;
        // emit the onVoiceActivity event
        this.#emitEvent('onVoiceActivity', { streamId, level });
      }
    };

    // peer connection onBlobEmitted
    this.#pc.onBlobEmitted = (blob: Blob, streamId: string, type: string, isLocal: boolean, owner: string) => {
      // emit the onBlobEmitted event
      this.#emitEvent('onBlobEmitted', { blob, streamId, type, isLocal, owner });
    };

    // peer connection on trace
    this.#pc.onTrace = (msg: string) => {
      // expose to the instance to override from the initiator
      this.#onTrace(msg);
    };

    // peer connection on error
    this.#pc.onError = (code: number, msg: string) => {
      // clear escalate timeout on error
      clearTimeout(this.#escalateTimeout);
      // send av stats for error having codes
      if (code) {
        // save stats in db
        this.#sendAVStats('signalling', {
          message: {
            error: msg,
            errorCode: code
          },
          type: 'error'
        });
      }
      // expose to the instance to override from the initiator
      this.#onError(code, msg);
    };
  };

  // to send AV stats
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  #sendAVStats = (type: string, data: any): void => {
    let sendValue = '';
    if (type === 'config') {
      //  If media not ready, 0 else, 1
      if (data.mediaReady) sendValue += '1' + ',';
      else sendValue += '0' + ',';
      this._apiConfig.webRTCConfig.iceServers.forEach((item: any) => {
        sendValue += item.urls + '|';
      });
      sendValue = sendValue.slice(0, sendValue.length - 1);
    } else if (type === 'signalling') {
      const messageType = data.type;
      sendValue += messageType + ':';
      if (messageType === WrsCallSignal.REQUEST) {
        // send data as 'requestav:0/1' (1-audio, 2-video, 3-screenshare call by customer)
        switch (data.message.param) {
          case 'audio':
            sendValue += '1' + ':' + data.message._statSource;
            break;
          case 'video':
            sendValue += '2' + ':' + data.message._statSource;
            break;
          case 'screenshare':
            sendValue += '3' + ':' + data.message._statSource;
            break;
        }
        // emit the onAVStats event
        this.#emitEvent('onAVStats', data.message.param + '-' + (data.message._statSource === 's' ? 'requested' : 'received'));
      } else if (messageType === WrsCallSignal.ACK) {
        sendValue += data.message.originalType + ':' + data.message._statSource;
        switch (data.message.originalType) {
          case WrsCallSignal.REQUEST:
            // emit the onAVStats event
            this.#emitEvent('onAVStats', data.message._statSource === 's' ? 'incoming' : 'ringing');
            break;
        }
      } else if (messageType === WrsCallSignal.REQUEST_ACK) {
        sendValue = 'ackav:requestav:' + data.message._statSource;
        // emit the onAVStats event
        this.#emitEvent('onAVStats', data.message._statSource === 's' ? 'incoming' : 'ringing');
      } else if (messageType === WrsCallSignal.STATUS) {
        // send data as 'avtstatus:0/1' (0-rejected, 1-acepted by customer)
        if (data.message.param === false || data.message.param === 'false') {
          sendValue += '0' + ':' + data.message._statSource;
          // emit the onAVStats event
          this.#emitEvent('onAVStats', data.message._statSource === 's' ? 'rejecting' : 'rejected');
        } else {
          sendValue += '1' + ':' + data.message._statSource;
          // emit the onAVStats event
          this.#emitEvent('onAVStats', data.message.param + '-' + (data.message._statSource === 's' ? 'accepting' : 'accepted'));
        }
      } else if (messageType === 'icestatus') {
        //  new: 1, checking: 2, connected: 3, completed: 4, failed: 5, disconnect: 6, closed: 7
        switch (data.message) {
          case 'new':
            sendValue += '1';
            break;
          case 'checking':
            sendValue += '2';
            break;
          case 'connected':
            sendValue += '3';
            break;
          case 'completed':
            sendValue += '4';
            break;
          case 'failed':
            sendValue += '5';
            break;
          case 'disconnect':
            sendValue += '6';
            break;
          case 'closed':
            sendValue += '7';
            break;
        }
        // emit the onAVStats event
        this.#emitEvent('onAVStats', data.message);
      } else if (messageType === 'error') {
        sendValue += data.message.errorCode;
        // emit the onAVStats event
        this.#emitEvent('onAVStats', 'error');
      } else {
        if (messageType === 'offer') {
          // emit the onAVStats event
          this.#emitEvent('onAVStats', data.message._statSource === 's' ? 'offering' : 'offer-received');
        } else if (messageType === 'answer') {
          // emit the onAVStats event
          this.#emitEvent('onAVStats', data.message._statSource === 's' ? 'answering' : 'answer-received');
        } else if (messageType === 'candidates') {
          // emit the onAVStats event
          this.#emitEvent('onAVStats', 'media connecting');
        }
        // webrtc messages, check if statSource available, ignore the rest
        if (data.message._statSource) {
          sendValue += data.message._statSource;
        }
      }
    } else if (type === 'mediasummary') {
      // calculate av call duration
      const duration = (new Date().getTime() - this.#startTime.getTime()) / 1000;
      sendValue = duration.toString();
      sendValue += ',' + this.#mediaSummaryStats.mos.min + '|' + this.#mediaSummaryStats.mos.max;
      sendValue += ',' + this.#mediaSummaryStats.audioPacketsLost;
      sendValue += ',' + this.#mediaSummaryStats.videoPacketsLost;
      sendValue += ',' + this.#mediaSummaryStats.ASB.min + '|' + this.#mediaSummaryStats.ASB.max;
      sendValue += ',' + this.#mediaSummaryStats.jitter.min + '|' + this.#mediaSummaryStats.jitter.max;
      sendValue += ',' + this.#mediaSummaryStats.audioBytesSent;
      sendValue += ',' + this.#mediaSummaryStats.audioBytesReceived;
      sendValue += ',' + this.#mediaSummaryStats.videoBytesSent;
      sendValue += ',' + this.#mediaSummaryStats.videoBytesReceived;
    }

    const statsDate = new Date();

    // send AV stats to server
    this.#sdkInstance.storeWebRTCCommStats([
      {
        Data: sendValue,
        DataType: type,
        // [MS: Feb 28, '22] send the datetime in UTC format
        // remove 'GMT' from string to make sure server does not convert to local time
        // when saving in to DB
        DateTime: statsDate.toUTCString().replace(' GMT', `.${statsDate.getMilliseconds().toString()}`),
        SessionId: this.#sessionId,
        Source: 'agent'
      }
    ]);
  };

  // to send message to Wrs channel
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  #send = (message: any): void => {
    this.#channel.send(message);
  };

  // screenshare reset
  #screenshareReset = (): void => {
    this.#screenshareStartTriggered = false;
    this.#screenshareConnectTriggered = false;
    this.#isScreensharePresenter = false;
    this.#screenshareStream = null;
  };

  // to handle modifying stream for screenshare
  #modifyStreamsForScreenShare = async (streams: MediaStream[]): Promise<any> => {
    const getDisplayMediaFn =
      navigator.mediaDevices.getDisplayMedia !== undefined
        ? navigator.mediaDevices.getDisplayMedia.bind(navigator.mediaDevices)
        : navigator.getDisplayMedia !== undefined
        ? navigator.getDisplayMedia.bind(navigator)
        : undefined;

    // check if the dispaly media is a function
    if (getDisplayMediaFn !== undefined) {
      return Promise.resolve(
        getDisplayMediaFn({ video: { frameRate: 5 }, audio: false })
          .then((screenshareStream: any) => {
            // set the screenshare triggered flag to false
            this.#screenshareStartTriggered = false;
            screenshareStream = WrsUtils.markStreamAsScreenshare(screenshareStream);
            screenshareStream.oninactive = () => {
              // check if the screenshare is connected
              if (this.#screenshareConnectTriggered) {
                //send endav to inform MSS screenshare has ended
                this.#send({
                  type: WrsScreenshareSignal.END,
                  reason: 'presenter ended screenshare',
                  errorCode: 'NORMAL_SCREENSHARE_END'
                });
                // emit the onScreenshareEnded event
                this.#emitEvent('onScreenshareEnded');
                // reset screenshare
                this.#screenshareReset();
              }
            };
            // set the screenshare reference
            this.#screenshareStream = screenshareStream;
            // trigger screenshare started event
            setTimeout(() => {
              this.#screenshareConnectTriggered = true;
              // emit the onScreenshareStarted event
              this.#emitEvent('onScreenshareStarted');
            }, 100);
            // push the screenshare stream to streams and return
            streams.push(screenshareStream);
            return streams;
          })
          .catch((error: any) => {
            Logger.error('AVChannel.getDisplayMedia', error);
            // set the screenshare triggered flag to false
            this.#screenshareStartTriggered = false;
            // at this point, MSS still thinks that a screenshare is going on in the a/v call, so we need to inform MSS that there is no screenshare happening
            this.#send({
              type: WrsScreenshareSignal.END,
              reason: 'Screenshare Cancelled ([' + error.code + '] ' + error.message + ' )',
              errorCode: 'SCREENSHARE_CANCELLED'
            });
            this.#onError(WrcCodes.ScrensharePermissionDenied, error.message);
            return Promise.reject({ message: '[' + error.name + '] ' + error.message });
          })
      );
    } else {
      // set the screenshare triggered flag to false
      this.#screenshareStartTriggered = false;
      // at this point, MSS still thinks that a screenshare is going on in the a/v call, so we need to inform MSS that there is no screenshare happening                                //send endav to inform MSS screenshare has ended
      this.#send({
        type: WrsScreenshareSignal.END,
        reason: 'Browser does not support getDisplayMedia',
        errorCode: 'SCREENSHARE_NOT_SUPPORTED'
      });
      this.#onError(1234, 'SystemError: Browser does not support getDisplayMedia');
      return Promise.reject({ message: 'Browser does not support getDisplayMedia' });
    }
  };

  // to modify stream from audio to video
  #modifyStreamToVideo = async (streams: MediaStream[]): Promise<any> => {
    return Promise.all(
      streams.map(async (oldStream) => {
        // check if the stream has video tracks
        if (oldStream.getVideoTracks().length) {
          // stop all the video tracks and capture new tracks
          oldStream.getVideoTracks().forEach((v) => {
            v.stop();
          });
        }
        // get the stream from user media
        const newStream = await this.wrsUtils.getUserMedia({ audio: false, video: true }, Logger.info);
        // get video tracks
        const videoTrack = newStream.getVideoTracks()[0];
        // check if track found
        if (videoTrack) {
          // assign the tracks to the stream
          oldStream.addTrack(videoTrack);
        }
        // return the stream
        return oldStream;
      })
    );
  };

  // to set stream info
  #setStreamInfo = (stream: MediaStream, user: string, type: string): void => {
    // add the stream info reference
    this.#remoteStreamDetails.push({
      user: user,
      type: type,
      stream: stream
    });
  };

  // get stream info ref
  #getStreamRef = (streamId: string): IRemoteStreamInfo[] => {
    // check if avaialable
    return this.#remoteStreamDetails.filter((r) => {
      return r.stream.id === streamId;
    });
  };

  // to remove stream info
  #removeStreamInfo = (streamId: string): boolean => {
    // if reference of the stream is not available return false
    if (
      this.#remoteStreamDetails.filter((r) => {
        return r.stream.id === streamId;
      }).length === 0
    ) {
      return false;
    }
    // remove the stream from reference
    this.#remoteStreamDetails = this.#remoteStreamDetails.filter((r) => {
      return r.stream.id !== streamId;
    });
    // return true
    return true;
  };

  // to set call types
  #setCallType = (callType: number): void => {
    this.#callType = callType;
    this.#intent = 'call';
  };

  // to set the stats collector
  #setStatsCollector = (): void => {
    try {
      // create an instance of TfiStatsCollector
      this.#statsCollector = new TfiWebRTCStats.TfiStatsCollector(null, null);

      // register to the stats
      this.#statsCollector.registerGlobals(false);

      // set the collector stats tag
      this.#statsCollector.setTagsProviderFn(() => {
        // create a map
        const map = new Map();
        // set the map variables
        map.set('Location', 'agent');
        map.set('AgentId', this.#userId);
        map.set('SessionId', Date);
        return Promise.resolve(map);
      });

      // set the Transport provider
      this.#statsCollector.setTransportFn(this.#sendStats);
    } catch (error) {
      Logger.error('AVChannel.setStatsCollector', `${error} - stats will not be available`);
    }
  };

  // to check device changes
  #deviceCheck = (): void => {
    try {
      window.navigator.mediaDevices.addEventListener('devicechange', (e: Event) => {
        const seconds = (Math.abs(Date.now() - this.#lastDeviceChangeEventTime) / 1000) % 60;
        Logger.info(`AVChannel.deviceCheck: devicechange event triggered. connected=${this.isConnected()}, (seconds > 0.5)=(${seconds} > 0.5)`);

        if (seconds > 0.5 === false) {
          // ignore the duplicate message
          Logger.info(`AVChannel.deviceCheck: devicechange event triggered. ingnoring duplicate message seconds=${seconds}`);
          return;
        }

        // emit event
        this.#emitEvent('onDeviceChange', e);

        // set last device change event time
        this.#lastDeviceChangeEventTime = Date.now();

        // check if the call is connected
        if (this.isConnected()) {
          if (this.#pc.userMedia_ !== null) {
            let reset = null;
            this.#pc.userMedia_.forEach((stream: MediaStream) => {
              stream.getTracks().forEach(function (track) {
                if (track.readyState === 'ended') {
                  reset = stream;
                }
              });
            });

            // check if not reset
            if (!reset) {
              navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
                try {
                  let audioInputDevice = null;
                  let videoInputDevice = null;
                  devices.some(function (device: MediaDeviceInfo) {
                    Logger.info(`AVChannel.deviceCheck: devicechange, kind=${device.kind}, label=${device.label}, deviceId=${device.deviceId}`);

                    if (device.kind === 'audioinput') {
                      if (audioInputDevice === null) {
                        audioInputDevice = device.deviceId;
                      }
                      if (device.deviceId === 'default') {
                        audioInputDevice = device.deviceId;
                      }
                    }

                    if (device.kind === 'videoinput') {
                      if (videoInputDevice === null) {
                        videoInputDevice = device.deviceId;
                      }
                      if (device.deviceId === 'default') {
                        videoInputDevice = device.deviceId;
                      }
                    }
                  });

                  // TODO:: set device
                  // this.#onRemoveVirtualBackgroundStreams(this.#callId);
                  // this.#setDevice(audioInputDevice, videoInputDevice);
                } catch (error) {
                  Logger.error('AVChannel.deviceCheck', `Unable to set device - ${error}`);
                }
              });
            }
          }
        }
      });
    } catch (error) {
      Logger.error('AVChannel.deviceCheck', error);
    }
  };

  #setDevice = async (audioDeviceId: string, videoDeviceId: string): Promise<boolean> => {
    // first we need to check if the audio/video input devices actually are passed in the parameters
    let doesAudioInputDeviceExist = false;
    let doesVideoInputDeviceExist = false;

    if (!audioDeviceId) {
      doesAudioInputDeviceExist = true;
    }

    if (!videoDeviceId) {
      doesVideoInputDeviceExist = true;
    }

    // if both audio and video device ids are passed as null then we return
    if (doesAudioInputDeviceExist === true && doesVideoInputDeviceExist === true) {
      Logger.error(`AVChannel.setDevice`, 'Audio and Video Device Id(s) are not passed in the parameters for SetInputDevice');
      return Promise.resolve(false);
    }

    // change media devices
    return window.navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        // capture the current status
        const captureStatus = devices.some((device) => {
          if (doesAudioInputDeviceExist === false) {
            if (device.deviceId === audioDeviceId && device.kind === 'audioinput') {
              doesAudioInputDeviceExist = true;
            }
          }

          if (doesVideoInputDeviceExist === false) {
            if (device.deviceId === videoDeviceId && device.kind === 'videoinput') {
              doesVideoInputDeviceExist = true;
            }
          }

          // if both audio & video devices are found, break the loop
          if (doesAudioInputDeviceExist === true && doesVideoInputDeviceExist === true) {
            return true;
          } else {
            return false;
          }
        });

        // if there is no ongoing a/v call, then we can directly change the deviceId in the mediaConstraints
        const audioSource = audioDeviceId && typeof audioDeviceId === 'string' ? { exact: audioDeviceId } : null;
        const videoSource = videoDeviceId && typeof videoDeviceId === 'string' ? { exact: videoDeviceId } : null;

        // save the original media constraints
        const originalMediaConstraints = { ...this._apiConfig.mediaConstraints };
        const newMediaConstrains = { ...originalMediaConstraints };

        // if the audio source is available, then we add them to the MediaConstraints (temporarily)
        if (audioSource) {
          const audioConstrains = newMediaConstrains.audio as MediaTrackConstraints;
          audioConstrains.deviceId = audioSource;
          newMediaConstrains.audio = audioConstrains;
        }

        // if the video source is available, then we add them to the MediaConstraints (temporarily)
        if (videoSource) {
          const videoConstrains = newMediaConstrains.video as MediaTrackConstraints;
          videoConstrains.deviceId = videoSource;
          newMediaConstrains.video = videoConstrains;
        }

        // set the new media constrains
        this._apiConfig.mediaConstraints = newMediaConstrains;

        // if there is no ongoing a/v call, as the Media Constraints were already set previously,
        // any call made next would be using the latest media constraints

        // if there is an ongoing a/v call, then we have to replace the tracks immediately
        if (this.isConnected()) {
          const localCaptures = this.#localStreamDetails.streams?.filter((f) => !Object.prototype.hasOwnProperty.call(f, '_wrsIsScreenshareStream'));

          // if the audio source is available, then we add them to the MediaConstraints (temporarily)
          if (audioSource) {
            // stop the audio track, on some Android devices the track will not get replaced, unless the existing track is stopped
            localCaptures?.forEach((audio) => {
              audio.getAudioTracks().forEach(function (track) {
                track.stop();
              });
            });
          }

          // if the video source is available, then we add them to the MediaConstraints (temporarily)
          if (videoSource) {
            // stop the video track, on some Android devices the track will not get replaced, unless the existing track is stopped
            localCaptures?.forEach((video) => {
              video.getVideoTracks().forEach(function (track) {
                track.stop();
              });
            });
          }

          // modify the streams
          this.modify(async (streams: MediaStream[]) => {
            // get the stream from user media
            return Promise.resolve(this.wrsUtils.getUserMedia(newMediaConstrains, Logger.info))
              .then((stream: MediaStream) => {
                const newStream = stream;
                // if audio device id doesnt need to be captured then we stop the audio tracks from the new stream
                if (!audioSource) {
                  newStream.getAudioTracks().forEach((track) => {
                    track.stop();
                    newStream.removeTrack(track);
                  });
                }

                // if video device id doesnt need to be captured then we stop the video tracks from the new stream
                if (!videoSource) {
                  newStream.getVideoTracks().forEach((track) => {
                    track.stop();
                    newStream.removeTrack(track);
                  });
                }

                // return new modified streams
                return [newStream];
              })
              .catch((error) => {
                // set the old media constrains back
                this._apiConfig.mediaConstraints = originalMediaConstraints;

                // output the error
                if (error instanceof Error) {
                  // these are for js related errors
                  Logger.error(
                    'AVChannel.setDevice',
                    `Unable to change the audio/video device, ${JSON.stringify({
                      name: error.name ?? '',
                      message: error.message ?? '',
                      stackTrace: error.stack ?? ''
                    })}`
                  );
                } else {
                  //This can be an internal error like wrs errors
                  Logger.error('AVChannel.setDevice', `Unable to change the audio/video device: ${JSON.stringify(error)}`);
                }

                // return the original streams
                return streams;
              });
          });
        }

        return captureStatus;
      })
      .catch((error) => {
        Logger.error(
          'AVChannel.setDevice',
          `Unable to enumerate devices, ${JSON.stringify({
            name: error.name ?? '',
            message: error.message ?? '',
            stackTrace: error.stack ?? ''
          })}`
        );

        return false;
      });
  };

  // internal sendStats method
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  #sendStats = (result: any): Promise<any> => {
    //check if stats available
    if (!result || !result.stats) {
      this.#onError(1234, 'TfiStatsCollector: stats is not available');
      return;
    }
    // calculate media summary
    this.#mediaSummaryStats.duration = 0;
    this.#mediaSummaryStats.audioPacketsLost = result.stats.audio.local.packetsLost;
    this.#mediaSummaryStats.videoPacketsLost = result.stats.video.local.packetsLost;

    const asb = result.stats.network.availableSendBandwidth;
    if (this.#mediaSummaryStats.ASB.min == 0 || this.#mediaSummaryStats.ASB.min > asb) {
      this.#mediaSummaryStats.ASB.min = asb;
    }
    if (this.#mediaSummaryStats.ASB.max == 0 || this.#mediaSummaryStats.ASB.max < asb) {
      this.#mediaSummaryStats.ASB.max = asb;
    }

    const mos = result.stats.audio.local.mos;
    if (this.#mediaSummaryStats.mos.min == 0 || this.#mediaSummaryStats.mos.min > mos) {
      this.#mediaSummaryStats.mos.min = mos;
    }
    if (this.#mediaSummaryStats.mos.max == 0 || this.#mediaSummaryStats.mos.max < mos) {
      this.#mediaSummaryStats.mos.max = mos;
    }

    const jitter = result.stats.audio.local.jitter;
    if (this.#mediaSummaryStats.jitter.min == 0 || this.#mediaSummaryStats.jitter.min > jitter) {
      this.#mediaSummaryStats.jitter.min = jitter;
    }
    if (this.#mediaSummaryStats.jitter.max == 0 || this.#mediaSummaryStats.jitter.max < jitter) {
      this.#mediaSummaryStats.jitter.max = jitter;
    }

    this.#mediaSummaryStats.audioBytesSent = result.stats.audio.local.bytesSent;
    this.#mediaSummaryStats.audioBytesReceived = result.stats.audio.remote.bytesReceived;
    this.#mediaSummaryStats.videoBytesSent = result.stats.video.local.bytesSent;
    this.#mediaSummaryStats.videoBytesReceived = result.stats.video.remote.bytesReceived;

    // emit the onCollectorStats event
    this.#emitEvent('onCollectorStats', result);
    // resolve promise
    return Promise.resolve({ code: 200, status: 'OK' });
  };

  // on trace
  #onTrace = (message: string): void => {
    // emit the onTrace event
    this.#emitEvent('onTrace', message);
  };

  // on error
  #onError = (code: number, error: string): void => {
    // emit the onTrace event
    this.#emitEvent('onError', { code, error });
  };

  // reset
  #reset = (): void => {
    if (this.#pc) {
      this.#pc.close();
    }
    this.#pc = null;

    this.#callType = undefined;

    this.#intent = '';

    this.#userId = '';

    this.#interactionId = '';

    this.#id = '';

    this.#sessionId = '';

    this.#tunnel = '';

    this.#lastVoiceStreamId = '';

    this.#escalateTimeout = null;

    this.#screenshareStartTriggered = false;

    this.#screenshareConnectTriggered = false;

    this.#screenshareStream = null;

    this.#isScreensharePresenter = false;

    this.#startTime = new Date();

    this.#localStreamDetails = null;

    this.#remoteStreamDetails = [];

    if (this.#statsCollector) {
      this.#statsCollector.cleanUp();
    }
    this.#statsCollector = null;

    if (this.#remoteVideo && typeof this.#remoteVideo.clear === 'function') {
      this.#remoteVideo.clear();
    }
    this.#remoteVideo = null;

    if (this.#localVideo && document.getElementById(this.#localVideo)) {
      const element = document.getElementById(this.#localVideo) as HTMLMediaElement;
      element.srcObject = null;
    }
    this.#localVideo = null;

    this.#lastDeviceChangeEventTime = Date.now();

    this.#statsifs = new Object();
  };

  // to handle event emitter
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  #emitEvent = (event: AVEventTypes, data?: any): void => {
    // emit all event
    this.events.emit('OnAVEvent', {
      event,
      sessionId: this.#sessionId,
      data: data
    });

    // emit Custom AV events
    if (!['onIncoming', 'onAVStats', 'onVoiceActivity', 'onDeviceChange', 'onCollectorStats', 'onTrace'].includes(event)) {
      let interactionId = 0;

      try {
        interactionId = isNaN(Number(this.#interactionId)) ? interactionId : Number(this.#interactionId);
      } catch (error) {
        //
      }

      // create the event name to emit
      const eventName: any = `AVSDK${event.replace('on', '')}Event`;

      let dataToEmit = null;

      // remove all the MediaStream from event when emitting
      // this could case error when emitting to custom widget/launcher from AD
      // MediaStreams cannot be sent via post message
      if (event === 'onSourceVideoAdded') {
        dataToEmit = null;
      } else if (
        event === 'onRemoteVideoAdded' ||
        event === 'onRemoteStreamEnded' ||
        event === 'onScreenshareConnected' ||
        event === 'onStreamStatusChanged'
      ) {
        dataToEmit = Object.assign({}, data);
        delete dataToEmit.stream;
      } else {
        dataToEmit = data;
      }

      const eventData = {
        EventName: eventName,
        InteractionID: interactionId,
        CreatedTime: new Date(),
        Owner: this.#id,
        SessionID: this.#sessionId,
        Data: dataToEmit
      };

      this.#sdkInstance.events.emit(eventName, eventData);
      this.#sdkInstance.events.emit('OnTMACEvent', eventData);

      // do not log 'onBlobEmitted' as it will log continuesly
      if (!['onBlobEmitted'].includes(event)) {
        Logger.info(`${eventName} - ${JSON.stringify(eventData)}`);
      }
    }
  };

  // to send message, this method can be overridden to send message through custom media
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sendMessage(msg: any): void {
    this.#sdkInstance.sendAVControlMessage({
      interactionId: this.#interactionId,
      message: JSON.stringify(msg),
      type: msg.type
    });
  }

  // expose to user to add the streams and return
  onAcquireUserMedia(mediaConstraints: MediaStreamConstraints): MediaStream {
    Logger.warn(`AcquireUserMedia [${this.#sessionId} - ${mediaConstraints}]: method is not overridden, return null stream!`);
    return null;
  }

  // expose to user to modify the streams and return
  onModifyStreams(streams: MediaStream[]): MediaStream[] {
    Logger.warn(`ModifyStreams [${this.#sessionId}]: method is not overridden, returning unmodified streams!`);
    return streams;
  }

  // on message from remote
  onMessage(msg: string): void {
    // parse the message
    const message = JSON.parse(msg);

    // check if this is a 'eventav' with 'call-ended' event then check if stats enabled
    if ((message.type === WrsCallSignal.EVENT && message.event === 'call-ended') || message.type === WrsCallSignal.END) {
      if (this.#statsCollector) {
        this.#statsCollector.cleanUp();
      }
    }

    if (message.type === WrsCallSignal.REQUEST) {
      // get the param
      const param = message.param;
      // get the offering
      const offering = message.offering ?? param;
      // request call type
      const reqCallType = AVUtils.WrcGetCallCode(param);

      // create the peer connection
      this.#createPc(reqCallType);

      // [ReviewCodeLine, Feb 25, '21] to change to below when change is done in call-sdk side
      // TODO:: to add callId later
      // let ack = { type: 'ackav', originalType: 'requestav', callId: jsonMessage.callId, originalSequence: '' };

      // send ack
      this.#send({
        type: WrsCallSignal.REQUEST_ACK,
        owner: this.#id,
        originalType: WrsCallSignal.REQUEST,
        originalSequence: ''
      });

      // check for timeout
      this.#escalateTimeout = setTimeout(
        () => {
          // send response timeout
          this.#send({ type: WrsCallSignal.STATUS, owner: this.#id, param: 'false', source: 'escalate', reason: 'request-timeout' });
          // emit the onFail event
          this.#emitEvent('onFail', {
            code: WrcCodes.ResponseTimeout,
            error: `User has not responded to the ${param} request`
          });
        },
        30000,
        msg
      );

      // check for the call type
      if (WrcCallTypes.Audio === reqCallType || WrcCallTypes.Video === reqCallType) {
        // check the user media
        AVUtils.WrcCheckUserMedia(param, this._apiConfig.mediaConstraints)
          .then(() => {
            // emit the onIncoming event
            this.#emitEvent('onIncoming', {
              param,
              offering,
              response: (accept: boolean) => {
                // send stats
                this.#sendAVStats('config', {
                  mediaReady: true
                });
                // user accept
                if (accept) {
                  this.#send({ type: WrsCallSignal.STATUS, owner: this.#id, param, source: 'escalate' });
                }
                // user reject
                else {
                  this.#send({ type: WrsCallSignal.STATUS, owner: this.#id, param: 'false', source: 'escalate', reason: 'reject' });
                }
                // clear escalation timeout
                clearTimeout(this.#escalateTimeout);
              }
            });
          })
          .catch((error) => {
            Logger.error('AVChannel.WrcCheckUserMedia', error);
            //send stats
            this.#sendAVStats('config', {
              mediaReady: false
            });
            // send avt status, reject
            this.#send({ type: WrsCallSignal.STATUS, owner: this.#id, param: 'false', source: 'escalate', reason: 'no-media' });
            // emit the onFail event
            this.#emitEvent('onFail', {
              code: WrcCodes.MediaFailed,
              error
            });
            // clear escalation timeout
            clearTimeout(this.#escalateTimeout);
          });
      } else {
        // emit the onIncoming event for non audio-video
        this.#emitEvent('onIncoming', {
          param,
          offering,
          response: (accept: boolean) => {
            // user accept
            if (accept) {
              this.#send({ type: WrsCallSignal.STATUS, owner: this.#id, param, source: 'escalate' });
            }
            // user reject
            else {
              this.#send({ type: WrsCallSignal.STATUS, owner: this.#id, param: 'false', source: 'escalate', reason: 'reject' });
            }
            // clear escalation timeout
            clearTimeout(this.#escalateTimeout);
          }
        });
      }
    } else if (message.type === WrsCallSignal.STATUS) {
      // get the param
      const param = message.param;
      // send ack
      // TODO:: to add callId later
      this.#send({ type: WrsCallSignal.ACK, owner: this.#id, originalType: WrsCallSignal.STATUS, originalSequence: '' });
      // clear escalation timeout
      clearTimeout(this.#escalateTimeout);
      // check if the remote user rejected
      if (param === 'false' || param === false) {
        // emit the onFail event
        this.#emitEvent('onFail', {
          code: WrcCodes.Rejected,
          error: 'user rejected'
        });
      }
      // check for legacy mode
      // if not legacy send offer
      // if avtstatus from source 'escalate' send offer
      else if (
        (Object.prototype.hasOwnProperty.call(message, 'version') && message.version === 2) ||
        (Object.prototype.hasOwnProperty.call(message, 'source') && message.source === 'escalate')
      ) {
        // send offer
        this.call();
      }
    } else if (message.type === WrsCallSignal.END) {
      // get the param
      const param = message.param;
      if (param === 'screenshare') {
        // reset screenshare
        this.#screenshareReset();
        // emit the onScreenshareDisconnected event
        this.#emitEvent('onScreenshareDisconnected');
        // screenshare ended
        return;
      } else {
        // send stat
        this.#sendAVStats('mediasummary', null);
        // cstop screenshare if any
        this.stopScreenshare();
        // emit the onEnd event
        this.#emitEvent('onEnd', message.reason);
        // close the peer connection
        this.close();
      }
    } else if (message.type === WrsCallSignal.DROP) {
      // emit the onUserLeft event
      this.#emitEvent('onUserLeft', {
        userId: message.userId.split('_')[1],
        reason: message.reason
      });
    } else if (message.type === WrsScreenshareSignal.ADD) {
      this.#send({ type: WrsScreenshareSignal.STATUS, owner: this.#id, param: 'true' });
    } else if (message.type === WrsScreenshareSignal.STATUS) {
      this.startScreenshare();
    } else if (message.type === WrsScreenshareSignal.END) {
      // reset screenshare
      this.#screenshareReset();
      // emit the onScreenshareDisconnected event
      this.#emitEvent('onScreenshareDisconnected');
    }

    // call implementation onMessage
    this.#channel.onMessage(message);

    // send stats
    if (this.#intent === 'call') {
      message._statSource = 'r';
      this.#sendAVStats('signalling', {
        message: message,
        type: message.type
      });
    }
  }

  // to start av call
  call(param?: number): void {
    // check the param
    if (param) {
      // create peer connection
      this.#createPc(param);
    }

    if (this.#pc) {
      this.#pc.call();
    } else {
      this.#onError(1234, 'call: WrsPeerConnection is not available');
    }
  }

  // to join av call
  join(
    param: number,
    mode: {
      mode: 'whisper' | 'conference' | 'monitor';
    }
  ): void {
    // create peer connection
    this.#createPc(param);

    if (this.#pc) {
      this.#pc.join(mode);
    } else {
      this.#onError(1234, 'join: WrsPeerConnection is not available');
    }
  }

  // to mute the call
  mute(audio: boolean, video: boolean): void {
    if (this.#pc) {
      this.#pc.mute(audio, video);

      let param: string;
      if (audio) {
        param = 'audio';
      } else if (video) {
        param = 'video';
      }

      // send the signal
      // TODO:: to add callId later
      this.#send({ type: WrsCallSignal.MUTE, param, owner: this.#id });
    } else {
      this.#onError(1234, 'mute: WrsPeerConnection is not available');
    }
  }

  // to un mute the call
  unMute(audio: boolean, video: boolean): void {
    if (this.#pc) {
      this.#pc.unMute(audio, video);

      let param: string;
      if (audio) {
        param = 'audio';
      } else if (video) {
        param = 'video';
      }

      // send the signal
      // TODO:: to add callId later
      this.#send({ type: WrsCallSignal.UNMUTE, param, owner: this.#id });
    } else {
      this.#onError(1234, 'unMute: WrsPeerConnection is not available');
    }
  }

  // to hold the call
  hold(): void {
    if (this.#pc) {
      this.#pc.hold();
    } else {
      this.#onError(1234, 'hold: WrsPeerConnection is not available');
    }
  }

  // to un hold the call
  unHold(): void {
    if (this.#pc) {
      this.#pc.unHold();
    } else {
      this.#onError(1234, 'unHold: WrsPeerConnection is not available');
    }
  }

  // to check whether the call is alive
  isConnected(): boolean {
    if (this.#pc) {
      return this.#pc.isConnected();
    } else {
      this.#onError(1234, 'isConnected: WrsPeerConnection is not available');
    }
    return false;
  }

  // to close the call
  close(): void {
    Logger.debug(`Close connection: ${this.#sessionId}`);
    if (this.#pc) {
      this.#pc.close();
    }
    // cstop screenshare if any
    this.stopScreenshare();
    // if video available then clear the the src video
    if (this.#localVideo && document.getElementById(this.#localVideo)) {
      const element: any = document.getElementById(this.#localVideo);
      element.srcObject = null;
    }
    // clear all the remote videos
    if (this.#remoteVideo && typeof this.#remoteVideo.clear === 'function') {
      this.#remoteVideo.clear();
    }
    // send stats
    this.#sendAVStats('mediasummary', null);
    if (this.#statsCollector) {
      this.#statsCollector.cleanUp();
    }
    // reset av channel
    this.#reset();
  }

  // to modify the call
  modify(arg: (...param: any) => Promise<any> | MediaStream[]): void {
    if (this.#pc) {
      this.#pc.modify(arg);
    } else {
      this.#onError(1234, 'modify: WrsPeerConnection is not available');
    }
  }

  // to play the given audio buffer on the call
  playAudio(buffer: ArrayBuffer): any {
    if (this.#pc) {
      return this.#pc.playAudio(buffer);
    } else {
      this.#onError(1234, 'playAudio: WrsPeerConnection is not available');
      return null;
    }
  }

  // to inserts a DTMF tone to the call. It Can be used to porvide inputs to IVR like systems
  sendDtmf(tone: number): void {
    if (this.#pc) {
      this.#pc.sendDtmf(tone);
    } else {
      this.#onError(1234, 'sendDtmf: WrsPeerConnection is not available');
    }
  }

  // to add conference multiple parties
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addConference(pc: any): void {
    // add the source pc then add the conference pc
    this.#mixer.add(this.#pc);
    // add the conference pc to the mixer
    this.#mixer.add(pc);
  }

  // to remove the pc from the mixer
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  removeConference(pc: any): void {
    // add the conference pc to the mixer
    this.#mixer.remove(pc);
  }

  // to start a call
  async startCall(param: number): Promise<any> {
    // create the peer connection
    this.#createPc(param);

    // create a request message
    const msg = {
      type: WrsCallSignal.REQUEST,
      param: AVUtils.WrcGetCallMode(param),
      offering: AVUtils.WrcGetCallMode(param),
      owner: this.#id
    };

    // return a promise
    return new Promise((resolve, reject) => {
      // check the user media
      AVUtils.WrcCheckUserMedia(AVUtils.WrcGetCallMode(param), this._apiConfig.mediaConstraints)
        .then(() => {
          // send the message
          this.#send(msg);

          // send stats
          this.#sendAVStats('config', {
            mediaReady: true
          });

          // check for timeout
          this.#escalateTimeout = setTimeout(
            () => {
              // resolve timeout
              resolve({
                code: WrcCodes.RequestTimeout,
                param: AVUtils.WrcGetCallMode(param)
              });
            },
            30000,
            msg
          );

          // resolve success
          resolve({
            code: WrcCodes.Success,
            param: AVUtils.WrcGetCallMode(param)
          });
        })
        .catch((error) => {
          Logger.error('AVChannel.WrcCheckUserMedia', error);
          // send stats
          this.#sendAVStats('config', {
            mediaReady: false
          });
          // reject promise
          reject(error);
        });
    });
  }

  // to end a call
  endCall(param: number, reason: string): void {
    // send the signal
    // TODO:: to add callId later
    this.#send({ type: WrsCallSignal.END, reason: reason ? reason : 'hungup', param: AVUtils.WrcGetCallMode(param) });
    // close the peer connection
    this.close();
  }

  // to start screenshare
  startScreenshare(): void {
    // check if the screenshare is already triggered
    if (this.#screenshareStartTriggered) {
      //This means user is already on a screenshare
      this.#onError(1234, 'startScreenshare: screenshare start has already been triggered');
      return;
    }
    // set the screenshare active flag to true
    this.#screenshareStartTriggered = true;
    // set the presenter flag
    this.#isScreensharePresenter = true;
    // call av channel to modify existiting video/audio stream to add screen share stream onModifyStream event
    this.modify(this.#modifyStreamsForScreenShare);
  }

  // to stop screenshare
  stopScreenshare(): void {
    // check if the screenshare stream is present
    if (this.#screenshareStream !== null && this.#screenshareStream.id !== null) {
      // modify the stream
      this.modify((streams: MediaStream[]) => {
        // get the screenshare stream id
        const ssid = this.#screenshareStream.id;
        // remove screen share stream
        let i = 0;
        while (i < streams.length) {
          if (streams[i].id == ssid) {
            streams.splice(i, 1);
          } else {
            i++;
          }
        }
        // return the modified stream
        return streams;
      });
    } else {
      // there is no screenshare going on, return
      return;
    }

    // end the screenshare stream by stopping all the video tracks
    if (
      this.#screenshareStream !== null &&
      this.#screenshareStream.getVideoTracks !== undefined &&
      this.#screenshareStream.getVideoTracks()[0] !== undefined
    ) {
      this.#screenshareStream.getVideoTracks()[0].stop();
    }

    // if this user is not the presenter, could be a conferenced user or the other user in a one-one call
    // if the user is a presenter the screenshare streams video track onended event will be called
    if (this.#isScreensharePresenter === false) {
      // emit the onScreenshareEnded event
      this.#emitEvent('onScreenshareEnded');
      // reset the flags and details
      this.#screenshareReset();
    }
  }

  // to drop a call
  dropCall(reason: string): void {
    // send the signal
    // TODO:: to add callId later
    this.#send({ type: WrsCallSignal.DROP, userId: this.#id, reason: reason ? reason : 'hungup' });
    // close the peer connection
    this.close();
  }

  // to start direct call without signalling, default direction is out
  directCall(param: number, direction = 'out'): void {
    // create peer connection
    this.#createPc(param);
    // check the direction, if 'in' just create peer connection only
    if (direction === 'in') {
      return;
    }
    // for voice tunnel direct call send offer
    if (this.#tunnel === 'voice') {
      this.call();
    }
    // else send avtstatus
    else {
      this.#send({ type: WrsCallSignal.STATUS, owner: this.#id, param: AVUtils.WrcGetCallMode(param), source: 'direct' });
    }
  }

  // to start a transfer call without signalling
  transferCall(param: number): void {
    // create peer connection
    this.#createPc(param);
    this.#send({ type: WrsCallSignal.STATUS, owner: this.#id, param: AVUtils.WrcGetCallMode(param), source: 'transfer' });
  }

  // this method is used to get session id
  getSessionId(): string {
    return this.#sessionId;
  }

  // to get the stream info
  getStreamInfo(): {
    local: ILocalStreamInfo;
    remote: IRemoteStreamInfo;
  } {
    const object = {
      local: null,
      remote: null
    };
    if (this.#localStreamDetails) {
      object.local = { ...this.#localStreamDetails };
    }
    if (this.#remoteStreamDetails.length > 0) {
      object.remote = [...this.#remoteStreamDetails];
    }
    return object;
  }

  // to check if screenshare stream is there
  hasScreenshareStream(): boolean {
    if (this.#screenshareStream !== null && this.#screenshareStream.id !== undefined && !this.#isScreensharePresenter) {
      return true;
    }
    return false;
  }

  // to change the call type
  changeCallMode(param: string): void {
    // check if param is provided
    if (!param) {
      return;
    }
    // set the call type
    this.#callType = AVUtils.WrcGetCallCode(param);
  }

  // to get the call type
  getCallType(): number {
    return this.#callType;
  }

  // to get call type mode
  getCallMode(): string {
    return AVUtils.WrcGetCallMode(this.#callType);
  }

  // to set local video
  setLocalVideo(elementId: string): void {
    this.#localVideo = elementId;
  }

  // to set local video
  setRemoteVideo(remoteVideoRef: IRemoteVideoRef): void {
    this.#remoteVideo = remoteVideoRef;
  }

  // to upgrade a audio/onewayvideo to video
  async upgradeToVideo(): Promise<boolean> {
    try {
      await AVUtils.WrcCheckUserMedia('video');
      this.modify(this.#modifyStreamToVideo);
      return true;
    } catch (error) {
      Logger.error('AVChannel.upgradeToVideo', error);
      return false;
    }
  }

  // to set input devices
  setInputDevice(audioDeviceId: string, videoDeviceId: string): Promise<boolean> {
    return this.#setDevice(audioDeviceId, videoDeviceId);
  }

  // to get peer connection
  getPeerConnection(): any {
    // check if pc is null
    if (this.#pc === null) {
      return null;
    }
    // return a copy of peer connection object
    return { ...this.#pc };
  }
}
