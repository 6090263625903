import { useEffect, useState } from 'react';
import { subscribeToEvents } from '../../util/eventEmitter'

function ChatLoader() {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        subscribeToEvents('load_chat', async (isLoading) => {
            setIsLoading(isLoading);
        })


    }, []);
    return (
        <>
            {isLoading && <div id="fountainG">
                <div id="fountainG_1" className="fountainG"></div>
                <div id="fountainG_2" className="fountainG"></div>
                <div id="fountainG_3" className="fountainG"></div>
                <div id="fountainG_4" className="fountainG"></div>
                <div id="fountainG_5" className="fountainG"></div>
                <div id="fountainG_6" className="fountainG"></div>
                <div id="fountainG_7" className="fountainG"></div>
                <div id="fountainG_8" className="fountainG"></div>
            </div>}
        </>

    );
}

export default ChatLoader;