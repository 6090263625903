/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { EventEmitterClass } from '../dispatcher/event-emitter';
import {
  AgentGroupModel,
  AgentInteractionTemplate,
  AgentModel,
  AgentReminder,
  AgentSessionDataModel,
  AgentSnapShot,
  AgentStatusChangeEvent,
  ChatInteractionHistory,
  CommandResultEvent,
  ConversationListModel,
  DashboardColorCodeModel,
  EmailInboxModel,
  EmailOutboxModel,
  EmailTemplate,
  EmailTemplateDeptGroup,
  FavouriteSkill,
  FaxAddressBookModel,
  FaxLineNumbersModel,
  FaxRecipientListModel,
  FaxTemplates,
  FileSaveData,
  IAddEvent,
  IAgentAVMessage,
  IAgentCommand,
  IAgentData,
  IAgentNotification,
  IAgentSnap,
  IAUXCodes,
  IAVControlMessage,
  ICallWorkCode,
  ICancelTransferNotification,
  IChangeEmailStatus,
  IChangeStatus,
  IChangeTextChatConferenceType,
  ICloneEmail,
  IComposeSMS,
  IConferenceTransferCall,
  IConfig,
  IConnection,
  ICreateAgentReminder,
  ICreateAgentReminderTask,
  ICreateGroup,
  IDeflectToDigitalArgs,
  IDeleteEmail,
  IDownloadFaxFile,
  IEndTextChat,
  IExternalConnectGenericCommand,
  IGenericCTICommand,
  IGetAgentActivity,
  IGetAgentListStaffedArgs,
  IGetAgentReminder,
  IGetAgentSessionsList,
  IGetAgentStatus,
  IGetChatInteractionHistory,
  IGetDataServerSignalRNatUrlsArgs,
  IGetEmailTemplates,
  IGetFaxTemplate,
  IGetInteractionData,
  IGetInteractionHistory,
  IGetTMACServer,
  IGetVIPData,
  IInstantMessage,
  IInteractionComment,
  IInvokeAsyncChatDataApiArgs,
  ILogin,
  ILoginData,
  ILogout,
  IMakeCall,
  IMakeCallWithUUI,
  IMarkEmailAsSpam,
  IMaskInboxEmail,
  IMiniDashboard,
  INotifyTyping,
  InteractionAction,
  InteractionData,
  InteractionHistory,
  IOpenIntent,
  IPullEmailFromMakerDraft,
  IPullEmailFromMakerQueue,
  IPullEmailFromSentItems,
  IRegisterCallback,
  IRejectEmail,
  IReplyBulkEmailsInQueue,
  IRespondTextChatTransferNotification,
  IRespondToWqDacRequest,
  IRespondTransferNotification,
  IResponse,
  IResponseData,
  IRestRequest,
  IRouteInteractionFromOtherRouterArgs,
  ISaveEmail,
  ISaveEmailAsEml,
  ISendActionMessage,
  ISendAgentActivity,
  ISendAppMessage,
  ISendBroadcastArgs,
  ISendDTMF,
  ISendEmail,
  ISendFax,
  ISendInstantSMS,
  IsendMessageToAgent,
  ISendNotificationArgs,
  ISendSMS,
  ISendTextChat,
  ISendTextChatTransferNotification,
  ISendTransferNotification,
  ISendWhatsApp,
  IStackToAgentSession,
  ITCISViolation,
  ITextChatConferenceToBot,
  ITransferEmailToAgent,
  ITransferEmailToSkill,
  ITransferInteraction,
  ITransferIVR,
  ITransferTextChat,
  ITransferTextChatToQueue,
  IUIEvent,
  IUpdateAgentReminder,
  IUpdateAsyncChatDataArgs,
  IUpdateFaxStatus,
  IUpdateGroup,
  IUpdateIntent,
  IUpdateMosDetails,
  IUploadFiles,
  IVideoSnap,
  IVoiceSendMessage,
  QueueColorCodesModel,
  QueueStatusEvent,
  SpeedDialModel,
  TextTemplate,
  TextTemplateGroup,
  VideoSnapData,
  VIPDataModel,
  WallboardSkillModel,
  WorkCode
} from '../interfaces';
import { Logger, SignalRWrapper } from '../utils';
import { SDKInternal } from './sdk-internal';

export class SDK {
  // private call server varaible
  #sdkInternal: SDKInternal;

  // singleton instance variable
  private static _instance: SDK = new SDK();

  // event emitter intance for TMAC
  events: EventEmitterClass;

  // tmac server signalr connector
  signalR: SignalRWrapper;

  constructor() {
    // create an instance of EventEmitterClass for TMACEvents
    this.events = new EventEmitterClass();
    // init call server
    this.#sdkInternal = new SDKInternal(this);
    // assign the signalR
    this.signalR = this.#sdkInternal.signalRConnector;
  }

  // singleton instance method
  public static getInstance(): SDK {
    return SDK._instance;
  }

  /**
   * To set SDK config for initialisation
   * @param config config to set
   */
  public setConfig(config: IConfig): void {
    // check if the config is provided
    if (Object.keys(config).length === 0) {
      Logger.warn('setConfig: Config is empty!', false);
    }
    // set the url
    this.#sdkInternal.setConfig(config);
  }

  /**
   * To get the config which is set
   */
  public getConfig(): IConfig {
    return this.#sdkInternal.getConfig();
  }

  /**
   * To set any app configs
   *
   * @param {String } type Type of app
   * @param {Any} config Config of app
   */
  public setAppConfig(type: string, config: any): void {
    // check if the config is provided
    if (Object.keys(config).length === 0) {
      Logger.warn('setAppConfig: Config is empty!', false);
    }
    // set the url
    this.#sdkInternal.setAppConfig(type, config);
  }

  /**
   * To get saved app config
   *
   * @param {String }type Type of app
   * @returns {Any}
   */
  public getAppConfig(type: string): any {
    return this.#sdkInternal.getAppConfig(type);
  }

  /**
   * To get the connection data
   */
  public getConnectionData(): IConnection {
    return this.#sdkInternal.getConnectionData();
  }

  /**
   * To set required agent data for commands
   * @param {IAgentData} requestArgs Input data required
   */
  public setAgentData(requestArgs: IAgentData): boolean {
    return this.#sdkInternal.setAgentData(requestArgs);
  }

  /**
   * To get the logged in agent data
   */
  public getAgentData(): IAgentData {
    return this.#sdkInternal.getAgentData();
  }

  /**
   * To get all the open interactions
   */
  public getInteractions(): any[] {
    return this.#sdkInternal.getInteractions();
  }

  /**
   * To get an open interaction by ID
   * @param interactionId ID of the interaction to filter
   */
  public getInteractionById(interactionId: number): any {
    return this.#sdkInternal.getInteractionById(interactionId);
  }

  /**
   * @description To get the tmac proxy version
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getProxyVersion(userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.getProxyVersion(userObject);
  }

  /**
   * To get the tmac server version
   * @param tmacServer TMAC server name to get the version
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getTMACVersion(tmacServer: string, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.getTMACVersion(tmacServer, userObject);
  }

  /**
   * To get the windows authentication ID
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getLanID(userObject?: any): Promise<IResponse> {
    return this.#sdkInternal.getLanID(userObject);
  }

  /**
   * @description To get the domain list configured in server
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getUserDomainList(userObject?: any): Promise<IResponseData<string[]>> {
    return this.#sdkInternal.getUserDomainList(userObject);
  }

  /**
   * @description To login the agent to tmac server
   * @param {ILogin} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async login(requestArgs: ILogin, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.login(requestArgs, userObject);
  }

  /**
   * To check agent session found in server
   * @param userObject Extra user data from the request to callback for reference
   */
  public async checkAgentSession(userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.checkAgentSession(userObject);
  }

  /**
   * @description To fork the session to an existing agent session
   * @param {IStackToAgentSession} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async stackAgentSession(requestArgs: IStackToAgentSession, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.stackAgentSession(requestArgs, userObject);
  }

  /**
   * To get the login data if any new page is opened on login successful
   * @param agentId To filter for an agent ID
   */
  public async getLoginData(agentId?: string): Promise<ILoginData> {
    return this.#sdkInternal.getLoginData(agentId);
  }

  /**
   * @description To logout agent from tmac server with reason
   * @param {ILogout} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async logout(requestArgs: ILogout, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.logout(requestArgs, userObject);
  }

  /**
   * To get TMAC Server ID of an agent
   * @param {IGetTMACServer} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getTMACServerID(requestArgs: IGetTMACServer, userObject?: any): Promise<IResponse> {
    return this.#sdkInternal.getTMACServerID(requestArgs, userObject);
  }

  /**
   * To get all the events from TMAC server
   */
  public getEvents(): void {
    return this.#sdkInternal.getEvents();
  }

  /**
   * To get repeat queue events
   */
  public async getRepeatQueueEvents(): Promise<IResponseData<IUIEvent[]>> {
    return this.#sdkInternal.getRepeatQueueEvents();
  }

  /**
   * To change from signalr to event polling
   */
  public changeToEventPolling(): void {
    this.#sdkInternal.changeToEventPolling();
  }

  ////////// GENERIC METHODS //////////

  /**
   * To get all the aux codes
   * @param byTeam to filter for team
   * @param userObject Extra user data from the request to callback for reference
   */
  public async loadAUXCodes(byTeam: boolean, userObject?: any): Promise<IResponseData<IAUXCodes[]>> {
    return this.#sdkInternal.loadAUXCodes(byTeam, userObject);
  }

  /**
   * To change the status of the agent
   * @param {IChangeStatus} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async changeStatus(requestArgs: IChangeStatus, userObject?: any): Promise<IResponseData<CommandResultEvent | AgentStatusChangeEvent>> {
    return this.#sdkInternal.changeStatus(requestArgs, userObject);
  }

  /**
   * To get all the intents
   * @param userObject Extra user data from the request to callback for reference
   */
  public async loadIntents(userObject?: any): Promise<IResponseData<string[]>> {
    return this.#sdkInternal.loadIntents(userObject);
  }

  /**
   * To update an intent of an iteraction
   * @param {IUpdateIntent} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async updateIntent(requestArgs: IUpdateIntent, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.updateIntent(requestArgs, userObject);
  }

  /**
   * To open an intent of an iteraction
   * @param {IOpenIntent} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async openIntent(requestArgs: IOpenIntent, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.openIntent(requestArgs, userObject);
  }

  /**
   * To inform the TMAC server that the interaction is selected
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async selectInteraction(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.selectInteraction(interactionId, userObject);
  }

  /**
   * To close an interaction
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async closeInteraction(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.closeInteraction(interactionId, userObject);
  }

  /**
   * To reload the complete interaction
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async reloadInteraction(interactionId: string, userObject?: any): Promise<IResponseData<IUIEvent[]>> {
    return this.#sdkInternal.reloadInteraction(interactionId, userObject);
  }

  /**
   * To get the agent session of all the agents logged in to the server
   * @param tmacServer to get list for particualr TMAC server
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getLoggedInAgentList(tmacServer: string, userObject?: any): Promise<IResponseData<AgentSessionDataModel[]>> {
    return this.#sdkInternal.getLoggedInAgentList(tmacServer, userObject);
  }

  /**
   * To get the list of staffed agents from all the TMAC server(s)
   * @param {IGetAgentListStaffedArgs} requestArgs
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getAgentListStaffed(requestArgs: IGetAgentListStaffedArgs, userObject?: any): Promise<IResponseData<AgentModel[]>> {
    return this.#sdkInternal.getAgentListStaffed(requestArgs, userObject);
  }

  /**
   * To get agent session list from specified server
   * @param {IGetAgentSessionsList} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getAgentSessionsList(requestArgs: IGetAgentSessionsList, userObject?: any): Promise<IResponseData<AgentSessionDataModel[]>> {
    return this.#sdkInternal.getAgentSessionsList(requestArgs, userObject);
  }

  /**
   * To get the list of favorite skills configured
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getFavouriteSkills(userObject?: any): Promise<IResponseData<FavouriteSkill[]>> {
    return this.#sdkInternal.getFavouriteSkills(userObject);
  }

  /**
   * To get the list of speed dial numbers
   * @param byTeam to filter for team
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getSpeedDialNumbers(byTeam: boolean, userObject?: any): Promise<IResponseData<SpeedDialModel[]>> {
    return this.#sdkInternal.getSpeedDialNumbers(byTeam, userObject);
  }

  /**
   * To get the status of the agent
   * @param {IGetAgentStatus} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getAgentStatus(requestArgs: IGetAgentStatus, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.getAgentStatus(requestArgs, userObject);
  }
  /**
   * To get the status of logged in agent
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getMyStatus(userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.getMyStatus(userObject);
  }

  /**
   * To get the status of the queue for a specific skill
   * @param skillId to filter for skill
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getQueueStatus(skillId: string, userObject?: any): Promise<IResponseData<CommandResultEvent | QueueStatusEvent>> {
    return this.#sdkInternal.getQueueStatus(skillId, userObject);
  }

  /**
   * To get the complete details of the skill for a specific skill
   * @param skillId to filter for skill
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getTmacWallboardForSkill(skillId: string, userObject?: any): Promise<IResponseData<WallboardSkillModel>> {
    return this.#sdkInternal.getTmacWallboardForSkill(skillId, userObject);
  }

  /**
   * To get the complete details of the skill for all the skills
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getTmacWallboardSkills(userObject?: any): Promise<IResponseData<WallboardSkillModel[]>> {
    return this.#sdkInternal.getTmacWallboardSkills(userObject);
  }

  /**
   * To get the scheduled reminders. status, startDateTime, endDateTime or message can be provider to filter the reminder
   *
   * startDateTime and endDateTime should be provided in yyyyMMddHHmmss format
   *
   * @param {IGetAgentReminder} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getAgentReminders(requestArgs: IGetAgentReminder, userObject?: any): Promise<IResponseData<AgentReminder[]>> {
    return this.#sdkInternal.getAgentReminders(requestArgs, userObject);
  }

  /**
   * To create an agent reminder
   *
   * reminderDate should be in yyyyMMdd and reminderTime should be in HHmmss format
   *
   * Response of this method invocation will be integer and an event i.e. CreateAgentReminderEvent will be sent to UI
   * @param {ICreateAgentReminder} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async createAgentReminder(requestArgs: ICreateAgentReminder, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.createAgentReminder(requestArgs, userObject);
  }

  /**
   * To create an agent reminder task
   *
   * reminderDate should be in yyyyMMdd and reminderTime should be in HHmmss format
   *
   * Response of this method invocation will be integer and an event i.e. CreateAgentReminderEvent will be sent to UI
   * @param {ICreateAgentReminderTask} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async createAgentReminderTask(requestArgs: ICreateAgentReminderTask, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.createAgentReminderTask(requestArgs, userObject);
  }

  /**
   * To update an agent reminder
   *
   * Response of this method invocation will be integer and an event i.e. UpdateAgentReminderEvent/DeleteAgentReminderEvent will be sent to UI
   * NOTE: For reminder date time update there wont be any event sent to you along with the integer response
   *
   * @param {IUpdateAgentReminder} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async updateAgentReminder(requestArgs: IUpdateAgentReminder, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.updateAgentReminder(requestArgs, userObject);
  }

  /**
   * To get a list interaction history details of a customer based on the customer detail
   *
   * 'lastId' should be 0 when loading for the first time
   *
   * To load more interaction after
   * fetching a number of interactions, the 'LastID' received in
   * 'interactionHistoryEvent' or getInteractionHistory response data should be
   * provided to load the interactions previous to the received interactions
   *
   * @param {IGetInteractionHistory} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getInteractionHistory(requestArgs: IGetInteractionHistory, userObject?: any): Promise<IResponseData<InteractionHistory[]>> {
    return this.#sdkInternal.getInteractionHistory(requestArgs, userObject);
  }

  /**
   * To send commands to external connector
   * @param {IExternalConnectGenericCommand} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async externalConnectGenericCommand(requestArgs: IExternalConnectGenericCommand, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.externalConnectGenericCommand(requestArgs, userObject);
  }

  /**
   * T0 load the call work codes from server
   * @param byTeam to filter for team
   * @param userObject Extra user data from the request to callback for reference
   */
  public async loadCallWorkCodes(byTeam: boolean, userObject?: any): Promise<IResponseData<WorkCode[]>> {
    return this.#sdkInternal.loadCallWorkCodes(byTeam, userObject);
  }

  /**
   * To set call work code for an interaction
   * @param {ICallWorkCode} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async setCallWorkCode(requestArgs: ICallWorkCode, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.setCallWorkCode(requestArgs, userObject);
  }

  /**
   * To remove call work code for an interaction
   * @param {ICallWorkCode} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async removeCallWorkCode(requestArgs: ICallWorkCode, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.removeCallWorkCode(requestArgs, userObject);
  }

  /**
   * To send notification to an agent
   * @param {IAgentNotification} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendAgentNotification(requestArgs: IAgentNotification, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.sendAgentNotification(requestArgs, userObject);
  }

  /**
   * To send command to an agent
   * @param {IAgentCommand} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendCommandToAgent(requestArgs: IAgentCommand, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.sendCommandToAgent(requestArgs, userObject);
  }

  /**
   * To send instant message to an agent
   * @param {IInstantMessage} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendIM(requestArgs: IInstantMessage, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.sendIM(requestArgs, userObject);
  }

  /**
   * To add event to agent session
   * @param {IAddEvent} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async addEventToAgentSession(requestArgs: IAddEvent, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.addEventToAgentSession(requestArgs, userObject);
  }

  /**
   * To send generic CTI commands
   * @param {IGenericCTICommand} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendGenericCTICommand(requestArgs: IGenericCTICommand, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.sendGenericCTICommand(requestArgs, userObject);
  }

  /**
   * To get data for mini dashboard
   * @param {IMiniDashboard} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getMiniDashboard(requestArgs: IMiniDashboard, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.getMiniDashboard(requestArgs, userObject);
  }

  /**
   * To get VIP data details by customer
   * @param {IGetVIPData} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getVIPData(requestArgs: IGetVIPData, userObject?: any): Promise<IResponseData<VIPDataModel[]>> {
    return this.#sdkInternal.getVIPData(requestArgs, userObject);
  }

  /**
   * To remove file from proxy upload path
   * @param filePath File path to remove from
   * @param userObject Extra user data from the request to callback for reference
   */
  public async removeFile(filePath: string, userObject?: any): Promise<IResponse> {
    return this.#sdkInternal.removeFile(filePath, userObject);
  }

  /**
   * To get dashboard color codes cofigured in OCM
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getDashboardColorCodes(userObject?: any): Promise<IResponseData<DashboardColorCodeModel[]>> {
    return this.#sdkInternal.getDashboardColorCodes(userObject);
  }

  /**
   * To get queue color codes cofigured in OCM
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getQueueTimeColorCodes(userObject?: any): Promise<IResponseData<QueueColorCodesModel[]>> {
    return this.#sdkInternal.getQueueTimeColorCodes(userObject);
  }

  /**
   * To update MOS value for an AV interaction
   * @param {IGetVIPData} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async updateMosDetails(requestArgs: IUpdateMosDetails, userObject?: any): Promise<IResponse> {
    return this.#sdkInternal.updateMosDetails(requestArgs, userObject);
  }

  /**
   * To send rest request to provided URL
   * @param {IRestRequest} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async restRequest(requestArgs: IRestRequest, userObject?: any): Promise<IResponse> {
    return this.#sdkInternal.restRequest(requestArgs, userObject);
  }

  /**
   * To get text template departments from OCM
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getTextTemplateDepartments(userObject?: any): Promise<IResponseData<TextTemplateGroup[]>> {
    return this.#sdkInternal.getTextTemplateDepartments(userObject);
  }

  /**
   * To get the text template groups by department ID
   * @param departmentId to filter by department ID
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getTextTemplateGroups(departmentId: string, userObject?: any): Promise<IResponseData<TextTemplateGroup[]>> {
    return this.#sdkInternal.getTextTemplateGroups(departmentId, userObject);
  }

  /**
   * To get the text template by group ID
   * @param groupId to filter by group ID
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getTextTemplates(groupId: string, userObject?: any): Promise<IResponseData<TextTemplate[]>> {
    return this.#sdkInternal.getTextTemplates(groupId, userObject);
  }

  /**
   * To get all the text templates
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getAllTextTemplates(userObject?: any): Promise<IResponseData<TextTemplate[]>> {
    return this.#sdkInternal.getAllTextTemplates(userObject);
  }

  /**
   * To send TCIS violation to the server
   * @param {ITCISViolation} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async insertTCISViolation(requestArgs: ITCISViolation, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.insertTCISViolation(requestArgs, userObject);
  }

  /**
   * To save video snap for a session
   * @param {IVideoSnap} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async saveVideoSnap(requestArgs: IVideoSnap, userObject?: any): Promise<IResponseData<VideoSnapData>> {
    return this.#sdkInternal.saveVideoSnap(requestArgs, userObject);
  }

  /**
   * To save agent snapshot and screenshot
   * @param {IAgentSnap} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async saveAgentSnap(requestArgs: IAgentSnap, userObject?: any): Promise<IResponseData<VideoSnapData>> {
    return this.#sdkInternal.saveAgentSnap(requestArgs, userObject);
  }

  /**s
   * To upload base64 files
   * @param {IUploadFiles} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async uploadFiles(requestArgs: IUploadFiles, userObject?: any): Promise<IResponseData<FileSaveData[]>> {
    return this.#sdkInternal.uploadFiles(requestArgs, userObject);
  }

  /**
   * To store WebRTC comm stats to DB
   * @param {IGetTMACServer} requestArgs WebRTC stats data
   */
  public async storeWebRTCCommStats(requestArgs: any[]): Promise<IResponseData<boolean>> {
    return this.#sdkInternal.storeWebRTCCommStats(requestArgs);
  }

  /**
   * To send AV messages to another agent
   * @param {IAgentAVMessage} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendAgentAVMessage(requestArgs: IAgentAVMessage, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.sendAgentAVMessage(requestArgs, userObject);
  }

  /**
   * To send a transfer notification for an interaction
   * @param {IGetTMACServer} requestArgs Input data required ISendTransferNotification
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendTransferNotification(requestArgs: ISendTransferNotification, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.sendTransferNotification(requestArgs, userObject);
  }

  /**
   * To cancel the interaction notification sent for an interaction
   * @param {ICancelTransferNotification} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async cancelTransferNotification(requestArgs: ICancelTransferNotification, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.cancelTransferNotification(requestArgs, userObject);
  }

  /**
   * To respond to an interaction transfer notification
   * @param {IRespondTransferNotification} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async respondTransferNotification(requestArgs: IRespondTransferNotification, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.respondTransferNotification(requestArgs, userObject);
  }

  /**
   * To transfer an interaction after response or to blind transfer
   * @param {IInteractionTransfer} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferInteraction(requestArgs: ITransferInteraction, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.transferInteraction(requestArgs, userObject);
  }

  /**
   * To save comments for an interaction
   * @param {IInteractionComment} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async saveInteractionComment(requestArgs: IInteractionComment, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.saveInteractionComment(requestArgs, userObject);
  }

  /**
   * To send AV control message for a AV interaction
   * @param {IAVControlMessage} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendAVControlMessage(requestArgs: IAVControlMessage, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.sendAVControlMessage(requestArgs, userObject);
  }

  /**
   * To send WhatsApp message to a mobile number
   * @param {ISendWhatsApp} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async SendWhatsApp(requestArgs: ISendWhatsApp, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.SendWhatsApp(requestArgs, userObject);
  }

  /**
   * To get login json for agent desktop
   * @param id user id to get specific for an agent
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getTmacLoginJson(id: string, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.getTmacLoginJson(id, userObject);
  }

  /**
   * To get widget preview json for a particular template id
   * @param id widget template id
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getWidgetPreviewJson(id: string, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.getWidgetPreviewJson(id, userObject);
  }

  /**
   * To get the agent's current screen activity and location for supervisor
   * @param {IGetAgentActivity} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getAgentActivity(requestArgs: IGetAgentActivity, userObject?: any): Promise<IResponseData<AgentSnapShot>> {
    return this.#sdkInternal.getAgentActivity(requestArgs, userObject);
  }

  /**
   * To send the current screen activity and location to supervisor
   * @param {ISendAgentActivity} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendAgentActivity(requestArgs: ISendAgentActivity, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.sendAgentActivity(requestArgs, userObject);
  }

  /**
   * To get the agent specific templates for interactions
   *
   * @param channel to filter to specific channel
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getInteractionTemplates(channel: string, userObject?: any): Promise<IResponseData<AgentInteractionTemplate[]>> {
    return this.#sdkInternal.getInteractionTemplates(channel, userObject);
  }

  /**
   * To get interaction actions for an interaction session
   *
   * @param sessionId session id of an interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getInteractionActions(sessionId: string, userObject?: any): Promise<IResponseData<InteractionAction[]>> {
    return this.#sdkInternal.getInteractionActions(sessionId, userObject);
  }

  /**
   * To respond to work queue item's direct agent request
   * @param {IRespondToWqDacRequest} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async respondToWqDacRequest(requestArgs: IRespondToWqDacRequest, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.respondToWqDacRequest(requestArgs, userObject);
  }

  /**
   * To get interaction data
   * @param {IGetInteractionData} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getInteractionData(requestArgs: IGetInteractionData, userObject?: any): Promise<IResponseData<InteractionData[]>> {
    return this.#sdkInternal.getInteractionData(requestArgs, userObject);
  }

  /**
   * To get converstion list
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getConversationList(userObject?: any): Promise<IResponseData<ConversationListModel[]>> {
    return this.#sdkInternal.getConversationList(userObject);
  }

  /**
   * To send agent message
   * @param {IsendMessageToAgent} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendMessageToAgent(requestArgs: IsendMessageToAgent, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.sendMessageToAgent(requestArgs, userObject);
  }

  /**
   * To get agent group info
   * @param groupId to filter by group ID
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getGroupInfo(groupId: string, userObject?: any): Promise<IResponseData<AgentGroupModel>> {
    return this.#sdkInternal.getGroupInfo(groupId, userObject);
  }

  /**
   * To create a new agent group
   * @param {ICreateGroup} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async createGroup(requestArgs: ICreateGroup, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.createGroup(requestArgs, userObject);
  }

  /**
   * To update a agent group details
   * @param {IUpdateGroup} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async updateGroup(requestArgs: IUpdateGroup, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.updateGroup(requestArgs, userObject);
  }

  /**
   * To sent broadcast message for team
   *
   * @param {ISendBroadcastArgs} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  setBroadcastMessageForTeam(requestArgs: ISendBroadcastArgs, userObject?: any): Promise<IResponseData<CommandResultEvent[]>> {
    return this.#sdkInternal.setBroadcastMessageForTeam(requestArgs, userObject);
  }

  /**
   * To send notification to team/supervisor or list of agents
   *
   * @param {ISendNotificationArgs} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendNotification(requestArgs: ISendNotificationArgs, userObject?: any): Promise<void> {
    await this.#sdkInternal.sendNotification(requestArgs, userObject);
  }

  /**
   * To send notification to team/supervisor or list of agents
   *
   * @param {IDeflectToDigitalArgs} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async deflectToDigital(requestArgs: IDeflectToDigitalArgs, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.deflectToDigital(requestArgs, userObject);
  }

  /**
   * To add a new interaction from external sources/components
   * @param {IRouteInteractionFromOtherRouterArgs} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async routeInteractionFromOtherRouter(requestArgs: IRouteInteractionFromOtherRouterArgs, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.routeInteractionFromOtherRouter(requestArgs, userObject);
  }

  /**
   * To get Data Server SignalR Nat Urls
   * @param {IGetDataServerSignalRNatUrlsArgs} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getDataServerSignalRNatUrls(requestArgs: IGetDataServerSignalRNatUrlsArgs, userObject?: any): Promise<IResponseData<string[]>> {
    return this.#sdkInternal.getDataServerSignalRNatUrls(requestArgs, userObject);
  }

  ////////// VOICE //////////

  /**
   * To make a call to provided number
   * @param {IMakeCall} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async makeCall(requestArgs: IMakeCall, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.makeCall(requestArgs, userObject);
  }

  /**
   * To make a call to provided number with UUI
   * @param {IMakeCallWithUUI} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async makeCallWithUUI(requestArgs: IMakeCallWithUUI, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.makeCallWithUUI(requestArgs, userObject);
  }

  /**
   * To answer an incoming call
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async answerCall(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.answerCall(interactionId, userObject);
  }

  /**
   * To hold a on going call
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async holdCall(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.holdCall(interactionId, userObject);
  }

  /**
   * To unhold a ongoing held call
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async unHoldCall(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.unHoldCall(interactionId, userObject);
  }

  /**
   * To disconnect a on going call
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async disconnectCall(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.disconnectCall(interactionId, userObject);
  }

  /**
   * To disconnect a on going call with connection handle
   * @param connectionHandle connection handle of a call
   * @param userObject Extra user data from the request to callback for reference
   */
  public async disconnectCallByHandle(connectionHandle: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.disconnectCallByHandle(connectionHandle, userObject);
  }

  /**
   * To disconnect a on going call from station
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async disconnectStation(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.disconnectStation(interactionId, userObject);
  }

  /**
   * To consult conference a call to a provided number
   * @param {IConferenceTransferCall} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async conferenceCall(requestArgs: IConferenceTransferCall, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.conferenceCall(requestArgs, userObject);
  }

  /**
   * To cancel a consulted conference call
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async conferenceCancel(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.conferenceCancel(interactionId, userObject);
  }

  /**
   * To complete a consulted conference call
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async conferenceComplete(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.conferenceComplete(interactionId, userObject);
  }

  /**
   * To blind conference a call to a provided number
   * @param {IConferenceTransferCall} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async conferenceBlind(requestArgs: IConferenceTransferCall, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.conferenceBlind(requestArgs, userObject);
  }

  /**
   * To consult transfer call to a provided number
   * @param {IConferenceTransferCall} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferCall(requestArgs: IConferenceTransferCall, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.transferCall(requestArgs, userObject);
  }

  /**
   * To cancel a consulted transfer call
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferCancel(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.transferCancel(interactionId, userObject);
  }

  /**
   * To complete a consulted transfer call
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferComplete(interactionId: string, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.transferComplete(interactionId, userObject);
  }

  /**
   * To blind transfer a call to a provided number
   * @param {IConferenceTransferCall} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferBlind(requestArgs: IConferenceTransferCall, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.transferBlind(requestArgs, userObject);
  }

  /**
   * To transfer on going call to IVR
   * @param {ITransferIVR} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferToIVR(requestArgs: ITransferIVR, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.transferToIVR(requestArgs, userObject);
  }

  /**
   * To send DTMF to an ongoing call
   * @param {ISendDTMF} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendDTMF(requestArgs: ISendDTMF, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.sendDTMF(requestArgs, userObject);
  }

  /**
   * To send message to a mobile during a call
   * @param {IVoiceSendMessage} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async voiceSendMessage(requestArgs: IVoiceSendMessage, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.voiceSendMessage(requestArgs, userObject);
  }

  ////////// SMS //////////

  /**
   * To compose a SMS to a number
   * @param {IComposeSMS} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async composeSMS(requestArgs: IComposeSMS, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.composeSMS(requestArgs, userObject);
  }

  /**
   * To send a SMS to a number
   * @param {ISendSMS} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendSMS(requestArgs: ISendSMS, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.sendSMS(requestArgs, userObject);
  }

  /**
   * To send an instant SMS to a number without an interaction
   * @param {ISendInstantSMS} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendInstantSMS(requestArgs: ISendInstantSMS, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.sendInstantSMS(requestArgs, userObject);
  }

  ////////// TEXT CHAT //////////

  /**
   * To send chat typing sate change to server
   * @param {INotifyTyping} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async notifyTextChatTyping(requestArgs: INotifyTyping, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.notifyTextChatTyping(requestArgs, userObject);
  }

  /**
   * To send user message for a text chat interaction
   * @param {ISendTextChat} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendTextChat(requestArgs: ISendTextChat, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.sendTextChat(requestArgs, userObject);
  }

  /**
   * To send an app message for a text chat interaction
   * @param {ISendAppMessage} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendAppMessage(requestArgs: ISendAppMessage, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.sendAppMessage(requestArgs, userObject);
  }

  /**
   * To register a callback for a text chat interaction
   * @param {IRegisterCallback} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async registerTextChatCallback(requestArgs: IRegisterCallback, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.registerTextChatCallback(requestArgs, userObject);
  }

  /**
   * To end an ongoing text chat interaction
   * @param {IEndTextChat} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async endTextChat(requestArgs: IEndTextChat, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.endTextChat(requestArgs, userObject);
  }

  /**
   * To send a text chat transfer notification
   * @param {ISendTextChatTransferNotification} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendTextChatTransferNotification(
    requestArgs: ISendTextChatTransferNotification,
    userObject?: any
  ): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.sendTextChatTransferNotification(requestArgs, userObject);
  }

  /**
   * To respond to a text chat transfer notification
   * @param {IRespondTextChatTransferNotification} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async respondTextChatTransferNotification(
    requestArgs: IRespondTextChatTransferNotification,
    userObject?: any
  ): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.respondTextChatTransferNotification(requestArgs, userObject);
  }

  /**
   * To transfer a text chat after response or to blind transfer
   * @param {ITransferTextChat} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferTextChat(requestArgs: ITransferTextChat, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.transferTextChat(requestArgs, userObject);
  }

  /**
   * To transfer a textchat to queue
   * @param {ITransferTextChatToQueue} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferTextChatToQueue(requestArgs: ITransferTextChatToQueue, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.transferTextChatToQueue(requestArgs, userObject);
  }

  /**
   * To freeze the auto response timer
   * @param interactionId ID of the interaction
   * @param userObject Extra user data from the request to callback for reference
   */
  public async freezeTextChatAutoResponse(interactionId: string, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.freezeTextChatAutoResponse(interactionId, userObject);
  }

  /**
   * To change conference type of a textchat interaction
   * @param {IChangeTextChatConferenceType} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async changeTextChatConferenceType(requestArgs: IChangeTextChatConferenceType, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.changeTextChatConferenceType(requestArgs, userObject);
  }

  /**
   * To transfer a textchat interaction to bot
   * @param {ITextChatConferenceToBot} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async textChatConferenceToBot(requestArgs: ITextChatConferenceToBot, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.textChatConferenceToBot(requestArgs, userObject);
  }

  /**
   * To send an action message
   * @param {ISendActionMessage} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendActionMessage(requestArgs: ISendActionMessage, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.sendActionMessage(requestArgs, userObject);
  }

  /**
   * To get chat interaction history by group id ot cif
   * @param {IGetChatInteractionHistory} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getChatInteractionHistory(
    requestArgs: IGetChatInteractionHistory,
    userObject?: any
  ): Promise<IResponseData<ChatInteractionHistory[]>> {
    return this.#sdkInternal.getChatInteractionHistory(requestArgs, userObject);
  }

  /**
   * To update async chat data
   * @param {IUpdateAsyncChatDataArgs} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async updateAsyncChatData(requestArgs: IUpdateAsyncChatDataArgs, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.updateAsyncChatData(requestArgs, userObject);
  }

  /**
   * To invoke async chat data api
   * @param {IInvokeAsyncChatDataApiArgs} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async invokeAsyncChatDataApi(requestArgs: IInvokeAsyncChatDataApiArgs, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.invokeAsyncChatDataApi(requestArgs, userObject);
  }

  ////////// FAX //////////

  /**
   * To get address book for a faxline
   * @param faxLine to filter for a faxline
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getFaxAddressBook(faxLine: string, userObject?: any): Promise<IResponseData<FaxAddressBookModel[]>> {
    return this.#sdkInternal.getFaxAddressBook(faxLine, userObject);
  }

  /**
   * To get fax recipients for an address book
   * @param adddressBookId to filter for a address book
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getFaxRecipients(adddressBookId: string, userObject?: any): Promise<IResponseData<FaxRecipientListModel[]>> {
    return this.#sdkInternal.getFaxRecipients(adddressBookId, userObject);
  }

  /**
   * To get all the faxline numbers
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getFaxLineNumbers(userObject?: any): Promise<IResponseData<FaxLineNumbersModel[]>> {
    return this.#sdkInternal.getFaxLineNumbers(userObject);
  }

  /**
   * To get all the fax template names
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getFaxTemplateNames(userObject?: any): Promise<IResponseData<FaxTemplates[]>> {
    return this.#sdkInternal.getFaxTemplateNames(userObject);
  }

  /**
   * To get a fax template by name and type
   * @param {IGetFaxTemplate} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getFaxTemplate(requestArgs: IGetFaxTemplate, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.getFaxTemplate(requestArgs, userObject);
  }

  /**
   * To send a fax
   * @param {ISendFax} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendFax(requestArgs: ISendFax, userObject?: any): Promise<IResponseData<CommandResultEvent>> {
    return this.#sdkInternal.sendFax(requestArgs, userObject);
  }

  /**
   * To download a fax file
   * @param {IDownloadFaxFile} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async downloadFaxFile(requestArgs: IDownloadFaxFile, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.downloadFaxFile(requestArgs, userObject);
  }

  /**
   * To update status of a fax
   * @param {IUpdateFaxStatus} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async updateFaxStatus(requestArgs: IUpdateFaxStatus, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.updateFaxStatus(requestArgs, userObject);
  }

  ////////// EMAIL //////////

  /**
   * To get the mail boxes for an agent/mailbox
   *
   * @param {'agent' | 'mailbox'} type Type
   * @param userObject Extra user data from the request to callback for reference
   *
   * 'type' parameter can only be 'agent' or 'mailbox'.
   * If 'agent' then it will load data for the logged in agent.
   * If 'mailbox' then it will load all the mailbox data.
   */
  public async getMailboxes(type: 'agent' | 'mailbox', userObject?: any): Promise<IResponseData<string[]>> {
    return this.#sdkInternal.getMailboxes(type, userObject);
  }

  /**
   * To get frequent email address list
   *
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getFrequentEmailAddressList(userObject?: any): Promise<IResponseData<string[]>> {
    return this.#sdkInternal.getFrequentEmailAddressList(userObject);
  }

  /**
   * To compose a new email
   *
   * @param mailBox mailbox address to compose from
   * @param userObject Extra user data from the request to callback for reference
   *
   * Response of this method invocation will be an event i.e. OutgoingEmailEvent
   */
  public async composeNewEmail(mailBox: string, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.composeNewEmail(mailBox, userObject);
  }

  /**
   * To send an email or reply to an email
   *
   * @param {ISendEmail} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async sendEmail(requestArgs: ISendEmail, userObject?: any): Promise<IResponseData<EmailOutboxModel>> {
    return this.#sdkInternal.sendEmail(requestArgs, userObject);
  }

  /**
   * To save an email draft
   *
   * @param {ISaveEmail} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async saveEmailDraft(requestArgs: ISaveEmail, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.saveEmailDraft(requestArgs, userObject);
  }

  /**
   * To delete an email draft
   *
   * @param {IDeleteEmail} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async deleteEmailDraft(requestArgs: IDeleteEmail, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.deleteEmailDraft(requestArgs, userObject);
  }

  /**
   * To change the status of an email
   *
   * @param {IChangeEmailStatus} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async changeEmailStatus(requestArgs: IChangeEmailStatus, userObject?: any): Promise<IResponseData<void>> {
    return this.#sdkInternal.changeEmailStatus(requestArgs, userObject);
  }

  /**
   * To pull an email from maker queue
   *
   * @param {pullEmailFromMakerQueue} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async pullEmailFromMakerQueue(requestArgs: IPullEmailFromMakerQueue, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.pullEmailFromMakerQueue(requestArgs, userObject);
  }

  /**
   * To pull an email from maker draft
   * @param {IPullEmailFromMakerDraft} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async pullEmailFromMakerDraft(requestArgs: IPullEmailFromMakerDraft, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.pullEmailFromMakerDraft(requestArgs, userObject);
  }

  /**
   * To pull an email from sent items
   *
   * @param {IPullEmailFromSentItems} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async pullEmailFromSentItems(requestArgs: IPullEmailFromSentItems, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.pullEmailFromSentItems(requestArgs, userObject);
  }

  /**
   * To reject an email by checker/vetter
   *
   * @param {IRejectEmail} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async rejectEmail(requestArgs: IRejectEmail, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.rejectEmail(requestArgs, userObject);
  }

  /**
   * To transfer an email to another agent
   *
   * @param {ITransferEmailToAgent} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferEmailToAgent(requestArgs: ITransferEmailToAgent, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.transferEmailToAgent(requestArgs, userObject);
  }

  /**
   * To transfer an email to a skill
   *
   * @param {ITransferEmailToSkill} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async transferEmailToSkill(requestArgs: ITransferEmailToSkill, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.transferEmailToSkill(requestArgs, userObject);
  }

  /**
   * To save an email and download as eml file
   *
   * @param {ISaveEmailAsEml} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async saveEmailAsEml(requestArgs: ISaveEmailAsEml, userObject?: any): Promise<IResponseData<string>> {
    return this.#sdkInternal.saveEmailAsEml(requestArgs, userObject);
  }

  /**
   * To get an inbox email by session ID
   *
   * @param sessionId session ID of the email to get
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getInboxEmail(sessionId: string, userObject?: any): Promise<IResponseData<EmailInboxModel>> {
    return this.#sdkInternal.getInboxEmail(sessionId, userObject);
  }

  /**
   * To get a outbox email by session ID
   *
   * @param sessionId session ID of the email to get
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getOutboxEmail(sessionId: string, userObject?: any): Promise<IResponseData<EmailOutboxModel>> {
    return this.#sdkInternal.getOutboxEmail(sessionId, userObject);
  }

  /**
   * To mask an inbox email for a supervisor
   *
   * @param {IMaskInboxEmail} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async maskInboxEmail(requestArgs: IMaskInboxEmail, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.maskInboxEmail(requestArgs, userObject);
  }

  /**
   * To close bulk emails in queue by route ID's
   *
   * @param routeIdList list of route ID's of emails
   * @param userObject Extra user data from the request to callback for reference
   *
   * 'routeIdList' accept comma seperated values.
   * Session ID can be provided with a route ID by pipe separated.
   *
   * ex: routeId,routeId|sessionId,...
   */
  public async closeBulkEmailsInQueue(routeIdList: string, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.closeBulkEmailsInQueue(routeIdList, userObject);
  }

  /**
   * To delete bulk emails in draft
   *
   * @param sessionIdList list of inbox and outbox session ID's
   * @param userObject Extra user data from the request to callback for reference
   *
   * 'sessionIdList' accept comma seperated values of inbox session ID.
   * Outbox session ID can be provided with a inbox session ID by pipe separated.
   *
   * ex: inboxSessionId,inboxSessionId|outboxSessionId,...
   */
  public async deleteBulkEmailsInDraft(sessionIdList: string, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.deleteBulkEmailsInDraft(sessionIdList, userObject);
  }

  /**
   * To clone an inbox email item
   *
   * @param {ICloneEmail} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async cloneEmail(requestArgs: ICloneEmail, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.cloneEmail(requestArgs, userObject);
  }

  /**
   * To get email template departments
   * @param byTeam to filter for team
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getEmailTemplateDepartments(byTeam: boolean, userObject?: any): Promise<IResponseData<EmailTemplateDeptGroup[]>> {
    return this.#sdkInternal.getEmailTemplateDepartments(byTeam, userObject);
  }

  /**
   * To get the email template groups by department ID
   *
   * @param departmentId to filter by department ID
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getEmailTemplateGroups(departmentId: string, userObject?: any): Promise<IResponseData<EmailTemplateDeptGroup[]>> {
    return this.#sdkInternal.getEmailTemplateGroups(departmentId, userObject);
  }

  /**
   * To get the email templates by group ID and type
   *
   * @param {IGetEmailTemplates} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async getEmailTemplates(requestArgs: IGetEmailTemplates, userObject?: any): Promise<IResponseData<EmailTemplate[]>> {
    return this.#sdkInternal.getEmailTemplates(requestArgs, userObject);
  }

  /**
   * To mark email as spam
   *
   * @param {IMarkEmailAsSpam} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   */
  public async markEmailAsSpam(requestArgs: IMarkEmailAsSpam, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.markEmailAsSpam(requestArgs, userObject);
  }

  /**
   * To reply bulk emails in queue by route ID's
   *
   * @param {IReplyBulkEmailsInQueue} requestArgs Input data required
   * @param userObject Extra user data from the request to callback for reference
   *
   * 'routeIdList' accept comma seperated values.
   * Session ID can be provided with a route ID by pipe separated.
   *
   * ex: routeId,routeId|sessionId,...
   */
  public async replyBulkEmailsInQueue(requestArgs: IReplyBulkEmailsInQueue, userObject?: any): Promise<IResponseData<number>> {
    return this.#sdkInternal.replyBulkEmailsInQueue(requestArgs, userObject);
  }
}
