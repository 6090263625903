
import ImageMessage from './messages/imageMessage';
import DocMessage from './messages/docMessage';
import VideoMessage from './messages/videoMessage';

const imageMimeTypes = ["image/jpeg"]
const videoMimeTypes = ["video/mp4"]
const docMimeTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel"
]

function MultiMediaMessage({ message }) {
    if (message && message.media && message.media.length > 0) {
        if (imageMimeTypes.includes(message.message_type)) {
            return message.media.map(msg => <ImageMessage key={`${message.id}_${msg.content_url}`} content={msg} />)
        }
        if (videoMimeTypes.includes(message.message_type)) {
            return message.media.map(msg => <VideoMessage key={`${message.id}_${msg.content_url}`} content={msg} />)
        }
        if (docMimeTypes.includes(message.message_type)) {
            return message.media.map(msg => <DocMessage key={`${message.id}_${msg.content_url}`} content={msg} />)
        }
    }
    return ""

}
export default MultiMediaMessage