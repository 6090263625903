import { WrcCallTypes, WrsConnectionModes } from '../enums';
import { AVApiConfig, IMediaConstrains } from '../interfaces';
import { Logger } from '../utils';

declare const WrsUtils: any;

export class AVUtils {
  /**
   * To get the call mode by type
   * @param callType call type number
   */
  static WrcGetCallMode(callType: number): string {
    let callMode: string;
    switch (callType) {
      case WrcCallTypes.Audio:
        callMode = 'audio';
        break;
      case WrcCallTypes.Video:
        callMode = 'video';
        break;
      case WrcCallTypes.OneWayVideo:
        callMode = 'onewayvideo';
        break;
      case WrcCallTypes.Screenshare:
        callMode = 'screenshare';
        break;
    }
    return callMode;
  }

  /**
   * To get call type mode
   * @param code param type string
   */
  static WrcGetCallCode(code: string): number {
    let callCode: number;
    switch (code) {
      case 'audio':
        callCode = WrcCallTypes.Audio;
        break;
      case 'video':
        callCode = WrcCallTypes.Video;
        break;
      case 'onewayvideo':
        callCode = WrcCallTypes.OneWayVideo;
        break;
      case 'screenshare':
        callCode = WrcCallTypes.Screenshare;
        break;
    }
    return callCode;
  }

  /**
   * To check the user media
   * @param {String} type type of media
   * @param {AVApiConfig} apiConfig AV config to check media contrains
   */
  static async WrcCheckUserMedia(type: string, mediaConstraints?: IMediaConstrains): Promise<void> {
    try {
      // if the type is video, check for onewayvideo
      if (mediaConstraints?.type?.toLocaleLowerCase() === 'onewayvideo') {
        type = 'onewayvideo';
      }

      // get the stream based on constrains
      const stream = await WrsUtils.getUserMedia({ audio: true, video: type === 'video' });

      // if success, stop all the tracks
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });

      // if success, return promise resolved
      Promise.resolve();
    } catch (err) {
      // if failed, return promise reject
      Promise.reject(err);
    }
  }

  /**
   * Default AV config
   */
  static DefautAVConfig(): AVApiConfig {
    Logger.info('AVUtils: AV config is not set, taking default AV conifiguration');

    return {
      webRTCConfig: {},
      mediaConstraints: {},
      offerOptions: {},
      answerOptions: {},
      recordingOptions: {
        enabled: false
      },
      audioLevelCheck: {
        enabled: false,
        threshold: 0.3
      },
      connectionBandwidthUpperLimit: 1024,
      audioTrackBandwidthUpperLimit: 16,
      videoTrackBandwidthUpperLimit: 128,
      audioMixerEnabled: false,
      audioVideoConnectionMode: WrsConnectionModes.Normal,
      enableCdcAutoSelection: false,
      tryToMergeSingleTrackStreamsFromDifferentSources: false,
      sendACK: false,
      blobEmitterOptions: {
        enabled: false,
        emitInterval: 500
      }
    };
  }
}
