import { WrcCallTypes, WrcCodes, WrsConnectionModes, WrsDtmfTones } from './av-channel';
import { ProxyType } from './sdk';

interface IEnums {
    ProxyType: typeof ProxyType;
    WrcCallTypes: typeof WrcCallTypes,
    WrcCodes: typeof WrcCodes,
    WrsConnectionModes: typeof WrsConnectionModes,
    WrsDtmfTones: typeof WrsDtmfTones
}

// create an object for Enums to export
// eslint-disable-next-line no-var
export declare var TEnums: IEnums;
TEnums = {
    ProxyType,
    WrcCallTypes,
    WrcCodes,
    WrsConnectionModes,
    WrsDtmfTones
};