let events = {};

export const subscribeToEvents = (channel, handler, group = null) => {
    if (group) {
        if (!events[channel]) {
            events[channel] = {};
        }

        events[channel][group] = handler;
    } else {
        events[channel] = handler;
    }

}

export const fireEvent = (channel, data) => {

    if (events[channel]) {
        if (typeof events[channel] === "function") {
            // do something
            events[channel](data);
        } else {
            Object.keys(events[channel]).forEach(key => {
                events[channel][key](data);
            })
        }

    }

}