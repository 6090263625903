
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import CloseBtn from './closeBtn';
import './shared.css';

function Search({ onChange, onEnter, placeholder }) {
    const [text, setText] = useState("")

    return <div className="p-0 m-2" title="Search using customer first name, last name, mobile number">
        {/* <FontAwesomeIcon icon={faSearch} className="fa-xx" /> */}
        <input
            className="form-control"
            type="text"
            value={text}
            placeholder={placeholder || "Search"}
            autoComplete="false"
            onChange={({ target }) => {
                setText(target.value)
                if (onChange) {
                    // debounce(({ target }) => {
                    onChange(target.value)
                    // }, waitTime || 10)
                }
            }}
            onKeyUp={({ keyCode }) => {
                if (onEnter && keyCode === 13) {
                    onEnter(text)
                }
            }}
        />
        {/* {text && (
            <CloseBtn
                className="clear"
                closeEvent={() => {
                    setText("")
                    onEnter(null)
                }} />
        )} */}
    </div>
}
export default Search