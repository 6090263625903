
import { InteractionUser } from '../models/interactionUser';
const apiV1Url = `${(process.env || window.env).REACT_APP_CONVERSATION_API_URL}/api/v1`

export const getAllUnreadCountsV2Users = (userId, idList) => {

  return new Promise((resolve, reject) => {
    let url = `${apiV1Url}/conversations/${userId}`;

    let bodyJSON = JSON.stringify({ ids: idList });

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: bodyJSON
    })
      .then(res => {
        return res.json();
      })
      .then(data => {

        if (data && data.isSuccess) {
          if (data.result) {
            //let funcArr = [];

            let chatCountObj = data.result.reduce((acc, item) => {

              let toUser = item.other_party_id;

              if (item.is_group) {
                //group conversation
                toUser = item.group_id;
              }
              acc[toUser] = {
                unseen_count: item.unseen_count,
                messages: {},
                conversation_dates: [],
                ...item
              };

              return acc;
            }, {});

            resolve(chatCountObj);
          } else {
            resolve({});
          }
        } else {
          //break operation and return empty list
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

/*export const getUnreadMessagesInteraction = (to, timestamp, limit, allMessages = {}, unseen_messages = []) => {
  console.log(
    `%c [ service chatService getUnreadMessagesInteraction ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
      { to, timestamp, limit }
    )}`,
    "background: #990033; color: #ffffff; font-size:12px"
  );
  return new Promise(function (resolve, reject) {
    let url = `${chatHistoryUrl}/api/v1/interaction/messages/after?interactionId=${to}&dateFrom=${timestamp}&limit=${limit}`;

    fetch(url, {
      method: "GET",
      headers: {
        "authorization": auth.getBearerToken()
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log(
          `%c [ service chatService getUnreadMessagesInteraction Response ] - SessionId : ${auth.getSession()}, Data : ${JSON.stringify(
            data
          )}`,
          "background: #cc9900; color: #ffffff; font-size:12px"
        );
        if (data && data.isSuccess && data.result) {
          let next_timestamp = null;
          let messages = data.result.reduce((acc, item) => {

            next_timestamp = item.created_at;

            if (!item.is_seen) {
              unseen_messages.push(item.mid);
            }

            if (item.file_uris && Array.isArray(item.file_uris) && item.file_uris.length > 0) {
              item.content_url = item.file_uris[0].content_url;
              item.thumbnail_url = item.file_uris[0].thumbnail_url;
            }

            item.from = { id: item.sender_id };
            item.isUploading = "DONE";
            if (item.related_message && item.related_message.sender_id) {
              item.related_message.from = { id: item.related_message.sender_id }
            }
            acc[item.mid] = item;

            return acc;
          }, {});

          allMessages = { ...allMessages, ...messages };

          if (data.result && data.result.length === limit) {
            //have more messages
            return getUnreadMessagesInteraction(
              to,
              next_timestamp,
              limit,
              allMessages,
              unseen_messages
            )
              .then(data => {
                resolve(data);
              })
              .catch(ex => {
                resolve(null);
              });
          } else {
            resolve({ messages: allMessages, unseen_messages });
          }
        } else {
          //break operation and return empty list
          resolve(null);
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};
*/

export const getOldMessagesInteractions = async (to, timestamp, limit = 50) => {
  const messages = await new Promise((resolve, reject) => {
    let url = `${apiV1Url}/interaction/messages/before?interactionId=${to}&dateFrom=${timestamp}&limit=${limit}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data && data.isSuccess && data.result) {
          resolve(data.result)
        } else {
          //break operation and return empty list
          resolve([]);
        }
      })
      .catch(ex => {
        reject(ex);
      });
  })
  // messages.sort((a, b) => b.created_at - a.created_at)
  // return messages.reduce((acc,item)=>{
  //   acc[item.mid]=item
  //   return acc
  // },{})
  return messages
}

//Interactions
export const getInteractions = (userId, from, limit = 50, userList = [], getNewUsers = true) => {

  return new Promise(async function (resolve, reject) {
    let url = `${apiV1Url}/interaction/${userId}?dateFrom=${from}&limit=${limit}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(async data => {

        if (data && data.isSuccess) {
          let converted = data.result.reduce((acc, item) => {
            acc.interactions.push(item);

            const usrIndex = acc.uniqueUsers.findIndex(usr => usr.userId === item.customerId);

            if (usrIndex > -1) {

              let newArray = [...acc.uniqueUsers];

              newArray[usrIndex].addInteraction(item);
              acc.uniqueUsers = newArray;
            } else {
              acc.uniqueUsers.push(new InteractionUser(item));
              if (getNewUsers) {
                acc.newUserIds.push(item.customerId);
              }

            }

            acc.lastInt = item;
            /* if (item.customerId.includes("whatsapp")) {
              if (acc.uniqueUsers[item.customerId]) {
                acc.uniqueUsers[item.customerId].addInteraction(item);
              } else {
                acc.uniqueUsers[item.customerId] = new InteractionUser(item, item.customerId, 'WHATSAPP');
              }

            } else {

              if (acc.uniqueUsers[item.customerId]) {
                acc.uniqueUsers[item.customerId].addInteraction(item);
              } else {
                acc.uniqueUsers[item.customerId] = new InteractionUser(item, item.customerId, 'MOBILE');
              }
            } */
            return acc;
          }, { interactions: [], uniqueUsers: userList, lastInt: null, newUserIds: [] });

          if (!getNewUsers) {
            converted.newUserIds = converted.uniqueUsers.map(u => u.userId);
          }

          if (converted.uniqueUsers.length < 16 && converted.interactions.length === limit) {

            let tempConverted = await getInteractions(userId, converted.lastInt.startDateTime, limit, converted.uniqueUsers, false);

            resolve(tempConverted);

          } else {

            resolve(converted);

          }


        } else {
          //break operation and return empty list
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

export const fetchInteractions = (userId, from, limit = 50) => {

  return new Promise(async function (resolve, reject) {
    let url = `${apiV1Url}/interaction/${userId}?dateFrom=${from}&limit=${limit}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(async ({ isSuccess, result }) => {

        if (isSuccess) {
          resolve(result)
        } else {
          reject(new Error("Error occurred"));
        }
      })
      .catch(ex => {
        reject(ex);
      });
  });
};

//Search Message by keyword

export const searchMessage = (agentId, customerId, msg, limit = 20) => new Promise(function (resolve, reject) {
  let url = `${apiV1Url}/conversation/search?user=${agentId}&type=4&to=${customerId}&limit=${limit}&msg=%${msg}%`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(({ result }) => {
      resolve(result || []);
    })
    .catch(ex => {
      reject(ex);
    });
});
