
import { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faComments } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { fireEvent } from '../../util/eventEmitter'
import moment from 'moment'
import { UserContext } from '../userContext'
import { startConversation } from '../../services/interactionApi'
import aniHandler from '../../util/aniGenerator'
import defaultAvatar from '../../assests/images/default_avatar.png'
import whatsappAvatar from '../../assests/images/whatsapp.png'
import { v4 as uuid } from 'uuid';
import { message } from 'antd'

const SkillId = (process.env || window.env).REACT_APP_DEFAULT_SKILL_ID;
const Skill = (process.env || window.env).REACT_APP_DEFAULT_SKILL;
const Channel = (process.env || window.env).REACT_APP_DEFAULT_CHANNEL;
const SubChannel = (process.env || window.env).REACT_APP_DEFAULT_SUB_CHANNEL;
const Intent = (process.env || window.env).REACT_APP_DEFAULT_INTENT;
const organization = (process.env || window.env).REACT_APP_DEFAULT_ORGANIZATION;

moment.updateLocale("en", {
    calendar: {
        lastDay: "[Yesterday]",
        sameDay: "LT",
        nextDay: "[Tomorrow]",
        lastWeek: "dddd",
        nextWeek: "dddd",
        sameElse: "L"
    }
})

moment.relativeTimeThreshold('s', 60)
moment.relativeTimeThreshold('m', 60)
moment.relativeTimeThreshold('h', 24)
moment.relativeTimeThreshold('d', 30)
moment.relativeTimeThreshold('M', 365)

function InteractionCard(props) {

    const context = useContext(UserContext)

    let channel = 'MOBILE'
    let userProfile = context.userList[props.user.userId]
    let customerName = userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : 'Unknown User'
    let avatar = defaultAvatar

    if (props.user.userId.indexOf('whatsapp') > -1) {
        channel = 'WHATSAPP'
        customerName = props.user.userId.replace('whatsapp:', '')
        avatar = whatsappAvatar
    }

    let chats = props.chats.sort(function (a, b) {

        let timestamp_a = a && a.created_at ? a.created_at : 0
        let timestamp_b = b && b.created_at ? b.created_at : 0

        if (timestamp_a < timestamp_b) {
            return 1
        }
        if (timestamp_a > timestamp_b) {
            return -1
        }

        return 0
    })

    let lastMessage = chats && chats.length > 0 && chats[0] ? chats[0].message_content : ''

    let timeAgo = props.user ? moment(moment.unix(props.user.lastInteractionTime / 1000)).calendar() : ""

    const addInteraction = async () => {
        try {
            let agentId = context.loginData && context.loginData.agentData ? context.loginData.agentData.agentId : ''
            let customerId = channel === 'WHATSAPP' ? `smm:${props.user.userId}` : `user:${props.user.userId}`

            let ani = aniHandler.GetAni().toString();

            if (agentId) {
                let interactionObj = {
                    CustomerUserId: customerId,
                    CustomData: {
                        dialogId: "",
                        meetingId: "",
                        ani: ani,
                        outbound: true
                    },
                    Channel,
                    SubChannel,
                    Intent ,
                    Skill: SkillId,
                    RequesterUserId: `tmac:${agentId}`,
                    UserIds: [`tmac:${agentId}`, customerId],
                    CustomerInfo: JSON.stringify({ aud: [props.user.userId], organization, skill: Skill, customerId: props.user.userId, channel: "chat", id: props.user.userId, smChannel: channel === 'WHATSAPP' ? 'TWILIO' : "", exp: "", iat: "", customerName: "test-user", orgId: "1", jti: "", intent: "banking" })
                }

                const d = { channel: Channel, routeType: "aaad", routerType: "aaad", item: { ucid: uuid(), ani: ani, addedTime: moment().format('MM/DD/YYYY hh:mm A'), skill: SkillId } }
                const tmacServer = context.loginData?.agentData?.tmacServer;
                const response = await startConversation(d.item, d.channel, agentId, interactionObj,tmacServer);
                if (!response) {
                    message.error("Conversation Start fail")
                }
                console.debug("addInteraction", response)
            } else {
                console.error("InteractionCard", "addInteraction", "unable to find agent id")
            }
        } catch (error) {
            console.error("addInteraction", error)
        }
    }

    const loadHistory = () => {
        try {
            //need to change to send all interaction ids
            props.onSelect(props.user.userId)
            props.user.interactions && fireEvent('chat', { customer: { ...props.user, customerName, avatar } })
        } catch (error) {
            console.error("InteractionCard", error)
        }
    }

    return (
        <div className={`list-group-item ${props.isSelected ? 'open-chat' : ''}`} onClick={() => { loadHistory() }} >
            <figure className="avatar avatar-state-success border-profile">
                <img src={avatar} className="rounded-circle" alt="image" />
                {/* {channel === 'WHATSAPP' && <FontAwesomeIcon icon={faWhatsapp} className="fa-3x sm-whatsapp" />}
                {channel === 'MOBILE' && <FontAwesomeIcon icon={faComments} className="fa-3x" style={{ color: "#732b90d1" }} />} */}
            </figure>
            <div className="users-list-body">
                <div>
                    <h5 className="text-primary color-purpl">
                        {customerName}
                    </h5>
                    <p>
                        {lastMessage ? lastMessage : ""}
                    </p>
                </div>
                <div className="users-list-action">
                    <div className="new-message-count" role="button">
                        <FontAwesomeIcon icon={faArrowRight} className="fa-lg" onClick={() => addInteraction()} />
                    </div>
                    <small className="text-primary">{timeAgo}</small>
                </div>
            </div>
        </div>
    )
}

export default InteractionCard