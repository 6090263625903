import "./shared.css"
import home from "../../assests/images/no-interaction.svg";

function EmptyChatList({ ...otherProps }) {
    return <>
        <img {...otherProps} src={home} className="empty-chat-list" alt="agent-home" />
        <div className="empty-chat-list-text">No textchat interaction yet...!</div>
        <div className="empty-chat-list-text" style={{marginBlockStart: 20}} >Organization: {(process.env || window.env).REACT_APP_DEFAULT_ORGANIZATION}</div>
    </>
}
export default EmptyChatList;