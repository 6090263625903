import { v4 as uuidv4 } from 'uuid';
import { Acknowledgement, OtherData } from "../interfaces";

export class IUIEventModel {
    EventName: string;
    InteractionID: number;
    IsInteractionConstructEvent: boolean;
    IsInteractionDisposeEvent: boolean;
    CreatedTime: Date;
    EventId: string;
    RecoveryEvent: boolean;
    QueuedEvent: boolean;
    ACK: Acknowledgement
    Catagory: string;

    constructor() {
        this.CreatedTime = new Date();
        this.EventId = uuidv4();
        this.IsInteractionConstructEvent = false;
        this.IsInteractionDisposeEvent = false;
        this.RecoveryEvent = false;
        this.QueuedEvent = false;
    }
}

export class CommandResultEventModel extends IUIEventModel {
    ResultCode: number;
    ResultMessage: string;
    Data: any;
    AgentSessionKey: string;
    ErrorDetails: string;
    TmacServerName: string;
    TmacSignalRUrl: string;
    OtherData: OtherData;

    constructor() {
        super();
    }
}